import React, {useEffect, useLayoutEffect, useRef} from "react";
import useWindowSize from "../../../../hooks/use-window-size";
import {setAttributes} from "../../../../../core/services/utils";
import debounce from "lodash.debounce";
import anime from "animejs";
import Colors from "../../../../../assets/js/colors";

// The Height of the Line Container
const lineContainerHeight = 20;
// The Height of the line. It must be less than lineContainerHeight
const lineHeight = 4;
// The Width of the Line
const lineWidth = 10;
// The duration in which the debounce wait before changing the
const changeLineNumbersWaitDuration = 300;
const lineAnimationDuration = 12000;


const SidebarFooter = () => {
    const windowSize = useWindowSize();
    const containerRef = useRef();

    /**
     * Listens for the changes in the size of the window and with each change, invokes the moveLines function with a
     * debounced property.
     */
    useEffect(() => {
        const lines = document.getElementsByClassName('sidebar-line');
        const container = containerRef?.current;
        if (!container) return;
        changeLineNumbers(lines, container);
        return () => changeLineNumbers.cancel();
    }, [containerRef?.current, windowSize.height]);


    /**
     * As soon as the component renders, creates the background lines for the login form section
     */
    useLayoutEffect(() => {
        createLines();
    }, []);


    const createAnimation = (div, index) => {
        const container = containerRef.current;
        if (!container) return;
        anime({
            targets: div,
            left: `${container.scrollWidth}px`,
            easing: 'linear',
            duration: (lineAnimationDuration * (container.scrollWidth - lineWidth - parseFloat(div.style.left))) / container.scrollWidth,
            // delay: index * 5,
            complete: () => createReverseAnimation(div, index)
        });
    }

    const createReverseAnimation = (div, index) => {
        const container = containerRef.current;
        if (!container) return;
        anime({
            targets: div,
            left: 0,
            easing: 'linear',
            duration: (lineAnimationDuration * (container.scrollWidth - lineWidth - parseFloat(div.style.left))) / container.scrollWidth,
            // delay: index * 5,
            complete: () => createAnimation(div, index)
        });
    }


    /**
     * Creates a single line and returns it
     * @param containerWidth {number}
     * @param index {number}
     */
    const createLine = (index, containerWidth) => {
        const div = document.createElement('div');
        const left = (Math.random() * (containerWidth - lineWidth - lineWidth));
        setAttributes(div,
            {
                style: {
                    position: 'absolute',
                    top: `${(index * lineContainerHeight) + ((lineContainerHeight - lineHeight) / 2)}px`,
                    left: `${left}px`,
                    backgroundColor: Colors.white,
                    width: `${lineWidth}px`,
                    height: `${lineHeight}px`,
                    zIndex: -1,
                    opacity: Math.random() > 0.5 ? 0.25 : 0.06,
                }
            });
        div.classList.add('rounded-md', 'transition', 'sidebar-line', `line-${index}`);
        createAnimation(div, index);
        return div;
    }

    /**
     * Creates the lines of bizkey.json tech login page for the first time. and appends them to element with the
     * container containerRef.
     */
    const createLines = () => {
        const container = containerRef.current;
        const w = container.clientWidth;
        const h = container.clientHeight;
        const numberOfLines = Math.trunc(h / lineContainerHeight);
        for (let index = 0; index < numberOfLines; ++index) {
            container.appendChild(createLine(index, w));
        }
    }

    /**
     * if there is a vertical change in the window, will create or remove lines accordingly to fit the screen
     * @type {function}
     */
    const changeLineNumbers = debounce((lines, container) => {
        const w = container.clientWidth;
        const h = container.clientHeight;
        const numberOfNewLines = Math.trunc(h / lineContainerHeight);
        // remove extra lines from the end when there are extra lines
        if (numberOfNewLines < lines?.length) {
            for (let index = lines?.length - 1; index >= numberOfNewLines; --index) {
                removeLine(container, lines, index)
            }
        }
        // add extra lines to the end when there are less lines
        if (numberOfNewLines > lines?.length) {
            for (let index = lines?.length; index < numberOfNewLines; ++index) {
                container.appendChild(createLine(index, w));
            }
        }
    }, changeLineNumbersWaitDuration);


    const removeLine = (container, lines, index) => {
        try {
            container.removeChild(lines.item(index));
        } catch (e) {
        }
    }

    return (
        <div className={'sidebar-footer'}>
            <div className={'w-100 h-100'} ref={containerRef}>
            </div>
        </div>
    );
}

export default SidebarFooter;
