import React from "react";
import {ReactComponent as CalendarIcon} from "../../../../assets/images/calendar.svg";
import moment from "moment";

const AnnouncementCard = ({item, onClick}) => {

    return (
        <div className={'announcement'} onClick={() => onClick(item)}>
            <div className={'d-flex w-100 align-items-center justify-content-between'}>
                <p className={'title'}>
                    {item?.title ?? ''}
                </p>
                <div className={'d-flex align-items-center justify-content-end'}>
                    <CalendarIcon className={'icon fill-ternary-color-light mr-2'}/>
                    <p className={'text-xs black'}>{moment(item?.publishDate).format('yyyy-MM-DD')}</p>
                </div>
            </div>
            <div className={'description mt-2'}>
                <p>
                    {
                        item?.shortDescription?.length > 100
                            ? item?.shortDescription?.substring(0, 100)?.concat('...')
                            : item?.shortDescription ?? ''}
                </p>
            </div>
        </div>
    );
}

export default AnnouncementCard;
