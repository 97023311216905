import React from "react";
import useRouter from "../../../hooks/use-router";
import * as Yup from "yup";
import {makeRequired, makeValidate} from "mui-rff";
import {Col, Row} from "reactstrap";
import Form, {defaultFormSubscription} from "../../base/form";
import moment from "moment";
import ValidateMessages from "../../../../core/constants/texts/vallidate_messages";
import {OutlinedInputForm} from "../../base/input/outlined_input";
import {DatePickerForm} from "../../base/date-picker/date_picker";
import classnames from "classnames";
import OutlinedButton from "../../base/button/outlined-button";
import {deepEqual} from "../../../../core/services/utils";


const schema = Yup.object().shape({
    startDate: Yup.date().nullable().typeError(ValidateMessages.incorrectType('Date')),
    endDate: Yup.date().nullable().typeError(ValidateMessages.incorrectType('Date')),
    keyword: Yup.string().nullable().min(3, ValidateMessages.minLength('Keywords', 3)),
});


const TicketsListSearchSection = ({filters, loading = false, className = ''}) => {
    const {history, stringifyUrl, location, query} = useRouter();
    const validate = makeValidate(schema);
    const required = makeRequired(schema);

    /**
     * Changes the query of the url to the query containing the searched values so that the list pages of orders can
     * use them and search related orders.
     * @param values {any} form values
     */
    const onSubmit = (values) => {
        values['fromDate'] = values.fromDate ? moment(values.fromDate).toDate().toDateString() : null;
        values['toDate'] = values.toDate ? moment(values.toDate ?? null).toDate().toDateString() : null;
        history.push(stringifyUrl({
            url: location.pathname,
            query: {...query, data: JSON.stringify(values)},
        }))
    }

    return (
        <Form
            onSubmit={onSubmit}
            className={className}
            subscription={defaultFormSubscription}
            validate={validate}
            initialValues={
                filters
                    ? {
                        ...filters,
                        fromDate: filters.fromDate ? moment(filters.fromDate).valueOf() : null,
                        toDate: filters.toDate ? moment(filters.toDate).valueOf() : null,
                    }
                    : filters
            }
            render={({submitting, values, initialValues}) => {
                const colClassName = 'px-2 mb-4';
                return (
                    <>
                        <Row className='no-gutters align-items-center justify-content-center'>
                            <Col xs={12} md={6} lg={4} xl={5} className={colClassName}>
                                <OutlinedInputForm
                                    label={'Keywords'}
                                    ariaLabel={'keywords search input for tickets'}
                                    name={'keyword'}
                                    placeholder={'ticket code...'}
                                    required={required.keyword}
                                    disabled={loading}
                                />
                            </Col>
                            <Col xs={12} sm={8} md={6} lg={6} xl={5} className={classnames(colClassName, 'd-flex' +
                                ' justify-content-around')}>
                                <DatePickerForm
                                    label={'Date From'}
                                    aria-label={'start date search input for tickets'}
                                    name={'startDate'}
                                    required={required.startDate}
                                    disabled={loading}
                                    placeHolder={'dd/mm/yyyy'}
                                    disableFuture
                                />
                                <DatePickerForm
                                    label={'Date to'}
                                    aria-label={'End date search input for tickets'}
                                    name={'endDate'}
                                    required={required.endDate}
                                    disabled={loading}
                                    placeHolder={'dd/mm/yyyy'}
                                    disableFuture
                                />
                            </Col>
                            <Col xs={12} sm={4} lg={2} className={classnames('d-flex align-items-center' +
                                ' justify-content-center')}>
                                <OutlinedButton
                                    type={'submit'}
                                    disabled={loading || submitting || deepEqual(initialValues, values)}
                                    className={'px-5'}
                                >
                                    Filter
                                </OutlinedButton>
                            </Col>
                        </Row>
                    </>
                );
            }}
        />
    );
}

export default TicketsListSearchSection;
