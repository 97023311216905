import React, {useContext, useEffect} from "react";
import useRouter from "../../../hooks/use-router";
import routes from "../../../routes";
import {serverError} from "../../../../static-jsons/errors.json";
import {JsonContentContext} from "../../../contexts";

const ServerError = () => {
    const {history, location} = useRouter();
    const jsonContent = useContext(JsonContentContext);


    /**
     * checks the pathname of the url based on serverErrorForSection route,
     * no prefix => change the url to serverErrorForSection
     */
    useEffect(() => {
        if (location.pathname === routes.error.serverErrorForSection) {
            history.replace(routes.error.serverError);
        }
    }, [history])


    return (
        <div
            className={`error-page ${location.pathname === routes.error.serverError ? "root" : ''} row no-gutters flex-column flex-md-row justify-content-around justify-content-md-around align-items-center`}>
            <svg width="380px" height="500px" viewBox="0 0 837 1045" version="1.1"
                 xmlns="http://www.w3.org/2000/svg">
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <path
                        d="M353,9 L626.664028,170 L626.664028,487 L353,642 L79.3359724,487 L79.3359724,170 L353,9 Z"
                        id="Polygon-1" strokeWidth="6"/>
                    <path
                        d="M78.5,529 L147,569.186414 L147,648.311216 L78.5,687 L10,648.311216 L10,569.186414 L78.5,529 Z"
                        id="Polygon-2" strokeWidth="6"/>
                    <path
                        d="M773,186 L827,217.538705 L827,279.636651 L773,310 L719,279.636651 L719,217.538705 L773,186 Z"
                        id="Polygon-3" strokeWidth="6"/>
                    <path
                        d="M639,529 L773,607.846761 L773,763.091627 L639,839 L505,763.091627 L505,607.846761 L639,529 Z"
                        id="Polygon-4" strokeWidth="6"/>
                    <path
                        d="M281,801 L383,861.025276 L383,979.21169 L281,1037 L179,979.21169 L179,861.025276 L281,801 Z"
                        id="Polygon-5" strokeWidth="6"/>
                </g>
            </svg>
            <div className="message-box">
                <h1>{jsonContent.errors?.serverError?.title ?? serverError.title}</h1>
                <p>{jsonContent.errors?.serverError?.message ?? serverError.message}</p>
                <div className="action-link-wrap">
                    <button onClick={history.goBack} className='animated-button btn'>
                        <span>Sorry again</span><span>Retry</span>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ServerError;
