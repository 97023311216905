import {Form as FormRff} from 'react-final-form';
import React from "react";

/**
 * Default Form Subscription
 * @type {{initialValues: boolean, submitting: boolean, values: boolean}}
 */
export const defaultFormSubscription = {submitting: true, values: true, initialValues: true, submitErrors: true};


const Form = ({
                  onSubmit,
                  initialValues = {},
                  subscription = defaultFormSubscription,
                  validate,
                  autoFillValues,
                  render,
                  className,
              }) => {
    return (
        <FormRff
            onSubmit={onSubmit}
            initialValues={initialValues}
            subscription={subscription}
            validate={validate}
            key={subscription}
            render={({handleSubmit, submitting, values, initialValues, form, submitErrors, errors, ...props}) => {
                return <form
                    className={className}
                    onSubmit={handleSubmit}
                    noValidate
                    autoComplete={autoFillValues}
                >
                    {render({submitting, values, initialValues, form, submitErrors, errors, ...props})}
                </form>
            }}
        />

    );
}

export default Form;

