import React from 'react';
import {OutlinedInput as MuiOutlinedInput} from '@material-ui/core';
import {TextField} from "mui-rff";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {useInputStyles as useStyles} from './_styles'
import classnames from "classnames";


export const Input = ({
                          type = 'text',
                          ariaLabel = 'input',
                          placeholder = 'type...',
                          fullWidth = true,
                          className = '',
                          label,
                          value,
                          onChange,
                          icon,
                          onIconClick,
                          ...props
                      }) => {
    const classes = useStyles({hasLabel: true});

    return (
        <FormControl
            className={classnames(classes.formControl, className)}
            aria-label={ariaLabel}
            fullWidth={fullWidth}
        >
            <InputLabel htmlFor={label}>
                {label}
            </InputLabel>
            <MuiOutlinedInput
                classes={{root: classes.innerInput, input: classes.input}}
                fullWidth={fullWidth}
                placeholder={placeholder}
                label={label}
                type={type}
                value={value}
                onChange={onChange}
                {...props}
            />
        </FormControl>
    );
}


export const InputForm = ({
                              placeholder = 'type...',
                              ariaLabel = 'input',
                              type = 'text',
                              required = false,
                              fullWidth = true,
                              className = '',
                              label,
                              helperText,
                              name,
                              disabled,
                              multiline,
                              rows,
                              ...props
                          }) => {
    const classes = useStyles({hasLabel: true});

    return (
        <TextField
            rows={rows}
            aria-multiline={multiline}
            multiline={multiline}
            disabled={disabled}
            helperText={helperText}
            fullWidth={fullWidth}
            required={required}
            type={type}
            variant={'outlined'}
            label={label}
            name={name}
            classes={{root: classes.innerInput}}
            InputProps={{
                classes: {input: classes.input}
            }}
            className={classnames(className)}
            placeholder={placeholder}
            inputProps={{'aria-label': ariaLabel}}
            {...props}
        />
    );
}

