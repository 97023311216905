import moment from "moment";
import {InputFileAcceptTypes, SupportedFileTypes} from "../constants/enums";

/**
 * Determines if two objects are equal
 * @param object1 {any}
 * @param object2 {any}
 * @return {boolean}
 */
export const deepEqual = (object1: any, object2: any): boolean => {
    // check if the first one is an array
    if (Array.isArray(object1)) {
        if (!Array.isArray(object2) || object1.length !== object2.length) return false;
        for (let i = 0; i < object1.length; i++) {
            if (!deepEqual(object1[i], object2[i])) return false;
        }
        return true;
    }
    // check if the first one is an object
    if (typeof object1 === 'object' && object1 !== null && object2 !== null) {
        if (!(typeof object2 === 'object')) return false;
        const keys = Object.keys(object1);
        if (keys.length !== Object.keys(object2).length) return false;
        for (const key in object1) {
            if (!deepEqual(object1[key], object2[key])) return false;
        }
        return true;
    }
    // not array and not object, therefore must be primitive
    return object1 === object2;
}

/**
 *  Deep copy an acyclic *basic* Javascript object.  This only handles basic
 * scalars (strings, numbers, booleans) and arbitrarily deep arrays and objects
 * containing these.  This does *not* handle instances of other classes.
 * @param obj {any}
 */
export const deepCopy = (obj: any): any => {
    let ret, key;
    let marker = '__deepCopy';

    if (obj && obj[marker])
        throw (new Error('attempted deep copy of cyclic object'));

    if (obj && obj.constructor == Object) {
        ret = {};
        obj[marker] = true;

        for (key in obj) {
            if (key == marker)
                continue;

            // @ts-ignore
            ret[key] = deepCopy(obj[key]);
        }

        delete (obj[marker]);
        return (ret);
    }

    if (obj && obj.constructor == Array) {
        ret = [];
        // @ts-ignore
        obj[marker] = true;

        for (key = 0; key < obj.length; key++)
            ret.push(deepCopy(obj[key]));

        // @ts-ignore
        delete (obj[marker]);
        return (ret);
    }
    // It must be a primitive type -- just return it.
    return (obj);
}

/**
 * Transforms a string that is parsable to a number into a formatted money string.
 * @param amount {string | number}
 * @param decimalCount {number}
 * @param decimal {string} the identifier used for decimal separation
 * @param thousands {string} the identifier used for thousands separation
 */
export const formatMoney = (amount: any, decimalCount = 2, decimal = ".", thousands = ",") => {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";
        amount = Math.abs(Number(amount) || 0).toFixed(decimalCount);
        let i: any = parseInt(amount).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return "$" + negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
};

/**
 * Flattens an object. if the parent key exists, then prepends the parent key with the key as it constructs the obejct
 * @param object {any}
 * @param parentKey {string | null}
 */
const _flatten = (object: any, parentKey: string | null = null): any => {
    return [].concat(...Object.keys(object)
        .map((key) => typeof object[key] === 'object'
            ? _flatten(object[key], parentKey ? `${parentKey}-${key}` : key)
            : ((parentKey) ? {[`${parentKey}-${key}`]: object[key]} : {[key]: object[key]})
        )
    );
}

/**
 * Creates a new flattened object off of the given object
 * @param object {any}
 */
export const flattenObject = (object: any) => {
    return Object.assign({}, ..._flatten(object))
}

/**
 * Given an object, will flatten it and return all of its values as a single.
 *
 * if append, then for each of the values of the object, appends it to their values as a string
 * @param object {any}
 * @param append {string | null}
 */
export const flattenObjectAndReturnAsAList = (object: any, append: string | null = null): string[] | any[] => {
    const all = flattenObject(object);
    const res = [];
    for (const [key, value] of Object.entries(all)) {
        if (key) res.push(value);
    }
    if (append && append.length) return res.map(e => `${e}${append}`);
    return res;
};

/**
 * Sets Attributes for a given element.
 * @param element
 * @param attributes
 */
export const setAttributes = (element: any, attributes: any) => {
    for (const [key, value] of Object.entries(attributes)) {
        if ((key === 'styles' || key === 'style') && typeof value === 'object') {
            for (const [styleKey, styleProp] of Object.entries(value as any)) {
                element.style[styleKey] = styleProp;
            }
        } else if (value === 'html') {
            element.innerHTML = value;
        } else {
            element.setAttribute(key, value);
        }
    }
}

type ObjectKey = string | number | symbol;

/**
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param items An array of type TItem.
 * @param keyGetter A Function that takes the the Array type TItem as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of TItem.
 *
 * @returns Map of the array grouped by the grouping function.
 */
export const groupBy = <K extends ObjectKey, TItem extends Record<K, ObjectKey>>(
    items: TItem[],
    keyGetter: (input: TItem) => K
): Record<ObjectKey, TItem[]> => {
    return items.reduce((result, item) => ({
        ...result,
        [keyGetter(item)]: [
            ...(result[keyGetter(item)] || []),
            item
        ],
    }), {} as Record<ObjectKey, TItem[]>);
}

/**
 * Calculates the area of a circle given its radius.
 * @param r {number}
 */
export const areaOfCircle = (r: number) => r * r * 3.1415;

/**
 * Chooses the extension svg of the image based on the file's extension name.
 *
 * @param {string} extension the extension of the file
 * @return {number}
 */
export const getSupportedFileType = (extension: string) => {
    switch (extension) {
        case InputFileAcceptTypes.png:
        case InputFileAcceptTypes.jpg:
        case InputFileAcceptTypes.jpeg:
            return SupportedFileTypes.image;
        case InputFileAcceptTypes.mp4:
        case InputFileAcceptTypes.mov:
        case  InputFileAcceptTypes.webm:
        case  InputFileAcceptTypes.flv:
        case InputFileAcceptTypes.wmv:
            return SupportedFileTypes.video;
        case InputFileAcceptTypes.pdf:
            return SupportedFileTypes.pdf;
        default:
            return SupportedFileTypes.unknown;
    }
}

/**
 * Downloads the given blob for the user.
 * @param blob {Blob} the content to be downloaded
 * @param name {string} name of the file.
 */
export const downloadBlob = (blob: Blob | File, name: string) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
}

/**
 * Creates a Unique Identifier in form of a string
 * @param {boolean} reactKey whether the uuid is for a react key.
 */
export const createUUId = (reactKey: boolean = false): string => {
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const randomNumber = Math.random() * 16 | 0;
        const v = c === 'x' ? randomNumber : (randomNumber & 0x3 | 0x8);
        return v.toString(16);
    });
    if (!reactKey) return uuid;
    return `_${uuid}`
}

/**
 * Corrects the url of the video by forcing it to start with https
 * @param {string} url
 * @return {string|*}
 */
export const correctUrl = (url: string): string => {
    if (!url?.length) return '';
    if (url.startsWith('http://') || url.startsWith('https://')) return url;
    return `https://${url}`;
}

//              ########################### COMPARATORS ###################################

/**
 * Compares two numbers
 * @param a {number}
 * @param b {number}
 */
export const numComparator = (a: number, b: number): number => {
    if (a === b) return 0;
    if (a < b) return -1;
    return 1
}

/**
 * Compares two dates by converting them to moment objects and then comparing them
 * @param a {Date}
 * @param b {Date}
 */
export const dateComparator = (a: Date, b: Date): number => {
    const _momentComparator = (a: moment.Moment, b: moment.Moment) => {
        if (a.isSame(b, 'ms')) return 0;
        if (a.isAfter(b, 'ms')) return 1;
        return -1;
    }
    return _momentComparator(moment(a), moment(b));
}

/**
 * Compares two strings.
 * @param a {string}
 * @param b {string}
 */
export const stringComparator = (a: string, b: string): number => {
    return a?.localeCompare(b);
}

/**
 * Compares two Booleans
 * @param a {boolean}
 * @param b {boolean}
 */
export const booleanComparator = (a: boolean, b: boolean): number => {
    if (a === b) return 0;
    if (a < b) return -1;
    return 1;
}
