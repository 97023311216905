import {makeStyles} from "@material-ui/core";

export const useButtonStyles = makeStyles((theme) => ({
    root: {
        transition: 'all 0.2s ease-in-out',
        textTransform: 'none',
        borderColor: props => props.borderColor,
        borderWidth: props => props.variant === 'outlined' ? props.borderWidth : 1,
        backgroundColor: props => props.backgroundColor,
        color: props => props.color,
        fontWeight: 600,
        borderRadius: props => props.borderRadius,
        '&:hover': {
            backgroundColor: props => props.hoverBackgroundColor,
            color: props => props.hoverColor,
        },
    },
}));
