import React, {useContext, useEffect, useState} from "react";
import QuestionnaireRoute from "../../../../components/app-specific/questionnaire/questionnaire-route";
import useRouter from "../../../../hooks/use-router";
import {QuestionnaireColors} from "../../../../../core/constants/enums";
import routes, {routeFunctions} from "../../../../routes";
import {Col, Row} from "reactstrap";
import {matchPath, Switch} from "react-router";
import {Route} from "react-router-dom";
import {ApplicationTokenContext} from "../../../../contexts";
import Api from "../../../../../core/services/api_service";
import useIsMounted from "../../../../hooks/use-is-mounted";
import TicketDefinition from "./ticket-definition";
import TicketDefinitionSubmitForm from "./submit-form";
import useStateWithCallback from "../../../../hooks/use-state-with-callback";

/**
 * Creates a service route given the required parameters. If service is null, replaces its values with plac holder
 * and its number.
 * @param application {string}
 * @param service  {any}
 * @param history
 * @return {{onClick: (null|(function(): void)), color: string, name: (*|string), filled: boolean, id: (*|number)}}
 */
const serviceRoute = (history, application, service) => ({
    onClick: !service
        ? null
        : () => history.push(routeFunctions.private.withApplication.newTicket.ticketDefinition(application)),
    filled: !!service,
    color: QuestionnaireColors.step,
    name: service?.title ?? 'Service',
    id: `service-${service?.id ?? -1}`,
});

/**
 * Creates a type route given the required parameters. If type is null, replaces its values with plac holder and its
 * number.
 * @param application {string}
 * @param service  {any}
 * @param type {any}
 * @param history
 * @return {{onClick: (null|(function(): void)), color: string, name: (*|string), filled: boolean, id: (*|number)}}
 */
const typeRoute = (history, application, service, type) => ({
    onClick: !type
        ? null
        : () => history.push(routeFunctions.private.withApplication.newTicket.ticketDefinition(application, service.id)),
    filled: !!type,
    color: QuestionnaireColors.step,
    name: type?.title ?? 'Ticket Type',
    id: `type-${type?.id ?? -1}`,
});

/**
 * Creates a question route given the required parameters. If question is null, replaces its values with place
 * holder and its number in the route.
 * @param application {string}
 * @param service  {any}
 * @param type {any}
 * @param question {any}
 * @param prevQuestion {any}
 * @param questionNumber {number}
 * @param history
 * @return {{onClick: (null|(function(): void)), color: string, name: (*|string), filled: boolean, id: (*|number)}}
 */
const questionRoute = (history, application, service, type, questionNumber = 1, question = null, prevQuestion = null) => ({
    onClick: !question
        ? null
        : () => history.push(routeFunctions.private.withApplication.newTicket.ticketDefinition(application, service.id, type.id, prevQuestion?.id)),
    filled: !!question,
    color: QuestionnaireColors.step,
    name: question?.body ?? `Question ${questionNumber}`,
    id: `question-${question?.id ?? (-1 - questionNumber)}`,
});

/**
 * Creates a hint route given the required parameters. If hint is null, replaces its values with place holders
 * @param application {string}
 * @param service  {any}
 * @param type {any}
 * @param question {any}
 * @param hint {any | undefined}
 * @param history
 * @return {{onClick: (null|(function(): void)), color: string, name: (*|string), filled: boolean, id: (*|number)}}
 */
const hintRoute = (history, application, service, type, question, hint) => ({
    onClick: !hint
        ? null
        : () => history.push(routeFunctions.private.withApplication.newTicket.ticketDefinition(application, service.id, type.id, question.id)),
    filled: true,
    color: QuestionnaireColors.hint,
    name: 'Hint',
    // name: hint?.title ?? 'Hint',
    id: `hint-${hint?.id ?? -1}`,
});

/**
 * Creates a form route given the required parameters.
 * @return {{onClick: (null|(function(): void)), color: string, name: (*|string), filled: boolean, id: (*|number)}}
 */
const formRoute = () => ({
    onClick: null,
    filled: false,
    color: QuestionnaireColors.step,
    name: 'Submit Ticket',
    id: 'form',
});


/**
 * Given a questionId returns the list of selected questions based on their parentalId link.
 * @param questions {any[]}
 * @param question {any}
 * @return {any[]}
 */
const findSelectedQuestions = (questions, question) => {
    if (!question) {
        return [];
    }
    const parent = questions.find(e => e.id === question?.parentId);
    if (!parent) {
        return [question];
    }
    return [...findSelectedQuestions(questions, parent), question];
}


const NewTicket = () => {
    const {location, history, params} = useRouter();
    const [questionnaireRoutes, setQuestionnaireRoutes] = useState([]);
    const [services, setServices] = useStateWithCallback([]);
    const [ticketTypes, setTicketTypes] = useStateWithCallback([]);
    const [questions, setQuestions] = useStateWithCallback([]);
    const [loading, setLoading] = useState(true);
    const applicationTokenContext = useContext(ApplicationTokenContext);
    const [applicationToken, setApplicationToken] = useState(applicationTokenContext.applicationToken);
    const isMounted = useIsMounted();
    const isSubmittingForm = !!matchPath(location.pathname, {
        path: routes.private.withApplication.newTicket.submitForm,
        exact: true
    });

    /**
     * Listens for the changes in the pathname of the url and application token and with each change:
     *  - if applicationToken as changed, resets the list of states and then in either case fetches the necessary
     * information for definition of a new ticket.
     * - determines the routes of the questionnaire.
     */
    useEffect(() => {
        if (!applicationTokenContext.applicationToken) return;
        const timer = setTimeout(() => {
            if (applicationToken !== applicationTokenContext.applicationToken) {
                setApplicationToken(applicationTokenContext.applicationToken);
                return resetState().then(() => getRequiredInformationForNewTicket())
            }
            // avoid the bug that the params are not updated and the path is, so the questioner shows
            // previous page's route.
            if (history.action === 'REPLACE') {
                return resetState().then(() => getRequiredInformationForNewTicket())
            }
            getRequiredInformationForNewTicket().then();
        }, 100);
        return () => timer && clearTimeout(timer);
    }, [location?.pathname, applicationTokenContext.applicationToken])


    /**
     * Resets the services, ticket types and questions.
     * @return {Promise<unknown[]>}
     */
    const resetState = () => {
        return Promise.all([
            new Promise(r => setServices([], r)),
            new Promise(r => setTicketTypes([], r)),
            new Promise(r => setQuestions([], r)),
        ])
    }

    /**
     * Fetches the necessary information regarding the definition of new ticket from the server and then determines
     * the route tree of the questionnaire.
     */
    const getRequiredInformationForNewTicket = async () => {
        await new Promise(r => setTimeout(r, 100));
        const response = await determineApiCalls();
        const routes = determineQuestionnaireRoutes(response);
        setQuestionnaireRoutes(routes);
    }


    /**
     * Depending on the existing parameters and states of this component fetches the required data from the server
     * and sets them if their result is successful.
     *
     * if there is no services or types in the state, fetches them from the server
     * if a service an a type is selected and no questions are available or there is a change in the selected type
     * fetches the questions again.
     * @return {Promise<{services: any[], ticketTypes: any[], questions: any[]}>}
     */
    const determineApiCalls = async () => {
        const {serviceId, typeId} = params;
        setLoading(true);
        let _services = services ?? [];
        let _ticketTypes = ticketTypes ?? [];
        let _questions = questions ?? [];

        // if no services or types are available, fetch the services and types
        if (!services?.length || !ticketTypes?.length) {
            const servicesAndTypesResponse = await Api.getAllServicesAndTypes(applicationTokenContext.applicationToken);
            if (!isMounted()) return {
                services: [],
                ticketTypes: [],
                questions: [],
            };
            if (!servicesAndTypesResponse?.resultFlag) {
                _services = [];
                _ticketTypes = [];
                setServices(_services);
                setTicketTypes(_ticketTypes);
                _questions = [];
                setLoading(false);
                return {
                    services: _services,
                    ticketTypes: _ticketTypes,
                    questions: _questions,
                };
            }
            _services = servicesAndTypesResponse.data?.services ?? [];
            _ticketTypes = servicesAndTypesResponse.data?.ticketTypes ?? [];
            setServices(_services);
            setTicketTypes(_ticketTypes);
        }
        // if no questions are available and service and type is already selected, or question has changed fetch the
        // questions
        if (serviceId && typeId && (!questions?.length || (questions?.length && questions[0].ticketTypeId !== parseInt(typeId)))) {
            const questionsResponse = await Api.getAllQuestionsAndHintsOfType(typeId);
            if (!isMounted()) return {
                services: [],
                ticketTypes: [],
                questions: [],
            };
            if (!questionsResponse?.resultFlag) {
                _questions = [];
                setQuestions(_questions);
                setLoading(false);
                return {
                    services: _services,
                    ticketTypes: _ticketTypes,
                    questions: _questions,
                };
            }
            _questions = questionsResponse.data ?? [];
            setQuestions(_questions);
        }
        setLoading(false);

        return {
            services: _services,
            ticketTypes: _ticketTypes,
            questions: _questions,
        };
    }

    /**
     * Determines the routes of the questionnaire based on a number of different factors:
     *
     * 1. whether the user has selected a service or not
     * 1. whether the user has selected a type or not
     * 1. whether the user has selected a question or not
     * 1. whether the selected question has a hint and the user has selected a hint or not
     * 1. whether the current route is a form
     *
     * @return {[{onClick: (null|(function(): void)), color: string, name: (*|string), filled: boolean, id: (*|number)}]|*[]|{onClick: (null|(function(): void)), color: string, name: (*|string), filled: boolean, id: (*|number)}[]}
     */
    const determineQuestionnaireRoutes = ({services, ticketTypes, questions}) => {
        const {serviceId, typeId, questionId, hintId, application} = params;
        // if there is no services or tasks, return no routes.
        if (!services?.length || !ticketTypes?.length) {
            return [];
        }
        // Add the route for the selected service or its place holder
        const selectedService = services?.find(e => e.id === parseInt(serviceId ?? ''));
        const _routes = [
            serviceRoute(
                history,
                application,
                selectedService,
            ),
        ];
        // if service placeholder is used return the routes
        if (!selectedService) {
            return _routes;
        }
        const selectedTicketType = ticketTypes?.find(e => e.id === parseInt(typeId ?? ''));
        // if there is a selected ticket, add the route for it or use the placeholder
        _routes.push(
            typeRoute(
                history,
                application,
                selectedService,
                selectedTicketType,
            ),
        );
        // if ticket type placeholder is used return the routes
        if (!selectedTicketType) {
            return _routes;
        }
        // if there is no questions or no questions are selected, return the already created routes with a question
        // placeholder.
        if (!questions?.length || !questionId) {
            return [
                ..._routes,
                questionRoute(
                    history,
                    application,
                    selectedService,
                    selectedTicketType,
                ),
            ];
        }
        const selectedQuestions = findSelectedQuestions(questions, questions.find(e => e.id === parseInt(questionId)));
        // if there is no selected question in case of not being able to construct the correct tree route, return
        // the routes with a question placeholder
        if (!selectedQuestions?.length) {
            return [
                ..._routes,
                questionRoute(
                    history,
                    application,
                    selectedService,
                    selectedTicketType,
                ),
            ];
        }
        const lastQuestion = selectedQuestions[selectedQuestions.length - 1];
        // if there last question has more children that need to be selected, returns the routes of selected questions
        // and a placeholder question route
        if (questions?.filter(e => e.parentId === lastQuestion.id)?.length) {
            return [
                ..._routes,
                ...selectedQuestions?.map((question, index) => questionRoute(
                    history,
                    application,
                    selectedService,
                    selectedTicketType,
                    index + 1,
                    question,
                    index === 0 ? null : selectedQuestions[index - 1],
                )),
                questionRoute(
                    history,
                    application,
                    selectedService,
                    selectedTicketType,
                    selectedQuestions.length + 1,
                )
            ];
        }
        const isSubmittingForm = !!matchPath(location.pathname, {
            path: routes.private.withApplication.newTicket.submitForm,
            exact: true
        });
        // if the last question does not have a hint, returns the list of routes.
        if (!lastQuestion.hint) {
            return [
                ..._routes,
                ...selectedQuestions?.map((question, index) => questionRoute(
                    history,
                    application,
                    selectedService,
                    selectedTicketType,
                    index + 1,
                    question,
                    index === 0 ? null : selectedQuestions[index - 1],
                )),
                isSubmittingForm ? formRoute() : undefined,
            ].filter(e => e)
        }
        // there must be a hint that needs to be considered, returns the list of routes and the hint or its
        // placeholder
        return [
            ..._routes,
            ...selectedQuestions?.map((question, index) => questionRoute(
                history,
                application,
                selectedService,
                selectedTicketType,
                index + 1,
                question,
                index === 0 ? null : selectedQuestions[index - 1],
            )),
            hintRoute(
                history,
                application,
                selectedService,
                selectedTicketType,
                lastQuestion,
                hintId ? lastQuestion.hint : null,
            ),
            isSubmittingForm ? formRoute() : undefined,
        ].filter(e => e)
    }


    return (
        <>
            <Row>
                <Col xs={12} lg={8} className={'order-2 order-lg-1 mt-2'}>
                    <Switch>
                        <Route path={routes.private.withApplication.newTicket.submitForm} exact>
                            <TicketDefinitionSubmitForm/>
                        </Route>
                        <Route path={routes.private.withApplication.newTicket.ticketDefinition} exact>
                            <TicketDefinition
                                services={services}
                                questions={questions}
                                loading={loading}
                                types={ticketTypes}
                                fetchAgain={getRequiredInformationForNewTicket}
                            />
                        </Route>
                    </Switch>
                </Col>
                <Col xs={12} lg={4} className={'order-1 order-lg-2 mt-2'}>
                    <QuestionnaireRoute clickable={!isSubmittingForm} routes={questionnaireRoutes}/>
                </Col>
            </Row>
        </>
    );
}

export default NewTicket;
