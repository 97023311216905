import React, {useContext, useEffect, useState} from "react";
import useRouter from "../../../../hooks/use-router";
import useIsMounted from "../../../../hooks/use-is-mounted";
import {deepEqual} from "../../../../../core/services/utils";
import Api from "../../../../../core/services/api_service";
import {Col, Row} from "reactstrap";
import {ApplicationTokenContext} from "../../../../contexts";
import InfiniteScroll from 'react-infinite-scroller';
import TryAgain from "../../../../components/app-specific/try-again";
import AnnouncementCard from "../../../../components/app-specific/announcement-card";
import Announcement from "./single";
import AnnouncementsListSearchSection from "../../../../components/search-sections/announcements-list";


/**
 * Prepares the search request for search api with the given parameters
 * @param filters {any}
 * @param applicationToken {string} the token of the selected application
 * @param paginationInfo {any}
 */
const prepareForApi = (filters, applicationToken, paginationInfo) => {
    let res = {}
    if (filters) {
        res = {
            ...res,
            applicationToken: applicationToken,
            startDate: filters?.startDate,
            endDate: filters?.endDate,
            keyword: filters?.keyword,
            statusId: filters?.status?.id,
        };
    }
    if (paginationInfo) {
        res = {
            ...res,
            pageNumber: paginationInfo.currentPage,
            itemCount: paginationInfo?.pageSize,
        };
    }
    return res;
}

const initialPaginationInfo = {
    currentPage: 1,
    pageSize: 8,
    length: 0,
};

const AnnouncementsList = () => {
    const {query, history, location} = useRouter();
    const [loading, setLoading] = useState(true);
    const [announcements, setAnnouncements] = useState([]);
    const [paginationInfo, setPaginationInfo] = useState(initialPaginationInfo);
    const [filters, setFilters] = useState(null);
    const isMounted = useIsMounted();
    const applicationTokenContext = useContext(ApplicationTokenContext);
    const [applicationToken, setApplicationToken] = useState(applicationTokenContext.applicationToken);
    const [selectedAnnouncement, setSelectedAnnouncement] = useState({open: false, data: null})


    /**
     * Listens for the changes in the query of the location and with each change, searches the data from api.
     */
    useEffect(() => {
        let call = false;
        if (applicationTokenContext.applicationToken !== applicationToken) {
            setApplicationToken(applicationTokenContext.applicationToken);
            call = true;
        }
        const newFilters = query?.data ? JSON.parse(query?.data) : {};
        if (!deepEqual(newFilters, filters)) {
            setFilters(newFilters);
            call = true;
        }
        if (!call) return;
        setAnnouncements([]);
        const forApi = prepareForApi(newFilters, applicationTokenContext.applicationToken, initialPaginationInfo);
        searchAnnouncements(forApi);
    }, [query, applicationTokenContext.applicationToken])


    /**
     * Fetches the list of announcements for the provided application of the user with the provided filters. IF the
     * result of the api is successful, sets the list of announcements.
     * @param values {any} the search filters
     */
    const searchAnnouncements = (values) => {
        setLoading(true);
        Api.searchAnnouncements(values).then((response) => {
            if (!isMounted()) return;
            if (response?.resultFlag) {
                setAnnouncements(prevState => [...prevState, ...response.data.announcements]);
                setPaginationInfo(response.data.paginationInfo);
            } else {
                setAnnouncements([]);
                setPaginationInfo(initialPaginationInfo);
            }
            setLoading(false);
        });
    }

    /**
     * Loads more announcements by increasing the currentPage of pagination info to trigger the search function.
     */
    const loadMore = () => {
        if (!announcements.length || loading) return;
        setPaginationInfo(prevState => ({...prevState, currentPage: prevState.currentPage + 1}))
    }

    /**
     * If there is any existing filters, removes them otherwise fetches the data from the server with no filters.
     */
    const searchAgain = () => {
        // reset the filters if there is any
        if (Object.values(filters ?? {}).length) {
            history.push(location.pathname)
        } else {
            setAnnouncements([]);
            setPaginationInfo(initialPaginationInfo);
            const forApi = prepareForApi(filters, applicationTokenContext.applicationToken, initialPaginationInfo);
            searchAnnouncements(forApi);
        }
    }

    /**
     * Selects the announcement by opening the modal and saving the selected announcement.
     * @param announcement {any}
     */
    const selectAnnouncement = (announcement) => {
        setSelectedAnnouncement({open: true, data: announcement})
    }


    return (
        <>
            <Row>
                <Col xs={12}>
                    <p className={'text-lg font-weight-bold primary-color-dark my-2'}>
                        Announcements
                    </p>
                </Col>
            </Row>
            <AnnouncementsListSearchSection className={'px-4 pt-4'} filters={filters} loading={loading}/>
            <div className={'pb-4 px-4 d-flex flex-column'}>
                {
                    !announcements?.length && !loading
                        ? <TryAgain
                            buttonText={Object.values(filters ?? {}).length ? 'Remove Filters' : 'Search Again'}
                            onClick={searchAgain}/>
                        : <InfiniteScroll
                            className='d-flex flex-column w-100 mt-4'
                            pageStart={0}
                            loadMore={loadMore}
                            hasMore={!loading && paginationInfo.length > paginationInfo.pageSize * paginationInfo.currentPage}
                            useWindow
                            threshhold={400}
                        >
                            {announcements?.map((announcement) => (
                                <Col xs={12} key={announcement.id} className='p-0 py-1'>
                                    <AnnouncementCard item={announcement} onClick={selectAnnouncement}/>
                                </Col>
                            ))}
                        </InfiniteScroll>
                }
                {
                    loading &&
                    Array(initialPaginationInfo.pageSize).fill(null).map((e, index) => (
                        <Col key={index} xs={12} className={'p-0 py-1 loading-div announcement '}>
                            <div/>
                        </Col>
                    ))
                }
            </div>
            <Announcement
                open={selectedAnnouncement.open}
                selectedAnnouncement={selectedAnnouncement.data}
                setOpen={(value) => setSelectedAnnouncement(prevState => ({...prevState, open: value}))}
            />
        </>
    );
}

export default AnnouncementsList;
