import React, {createRef, useEffect, useRef, useState} from "react";
import {Fade, Modal} from "@material-ui/core";
import {ArrowBackIos, ArrowForwardIos, Close} from "@material-ui/icons";
import IconButton from "../../base/button/icon-button";
import Colors from "../../../../assets/js/colors";
import {pdfjs} from 'react-pdf';
import {SupportedFileTypes} from "../../../../core/constants/enums";
import Image from "./file-types/image";
import Video from "./file-types/video";
import useIsMounted from "../../../hooks/use-is-mounted";
import Pdf from "./file-types/pdf";

const FilesPreviewDialog = ({open, setOpen, files}) => {
    const [refs, setRefs] = useState([]);
    const [hasNext, setHasNext] = useState(false);
    const [hasPrev, setHasPrev] = useState(false);
    /**@type {React.MutableRefObject<HTMLDivElement>}*/
    const containerRef = useRef();
    /**@type {React.MutableRefObject<number | null>}*/
    const timerRef = useRef();
    const isMounted = useIsMounted();

    /**
     * Sets the global worker for the pdfjs as soon as the component mounts.
     */
    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    }, [])

    /**
     * Listens to open and files and with each change:
     * - if open, then sets the refs of this modal.
     */
    useEffect(() => {
        if (!open) return;
        setRefs(prevState => files?.map((e, i) => prevState[i] ?? createRef()));
        return () => {
            for (const ref of refs) {
                ref.current = null;
            }
        };
    }, [open, files])

    /**
     * As soon as the container is rendered and visible:
     *
     * - determines the visibility of the buttons.
     * - if there is a startingImage, then scrolls to that image
     */
    useEffect(() => {
        if (!open) return;
        const _interval = () => {
            if (!containerRef.current) {
                return timer = setInterval(_interval, 100);
            }
            clearInterval(timer);
            onScroll()
            const startingIndex = files.findIndex(e => e.first);
            if (startingIndex !== -1) scrollToAnotherFile(false, startingIndex)
        }
        let timer = setInterval(_interval, 100)
        return () => clearInterval(timer);
    }, [open])

    /**
     * Scrolls to the next appropriate image based on the value of next.
     *
     * - resets the current file's state.
     * - if the forceIndex is available, then scrolls to that index
     * @param {boolean} next whether to scroll to next or prev
     * @param {boolean} forceIndex the index that we are forced to scroll to.
     */
    const scrollToAnotherFile = (next = true, forceIndex) => {
        const current = containerRef.current?.scrollLeft / window.innerWidth
        const index = forceIndex ?? Math.floor(current);
        resetFile(index);
        if (forceIndex !== undefined) {
            return containerRef.current?.scrollTo(window.innerWidth * index, 0);
        }
        if (!next) {
            containerRef.current?.scrollTo(window.innerWidth * Math.max(Math.floor(current) - 1, 0), 0);
        } else {
            containerRef.current?.scrollTo(window.innerWidth * Math.min(Math.ceil(current) + 1, (files.length ?? 1) - 1), 0);
        }
    }

    /**
     * Determines whether the modal can show the prev or next buttons.
     * - resets the current files' zoom scale back to 1
     */
    const onScroll = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
        }
        timerRef.current = setTimeout(() => {
            if (!isMounted()) return;
            const current = containerRef.current?.scrollLeft / window.innerWidth;
            const index = Math.floor(current);
            const prev = Math.max(index - 1, 0);
            const next = Math.min(index + 1, 8);
            resetFile(index);
            resetFile(prev);
            resetFile(next);
            setHasNext(((containerRef.current?.scrollLeft ?? 0) + window.innerWidth) < (containerRef.current?.scrollWidth ?? 0));
            setHasPrev((containerRef.current?.scrollLeft ?? 0) > 0)
        }, 200);
    }

    /**
     * Resets the file's state that exits at the given index.
     *
     * - if file is an image, zoom scale back to 1.
     * - if file is a video, pauses the video
     * @param {number} index
     */
    const resetFile = (index) => {
        switch (files[index]?.type) {
            case SupportedFileTypes.image:
                refs.at(index)?.current?.reset();
                break;
            case SupportedFileTypes.video:
                refs.at(index)?.current?.pause();
                break;
            case SupportedFileTypes.pdf:
                // refs.at(index)?.current?.reset();
                break;
            default:
                break;
        }
    }

    /**
     * Renders the files of this preview based on their type.
     * @return {JSX.Element[]}
     */
    const renderFiles = () => {
        return files?.map((file, i) => {
            switch (file.type) {
                case SupportedFileTypes.image:
                    return <Image
                        image={file}
                        key={i}
                        ref={refs[i]}
                    />
                case SupportedFileTypes.video:
                    return <Video
                        video={file}
                        key={i}
                        ref={refs[i]}
                    />
                case SupportedFileTypes.pdf:
                    return <Pdf
                        pdf={file}
                        key={i}
                        ref={refs[i]}
                    />
                default:
                    return <></>
            }
        })
    }

    return (
        <>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                className={'files-preview-modal'}>
                <Fade in={open} unmountOnExit mountOnEnter>
                    <div>
                        <div className={'files-preview'} onScroll={onScroll} ref={containerRef}>
                            <IconButton
                                className={'close-button'}
                                color={Colors.white}
                                hoverColor={Colors.white}
                                backgroundColor={Colors.primaryColorDark}
                                hoverBackgroundColor={Colors.secondaryColorDark}
                                onClick={() => setOpen(false)}>
                                <Close className={'icon'}/>
                            </IconButton>
                            <Fade in={hasPrev}>
                                <IconButton
                                    color={Colors.primaryColorDark}
                                    hoverColor={Colors.white}
                                    backgroundColor={Colors.white}
                                    hoverBackgroundColor={Colors.secondaryColorDark}
                                    onClick={() => scrollToAnotherFile(false)}
                                    className={'prev-button'}>
                                    <ArrowBackIos className={'icon pl-2'}/>
                                </IconButton>
                            </Fade>
                            <div
                                className={'file-container'}
                                onClick={() => setOpen(false)}
                                style={{width: `${(files?.length ?? 1) * 100}vw`}}>
                                {renderFiles()}
                            </div>
                            <Fade in={hasNext}>
                                <IconButton
                                    color={Colors.primaryColorDark}
                                    hoverColor={Colors.white}
                                    backgroundColor={Colors.white}
                                    hoverBackgroundColor={Colors.secondaryColorDark}
                                    onClick={() => scrollToAnotherFile(true)}
                                    className={'next-button'}>
                                    <ArrowForwardIos className={'icon px-1'}/>
                                </IconButton>
                            </Fade>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </>
    )
}


export default FilesPreviewDialog;
