import {useCallback, useEffect, useRef, useState} from "react";

/**
 * Hook for defining states with lazy callbacks to run after the state state function is called.
 * @param initialValue
 * @return {[unknown, (function(* ,(* | function(*): *)?): void)]}
 */
const useStateWithCallback = (initialValue) => {
    const callbackRef = useRef(null);
    
    const [value, setValue] = useState(initialValue);

    /**
     * Listens for the changes in the value and with each change:
     * if there is a callback attached to the setState function, then runs it with the updated value, and terminates
     */
    useEffect(() => {
        if (callbackRef.current) {
            callbackRef.current(value);
            callbackRef.current = null;
        }
    }, [value]);

    /**
     * The Setter for the useState hook.
     *
     * Assigns a lazy callback to the ref so that it can be run after the setState has been called successfully.
     * @param {any} newValue
     * @param {Function} callback
     */
    const setValueWithCallback = useCallback((newValue, callback = null) => {
        callbackRef.current = callback;
        return setValue(newValue);
    }, [setValue]);

    return [value, setValueWithCallback];
};

export default useStateWithCallback;
