import React, {forwardRef, useImperativeHandle, useRef, useState} from "react";
import {Document, Page} from "react-pdf";
import OutlinedButton from "../../../../base/button/outlined-button";


const Pdf = ({pdf}, ref) => {
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const containerRef = useRef();

    const url = `${pdf.baseURL}${pdf.filename}${pdf.extension}`;

    /**
     * Attaches the video player functionalities to the given ref so that the parent components can directly use them.
     */
    useImperativeHandle(ref, () => ({
        reset: () => {
            setPageNumber(1);
        },
    }), [containerRef])

    /**
     * Sets the page numbers and the selected page number as soon as the pdf document loads successfully.
     * @param numPages
     */
    const onDocumentLoadSuccess = ({numPages}) => {
        setNumPages(numPages);
        setPageNumber(1);
    }

    /**
     * Prevent the bubbling of the event form this video to not let the dialog close.
     * @param {Event} e
     */
    const preventBubbling = (e) => {
        e.stopPropagation();
        e.preventDefault();
    }

    return (
        <>
            <div
                ref={containerRef}
                className={'file'}
                style={{'--transition': 'all 0.2s ease-out'}}
                onClick={preventBubbling}>
                <Document
                    ref={ref}
                    file={url}
                    onLoadSuccess={onDocumentLoadSuccess}
                    error={
                        <div className={'mb-3 mt-4 py-5 d-flex justify-content-center align-items-center text-center'}>
                            Failed to load this PDF
                        </div>
                    }>
                    <Page pageNumber={pageNumber}/>
                </Document>
                {
                    numPages > 1 &&
                    <div className={'controller'}>
                        <OutlinedButton onClick={() => setPageNumber(prevState => Math.max(1, prevState - 1))}>
                            Prev
                        </OutlinedButton>
                        <p>
                            {pageNumber ?? 0} of {numPages ?? 0}
                        </p>
                        <OutlinedButton onClick={() => setPageNumber(prevState => Math.min(numPages, prevState + 1))}>
                            Next
                        </OutlinedButton>
                    </div>
                }
            </div>
        </>
    );
}

export default forwardRef(Pdf);

