import React from "react";
import useRouter from "../../../../../../hooks/use-router";
import {Col, Row} from "reactstrap";
import classnames from "classnames";
import TryAgain from "../../../../../../components/app-specific/try-again";
import OutlinedButton from "../../../../../../components/base/button/outlined-button";
import {routeFunctions} from "../../../../../../routes";


const TicketDefinitionTicketTypes = ({ticketTypes, fetchAgain, loading}) => {
    const {params: {application, serviceId}, history} = useRouter();

    /**
     * Navigates the user to the question selection for the selected ticket-type of ticket-definition
     * @param type {any}
     */
    const navigateToNextSection = (type) => {
        history.push(routeFunctions.private.withApplication.newTicket.ticketDefinition(application, serviceId, type.id))
    }


    return (
        <>
            <Row className={classnames('ticket-definition', {'loading-div': loading})}>
                <Col xs={12} className={'mb-4'}>
                    <p className={'title'}>
                        What kind of Ticket do you want to Submit?
                    </p>
                </Col>
                <Col xs={12}>
                    {
                        loading
                            ? <>
                                {Array(4).fill(null).map((e, index) => (
                                    <div className={'button my-2'} key={index}>
                                        <div/>
                                    </div>
                                ))}
                            </>
                            : !ticketTypes?.length
                                ? <TryAgain onClick={fetchAgain}
                                            text={'There is no ticket types available for your application. Please' +
                                                ' contact our' +
                                                ' technical support for more information'}
                                            buttonText={'Load Ticket Types Again'}/>
                                : ticketTypes?.map((type) => (
                                    <OutlinedButton
                                        key={type.id}
                                        className={'button'}
                                        onClick={() => navigateToNextSection(type)}
                                    >
                                        {type?.title ?? ''}
                                    </OutlinedButton>
                                ))
                    }
                </Col>

            </Row>
        </>
    );
}

export default TicketDefinitionTicketTypes;
