import React from "react";
import routes, {routesLists} from "../../routes";
import {Redirect, Route, Switch} from "react-router";
import PrivateViewsWithApplication from "./with-application";
import PrivateViewsWithoutApplication from "./without-application";
import Panel from "../../containers/panel";

const PrivateViews = () => {
    return (
        <Panel>
            <Switch>
                <Route path={routesLists.privateWithoutApplications} exact>
                    <PrivateViewsWithoutApplication/>
                </Route>
                <Route path={routesLists.privateWithApplications} exact>
                    <PrivateViewsWithApplication/>
                </Route>
                <Redirect to={routes.error.notFound}/>
            </Switch>
        </Panel>
    );
}

export default PrivateViews;
