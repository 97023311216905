import {makeStyles} from "@material-ui/core/styles";
import Colors from '../../../../assets/js/colors';

export const useSelectStyles = makeStyles((theme) => ({
    root: {
        padding: props => props.projectsSelect ? '15px 16px 16px 40px !important' : '11px 23px 9px 35px !important',
    },
    icon: {
        width: props => props.projectsSelect ? '1em' : '0.7em',
        height: props => props.projectsSelect ? '1em' : '0.7em',
        top: props => props.projectsSelect ? 'calc(50% - 9px)' : 'calc(50% - 7px)',
        left: 15,
        color: props => !props.data?.length ? Colors.disabledTextColor : Colors.secondaryColorDark,
        cursor: props => (!props.data || !props.data.length) ? 'not-allowed' : 'pointer',
    },
    iconOpen: {
        top: props => props.projectsSelect ? 'calc(50% - 15px)' : 'calc(50% - 10px)',
        left: props => props.projectsSelect ? 10 : 10,
    },
    innerInput: {
        fontSize: props => props.fontSize,
    },
    menuRoot: {},
    label: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: 14,
        fontWeight: 700,
        color: Colors.primaryColorDark,
    },
}));
