import React, {forwardRef, useState} from "react";
import {Button as MuiButton} from '@material-ui/core';
import Colors from "../../../../assets/js/colors";
import {useButtonStyles as useStyles} from "./_styles";

const Button = forwardRef(({
                               disabled = false,
                               color = Colors.white,
                               hoverColor = Colors.primaryColor,
                               backgroundColor = Colors.primaryColor,
                               hoverBackgroundColor = Colors.transparent,
                               borderColor = Colors.primaryColor,
                               borderRadius = 5,
                               borderWidth = 1,
                               variant = 'contained',
                               type = 'button',
                               onClick,
                               name,
                               children,
                               className,
                               disableRipple = false,
                               hoverChildren,
                               ariaLabel,
                               ...props
                           }, ref) => {
    const [hovering, setHovering] = useState(false);
    const classes = useStyles({
        color,
        hoverColor,
        backgroundColor,
        hoverBackgroundColor,
        variant,
        borderRadius,
        borderColor,
        borderWidth,
    });

    /**
     * Callback to set the hovering state to true
     */
    const onMouseEnter = () => {
        setHovering(true)
    }

    /**
     * Callback to set the hovering state to false
     */
    const onMouseLeave = () => {
        setHovering(false)
    }

    return (
        <MuiButton
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            disableElevation
            onMouseDown={(e) => e.stopPropagation()}
            disabled={disabled}
            ref={ref}
            name={name}
            disableRipple={disableRipple}
            className={`${classes.root} ${className}`}
            variant={variant}
            onClick={onClick}
            type={type}
            aria-label={ariaLabel}
            {...props}
        >
            {hovering ? hoverChildren ?? children : children}
        </MuiButton>
    );
});

export default Button;


