export function valueToPercent(value, min, max) {
    return ((value - min) * 100) / (max - min);
}

export function asc(a, b) {
    return a - b;
}

export function clamp(value, min, max) {
    return Math.min(Math.max(min, value), max);
}

export function findClosest(values, currentValue) {
    const {index: closestIndex} = values.reduce((acc, value, index) => {
        const distance = Math.abs(currentValue - value);

        if (acc === null || distance < acc.distance || distance === acc.distance) {
            return {
                distance,
                index,
            };
        }

        return acc;
    }, null);
    return closestIndex;
}

export function trackFinger(event, touchId) {
    if (touchId.current !== undefined && event.changedTouches) {
        for (let i = 0; i < event.changedTouches.length; i += 1) {
            const touch = event.changedTouches[i];
            if (touch.identifier === touchId.current) {
                return {
                    x: touch.clientX,
                    y: touch.clientY,
                };
            }
        }

        return false;
    }

    return {
        x: event.clientX,
        y: event.clientY,
    };
}

export function percentToValue(percent, min, max) {
    return (max - min) * percent + min;
}

function getDecimalPrecision(num) {
    // This handles the case when num is very small (0.00000001), js will turn this into 1e-8.
    // When num is bigger than 1 or less than -1 it won't get converted to this notation so it's fine.
    if (Math.abs(num) < 1) {
        const parts = num.toExponential().split('e-');
        const matissaDecimalPart = parts[0].split('.')[1];
        return (matissaDecimalPart ? matissaDecimalPart.length : 0) + parseInt(parts[1], 10);
    }

    const decimalPart = num.toString().split('.')[1];
    return decimalPart ? decimalPart.length : 0;
}

export function roundValueToStep(value, step, min) {
    const nearest = Math.round((value - min) / step) * step + min;
    return Number(nearest.toFixed(getDecimalPrecision(step)));
}

export function setValueIndex({values, source, newValue, index}) {
    // Performance shortcut
    if (values[index] === newValue) {
        return source;
    }

    const output = values.slice();
    output[index] = newValue;
    return output;
}

export function focusThumb({sliderRef, activeIndex, setActive}) {
    if (
        !sliderRef.current.contains(document.activeElement) ||
        Number(document.activeElement.getAttribute('data-index')) !== activeIndex
    ) {
        sliderRef.current.querySelector(`[role="slider"][data-index="${activeIndex}"]`)?.focus();
    }

    if (setActive) {
        setActive(activeIndex);
    }
}

export const Identity = (x) => x;
