import React, {useContext, useEffect, useState} from "react";
import useRouter from "../../../../../hooks/use-router";
import useIsMounted from "../../../../../hooks/use-is-mounted";
import {deepEqual, groupBy} from "../../../../../../core/services/utils";
import Api from "../../../../../../core/services/api_service";
import {TicketStatuses} from "../../../../../../core/constants/enums";
import {Col, Container, Row} from "reactstrap";
import TicketsListSearchSection from "../../../../../components/search-sections/ticekts-list";
import BoardList from "../../../../../components/app-specific/board/board-list";
import TryAgain from "../../../../../components/app-specific/try-again";
import {ApplicationTokenContext} from "../../../../../contexts";
import BoardListTicketCard from "../../../../../components/app-specific/board/ticket-card";


/**
 * Prepares the search request for search api with the given parameters
 * @param filters {any}
 * @param applicationToken {string} the token of the selected application
 */
const prepareForApi = (filters, applicationToken) => {
    return {
        applicationToken: applicationToken,
        startDate: filters?.startDate,
        endDate: filters?.endDate,
        keyword: filters?.keyword,
        statusId: filters?.status?.id,
    };
}

/**
 * Given a list of tickets, groups them based on their status and for each status single, gets their counts object
 * that has been grouped based on their type.
 * @param tickets {any[]}
 * @param types {any[]}
 * @param statuses {any[]}
 */
const groupTickets = (tickets, types, statuses) => {
    const groups = {};
    statuses.forEach(status => {
        groups[status.id] = [];
    })
    // group the tickets based on their status.
    const groupedTicketsByStatus = Object.entries(groupBy(tickets, (e) => e.status?.id));
    for (let [statusId, list] of groupedTicketsByStatus) {
        groups[statusId] = [...groups[statusId], ...list];
    }
    // group each group of tickets by their ticket types and their counts.
    return Object.entries(groups).map(([statusId, list]) => {
        const countsGrouped = [];
        const groupedTicketsByType = Object.entries(groupBy(list, (e) => e.type?.id));
        for (let [typeId, typeList] of groupedTicketsByType) {
            countsGrouped.push({
                type: types?.find(e => e.id === parseInt(typeId)),
                length: typeList?.length ?? 0,
            });
        }
        return {
            status: statuses.find(e => e.id === parseInt(statusId)),
            list: list || [],
            counts: countsGrouped,
        };
    });
}

const TicketsList = () => {
    const {location, history, query} = useRouter();
    const [loading, setLoading] = useState(true);
    const [ticketGroups, setTicketGroups] = useState([]);
    const [types, setTypes] = useState([]);
    const [filters, setFilters] = useState(null);
    const isMounted = useIsMounted();
    const applicationTokenContext = useContext(ApplicationTokenContext);
    const [applicationToken, setApplicationToken] = useState(applicationTokenContext.applicationToken);


    /**
     * Listens for the changes in the query of the location and with each change, searches the data from api.
     */
    useEffect(() => {
        let call = false;
        if (applicationTokenContext.applicationToken !== applicationToken) {
            setApplicationToken(applicationTokenContext.applicationToken);
            setLoading(true);
            call = true;
        }
        const newFilters = query?.data ? JSON.parse(query?.data) : {};
        if (!deepEqual(newFilters, filters)) {
            setFilters(newFilters);
            call = true;
        }
        if (!call) return;
        const forApi = prepareForApi(newFilters, applicationTokenContext.applicationToken);
        searchTickets(forApi).then();
    }, [query, applicationTokenContext.applicationToken])


    /**
     * If the types are loaded, searches for the list of tickets with the given filter values and sets the tickets
     * state after grouping the results based on their status.
     * otherwise, fetches the list of types first and then searches for the tickets.
     * @param values {any} the search filters
     */
    const searchTickets = async (values) => {
        let typesResponse;
        if (!types?.length) {
            typesResponse = await Api.getAllServicesAndTypes(applicationTokenContext.applicationToken);
            if (!isMounted()) return;
            if (!typesResponse?.resultFlag) {
                setTicketGroups([]);
                setLoading(false);
                return;
            }
            setTypes(typesResponse.data.ticketTypes);
        } else {
            typesResponse = {data: {ticketTypes: types}};
        }
        const ticketsResponse = await Api.searchTickets(values);
        if (!isMounted()) return;
        if (ticketsResponse?.resultFlag && ticketsResponse?.data?.tickets?.length) {
            setTicketGroups(groupTickets(
                ticketsResponse.data.tickets,
                typesResponse.data.ticketTypes,
                ticketsResponse.data.statuses
            ));
        } else {
            setTicketGroups([]);
        }
        setLoading(false);
    }

    /**
     * If there is any existing filters, removes them otherwise fetches the data from the server with no filters.
     */
    const searchAgain = () => {
        // reset the filters if there is any
        if (Object.values(filters ?? {}).length) {
            history.push(location.pathname)
        } else {
            searchTickets(prepareForApi(filters, applicationTokenContext.applicationToken)).then();
        }
    }

    return (
        <>
            <Container>
                <Row>
                    <Col xs={12}>
                        <p className={'text-lg font-weight-bold primary-color-dark my-2'}>
                            Tickets
                        </p>
                    </Col>
                </Row>
                <TicketsListSearchSection className={'px-4 pt-4'} filters={filters} loading={loading}/>
            </Container>
            <Row className={'pb-4 px-4'}>
                <div className={'board'}>
                    {
                        loading
                            ? <div className={'board-lists loading-div'}>
                                {
                                    Object.values(TicketStatuses).map(e => (
                                        <div key={e.id} className={'board-list'}/>
                                    ))
                                }
                            </div>
                            : !ticketGroups?.length
                                ? <TryAgain
                                    buttonText={Object.values(filters ?? {}).length ? 'Remove Filters' : 'Search Again'}
                                    onClick={searchAgain}/>
                                : <div className={'board-lists'}>
                                    {
                                        ticketGroups?.map(group => (
                                            <BoardList
                                                key={group.status?.id}
                                                group={group}
                                                getKey={e => e.ticketId}
                                                getItem={(boardItem) => <BoardListTicketCard item={boardItem}/>}
                                            />
                                        ))
                                    }
                                </div>
                    }
                </div>
            </Row>
        </>

    );
}

export default TicketsList;
