import {createContext} from "react";
import ErrorsStatic from "../../static-jsons/errors.json";
import BizKeyStatic from "../../static-jsons/bizkey.json";

/**
 * Context used for injecting the current state of the layout and its children
 */
export const LayoutContext = createContext({
    collapsed: false,
    disabled: false,
    noPadding: false,
});


/**
 * Context used for injecting the Json content (error messages, login messages) to the application
 * @type {React.Context<{bizkey: {aimKey: {title: string, message: string}, actKey: {title: string, message: string}, infiniteKey: {title: string, message: string}}, errors: {notFound: {title: string, message: string}, accessDenied: {title: string, message: string}, serverError: {title: string, message: string}}}>}
 */
export const JsonContentContext = createContext({errors: ErrorsStatic, bizkey: BizKeyStatic});


/**
 * Context used for injecting the current application token into the views that are dependant on it.
 */
export const ApplicationTokenContext = createContext({
    applicationToken: undefined,
});
