import moment from "moment";
import classnames from "classnames";
import React from "react";

const TaskCard = ({name, value: valueProp, date = false}) => {
    const red = (name === 'Type' && valueProp === 'Bug') || (name === 'Priority' && valueProp === 'High');
    const green = (name === 'Status' && valueProp === 'Done');

    let value = valueProp ?? 'N/A';
    if (date && valueProp) {
        value = moment(valueProp).format('DD MMMM, yyyy').split(',');
    }

    return (
        <div className={classnames('task-card', {'green': green, 'red': red})}>
            <div className={'background'}/>
            <p className={classnames('value', {date})}>
                {
                    date && valueProp
                        ? <>
                            <span> {value[0]},</span>
                            <span className={'mr-3'}/>
                            <span> {value[1]}</span>
                        </>
                        :
                        value
                }
            </p>
            <div className={'foreground'}>
                <p>
                    {name}
                </p>
            </div>
        </div>
    );
}

export default TaskCard;
