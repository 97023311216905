import React, {useEffect, useState} from 'react';
import {Prompt} from 'react-router-dom';
import useRouter from "../../../hooks/use-router";
import Dialog from "../dialog";
import OutlinedButton from "../button/outlined-button";


export const RouteLeavingGuardDefaultConfirmationTitle = 'Leave the Page?';
export const RouteLeavingGuardDefaultConfirmationMessage = 'Changes you made may not be saved. Are your sure you' +
    ' want to proceed?';

const RouteLeavingGuard = ({
                               when = true,
                               navigate,
                               shouldBlockNavigation,
                               confirmationTitle = RouteLeavingGuardDefaultConfirmationTitle,
                               confirmationBody = RouteLeavingGuardDefaultConfirmationMessage,
                           }) => {
    const {history} = useRouter();
    const [openPrompt, setOpenPrompt] = useState(false);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);
    const [savedLocation, setSavedLocation] = useState();

    /**
     * Listens for the changes in savedLocation and confirmationNavigation and with each change:
     * if confirmedNavigation and savedLocation then navigates the user to the savedLocation.
     */
    useEffect(() => {
        if (!confirmedNavigation || !savedLocation) return;
        if (navigate) {
            navigate(savedLocation.pathname);
        } else {
            history.push(savedLocation.pathname);
        }
    }, [confirmedNavigation, savedLocation]);

    /**
     * Adds the confirmation trigger for default browser behaviour on reload or back-button click confirmation
     */
    useEffect(() => {
        window.addEventListener('beforeunload', triggerConfirmationForBeforeunload);
        return () => window.removeEventListener('beforeunload', triggerConfirmationForBeforeunload)
    }, [])

    /**
     * Triggers browsers' confirmation popup in case of leaving the page or reloading the page.
     * @param e
     * @return {string}
     */
    const triggerConfirmationForBeforeunload = (e) => {
        e.preventDefault();
        e.returnValue = confirmationBody;
        return confirmationBody;
    }

    /**
     * Blocks the navigation of the route by checking whether the route is to be blocked or not. If blocked, shows
     * the confirmation prompt.
     * @param nextLocation {Location}
     * @return {boolean} whether to allow navigation
     */
    const blockNavigation = (nextLocation) => {
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            setOpenPrompt(true);
            setSavedLocation(nextLocation);
            return false;
        }
        return true;
    };

    /**
     * Closes the modal and sets the confirmedNavigation to trigger the location change
     */
    const onConfirmNavigation = () => {
        setOpenPrompt(false);
        setConfirmedNavigation(true);
    };

    /**
     * Closes the dialog
     */
    const closeDialog = () => {
        setOpenPrompt(false);
    }

    return (
        <>
            <Prompt when={when} message={blockNavigation}/>
            <Dialog
                open={openPrompt}
                setOpen={setOpenPrompt}
                className={'route-leaving-guard'}
                maxWidth={'xs'}
            >
                <div className={'body'}>
                    <div className={'title'}>
                        <p>
                            {confirmationTitle ?? RouteLeavingGuardDefaultConfirmationTitle}
                        </p>
                    </div>
                    <div className={'message'}>
                        <p>
                            {confirmationBody ?? RouteLeavingGuardDefaultConfirmationMessage}
                        </p>
                    </div>
                    <div className={'d-flex justify-content-around'}>
                        <OutlinedButton
                            onClick={closeDialog}
                            className={'w-50 mx-2 text-xs'}
                        >
                            Cancel
                        </OutlinedButton>
                        <OutlinedButton
                            onClick={onConfirmNavigation}
                            className={'w-50 mx-2 text-xs'}
                        >
                            Proceed
                        </OutlinedButton>
                    </div>
                </div>
            </Dialog>
        </>
    );
};


export default RouteLeavingGuard;
