import {makeStyles} from "@material-ui/core";

export const useSliderStyles = makeStyles(() => ({
    root: {
        color: props => `transparent !important`,
        height: props => `${props.trackHeight} !important`,
        padding: '15px 0',
    },
    thumb: {
        transition: 'all 0.2s linear',
        height: props => props.thumbHeight + 1,
        width: props => props.thumbHeight,
        marginTop: -7,
        marginLeft: props => -(props.thumbHeight / 2),
    },
    active: {},
    track: {
        height: props => `${props.trackHeight} !important`,
        color: props => `${props.trackColor} !important`,
        opacity: 0,
    },
    rail: {
        borderRadius: 5,
        height: props => props.railHeight,
        opacity: 0.38,
        backgroundColor: props => props.railColor,
    },
    mark: {},
    markActive: {},
    trackInverted: {
        '& $rail': {},
    }
}));


