import LocalStorageService from "./local_storage_service";
import moment from "moment";
import {matchPath} from "react-router";
import routes from "../../ui/routes";
import {Location} from 'history';

/**
 * Service that communicates with the local storage of the window
 */
export default class CacheService {
    static keys = LocalStorageService.keys;
    static setLocalStorage = LocalStorageService.set;
    static getLocalStorage = LocalStorageService.get;
    static localStorageRemove = LocalStorageService.remove;
    static localStorageRemoveAll = LocalStorageService.removeAll;

    /**
     * Sets the login information to the local storage of the window
     * @param token {string}
     * @param tokenLifeSpanMinutes {number} the number of minutes until the token expires
     * @param firstName {string} (OPTIONAL) the first name of the user
     * @param lastName {string} (OPTIONAL) the last name of the user
     * @param email {string} (OPTIONAL) the email of the user
     * @param type {any} (OPTIONAL) the type of the user
     */
    static setLoginInfo(token: string, tokenLifeSpanMinutes: number, firstName?: string, lastName?: string, email?: string, type?: any,) {
        this.setLocalStorage(this.keys.token, token);
        this.setLocalStorage(this.keys.tokenExpiration, new Date().setMinutes(new Date().getMinutes() + tokenLifeSpanMinutes).toString());
        if (firstName) this.setLocalStorage(this.keys.userFirstName, firstName);
        if (lastName) this.setLocalStorage(this.keys.userLastName, lastName);
        if (email) this.setLocalStorage(this.keys.userEmail, email);
        if (type) this.setLocalStorage(this.keys.userIsSuperAdmin, type?.id === 1 ? 'true' : 'false');
    }


    /**
     * Checks to see if the user has a token and their expiry time has not passed
     */
    static isLoggedIn(): boolean {
        return !!this.retrieveTokenAndExpiration();
    }

    /**
     * Retrieves the token and expiry time in minutes form the local storage.
     */
    static retrieveTokenAndExpiration() {
        let token = this.getLocalStorage(this.keys.token);
        let expDateString = this.getLocalStorage(this.keys.tokenExpiration);
        if (isNaN(parseInt(expDateString))) {
            return undefined;
        }
        let expDate = expDateString && moment(parseInt(expDateString));
        let currentDate = moment();
        if (!token || !expDate || currentDate >= expDate) {
            return undefined;
        }
        return {token, tokenExpiration: moment.duration(expDate.diff(currentDate)).asMinutes()};
    };

    /**
     * Logs the user out the system by removing the user from the local storage
     */
    static removeUserInformation() {
        this.localStorageRemove(this.keys.token);
        this.localStorageRemove(this.keys.tokenExpiration);
        this.localStorageRemove(this.keys.userFirstName);
        this.localStorageRemove(this.keys.userLastName);
        this.localStorageRemove(this.keys.userEmail);
        this.localStorageRemove(this.keys.userIsSuperAdmin);
    }

    /**
     * Caches the location to the local storage only anf only if the route is not of error, or auth routes
     * @param location {string}
     */
    static cacheUrl(location?: Location | null) {
        if (!location) return;
        if (!location?.pathname.includes(routes.error.base)
            && !matchPath(location.pathname, {path: routes.auth.logout})) {
            const unCacheableRoutes = [routes.error.base, routes.error.accessDeniedForSection, routes.error.serverErrorForSection];
            if (!!matchPath(location.pathname, {path: unCacheableRoutes, exact: false})) return;
            this.setLocalStorage(this.keys.cachedUrl, JSON.stringify(location));
        }
    }

    /**
     * Gets the cached url from the local storage
     */
    static getCachedUrl(): Location | null {
        const cachedUrl = this.getLocalStorage(this.keys.cachedUrl);
        if (cachedUrl) return JSON.parse(cachedUrl);
        return cachedUrl;
    }

    /**
     * Syncs the user applications with the values saved in the local storage if the list of applications are not
     * empty. Otherwise remove the list of applications.
     * @param applications
     */
    static syncUserApplications(applications: any[]) {
        if (!applications?.length) {
            this.setLocalStorage(this.keys.userApplications, JSON.stringify([]));
            return;
        }
        this.setLocalStorage(this.keys.userApplications, JSON.stringify(applications));
    }

    /**
     * Fetches the application token of the selected application name.
     * if no application name is provided, fetches the application token of the current application name.
     * @param applicationName {string | undefined}
     */
    static getApplicationToken(applicationName?: string) {
        const savedApplications = this.getLocalStorage(this.keys.userApplications);
        if (!savedApplications) return undefined;
        const parsedApplications = JSON.parse(savedApplications);
        if (!applicationName) {
            const selectedApplicationName = this.getLocalStorage(this.keys.selectedApplicationName);
            if (!selectedApplicationName) return undefined;
            return parsedApplications.find((e: any) => e.name === selectedApplicationName)?.applicationToken;
        }
        return parsedApplications.find((e: any) => e.name === applicationName)?.applicationToken;
    }


    /**
     * Fetches the list of applications of the user.
     */
    static getApplicationsOfUser() {
        const savedApplications = this.getLocalStorage(this.keys.userApplications);
        if (!savedApplications) return [];
        return JSON.parse(savedApplications) ?? [];
    }
}
