import React from 'react';
import {MenuItem, Select as MuiSelect, SvgIcon} from "@material-ui/core";
import {Select as FormSelect} from "./_base";
import classNames from "classnames";
import classnames from "classnames";
import {useSelectStyles} from "./_styles";
import {ReactComponent as Icon} from '../../../../assets/images/select-icon.svg';
import {Label} from "reactstrap";


export const OutlinedSelect = ({
                                   placeholder = 'select',
                                   ariaLabel = 'select',
                                   value = '',
                                   fullWidth = false,
                                   fontSize = 14,
                                   autoWidth = false,
                                   projects = false,
                                   multiple = false,
                                   displayEmpty = true,
                                   onChange,
                                   label,
                                   name = label,
                                   header = placeholder,
                                   type,
                                   data,
                                   renderValue,
                                   disabled,
                                   className = '',
                                   ...props
                               }) => {
    const classes = useSelectStyles({data, fontSize, projectsSelect: !!projects});

    /**
     * The Menu props for blurring the dropdown after its been opened and to bring the menu to the front to prevent
     * its edges being clipped.
     * @type {{onEnter: defaultMenuProps.onEnter, PaperProps: {style: {transform: string}}}}
     */
    const defaultMenuProps = {
        onEnter: () => {
            setTimeout(() => {
                if (document.activeElement) {
                    document.activeElement.blur();
                }
            }, 500);
        },
        PaperProps: {
            elevation: 4,
            style: {
                transform: 'translate3d(0, 0, 0)'
            }
        },
        MenuListProps: {
            classes: {
                root: classes.menuRoot
            },
        },
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
        },
        getContentAnchorEl: null
    }

    /**
     * The Default Renderer for the values of the dropdown
     * @param value {any}
     * @return {JSX.Element}
     */
    const defaultRenderValue = (value) => {
        // no value selected => the header
        if (!value) {
            return (
                <p>
                    {header}
                </p>
            )
        }
        // the external render value exists
        if (renderValue) {
            return renderValue(value);
        }
        // multiple values can be rendered
        if (multiple) {
            return (
                <p className={'text-wrap'}>
                    {value.map(e => (
                        <span key={e.label ?? e.name ?? e.title}
                              className='mr-3'>
                            {e.label ?? e.name ?? e.title}
                        </span>
                    ))}
                </p>
            )
        }
        // default
        return (
            <p>
                {value.label ?? value.name ?? value.title ?? value.description ?? `${value}`}
            </p>
        )
    }


    return (
        <div className={classnames('d-flex flex-column align-items-start input', className)}>
            <Label for={name} className={classnames(classes.label, {'w-100': fullWidth})}>
                {label}
            </Label>
            <MuiSelect
                displayEmpty={displayEmpty}
                disabled={disabled || !data?.length}
                fullWidth={fullWidth}
                autoWidth={autoWidth}
                type={type}
                variant={'outlined'}
                name={name}
                value={value}
                onChange={onChange}
                multiple={multiple}
                classes={{
                    icon: classes.icon,
                    iconOpen: classes.iconOpen,
                    root: classes.root,
                }}
                className={classNames(classes.input, 'select')}
                placeholder={placeholder}
                inputProps={{'aria-label': ariaLabel, className: classes.innerInput}}
                renderValue={defaultRenderValue}
                MenuProps={defaultMenuProps}
                IconComponent={(props) => (
                    <SvgIcon component={Icon} className={classNames(props.className)}/>
                )}
                {...props}
            >
                {
                    data &&
                    [
                        (header?.length
                                ? <MenuItem
                                    unselectable={'on'}
                                    key={'first-item'}
                                    disabled
                                >
                                    {header}
                                </MenuItem>
                                : null
                        ),
                        ...data?.map(item => (
                            <MenuItem
                                key={item.id}
                                value={item.value}
                                disabled={item.disabled}
                            >
                                {defaultRenderValue(item.value)}
                            </MenuItem>
                        ))].filter(e => e)
                }
            </MuiSelect>
        </div>
    );
}


export const OutlinedSelectForm = ({
                                       label,
                                       placeholder = 'select...',
                                       ariaLabel = 'input',
                                       required = false,
                                       fullWidth = false,
                                       autoWidth = false,
                                       multiple = false,
                                       displayEmpty = true,
                                       fontSize = 14,
                                       helperText,
                                       name,
                                       data,
                                       renderValue,
                                       header,
                                       fieldProps,
                                       inputLabelProps,
                                       formControlProps,
                                       formHelperTextProps,
                                       disabled = false,
                                       className = '',
                                       ...props
                                   }) => {
    const classes = useSelectStyles({data, fontSize});

    /**
     * The Menu props for blurring the dropdown after its been opened and to bring the menu to the front to prevent
     * its edges being clipped.
     * @type {{onEnter: defaultMenuProps.onEnter, PaperProps: {style: {transform: string}}}}
     */
    const defaultMenuProps = {
        onEnter: () => {
            setTimeout(() => {
                if (document.activeElement) {
                    document.activeElement.blur();
                }
            }, 500);
        },
        PaperProps: {
            elevation: 4,
            style: {
                transform: 'translate3d(0, 0, 0)'
            }
        },
        MenuListProps: {
            classes: {
                root: classes.menuRoot
            },
        },
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
        },
        getContentAnchorEl: null
    }

    /**
     * The Default Renderer for the values of the dropdown
     * @param value {any}
     * @return {JSX.Element}
     */
    const defaultRenderValue = (value) => {
        // no value selected => the header
        if (!value) {
            return (
                <p>
                    {header}
                </p>
            )
        }
        // the external render value exists
        if (renderValue) {
            return renderValue(value);
        }
        // multiple values can be rendered
        if (multiple) {
            return (
                <p className={'text-wrap'}>
                    {value.map(e => (
                        <span key={e.label ?? e.name ?? e.title}
                              className='mr-3'>
                            {e.label ?? e.name ?? e.title}
                        </span>
                    ))}
                </p>
            )
        }
        // default
        return (
            <p>
                {value.label ?? value.name ?? value.title ?? value.description ?? `${value}`}
            </p>
        )
    }


    return (
        <div className={classnames('d-flex flex-column align-items-start')}>
            <Label for={name} className={classnames(classes.label, {'w-100': fullWidth})}>
                {label}
            </Label>
            <FormSelect
                displayEmpty={displayEmpty}
                disabled={!data?.length || disabled}
                fullWidth={fullWidth}
                autoWidth={autoWidth}
                required={!!required}
                name={name}
                variant={'outlined'}
                multiple={multiple}
                placeholder={placeholder}
                classes={{
                    icon: classes.icon,
                    iconOpen: classes.iconOpen,
                    root: classes.root,
                }}
                className={classNames(classes.input, 'select')}
                inputProps={{'aria-label': ariaLabel, className: classes.innerInput}}
                renderValue={defaultRenderValue}
                MenuProps={defaultMenuProps}
                IconComponent={(props) => (
                    <SvgIcon component={Icon} className={classNames(props.className)}/>
                )}
                helperText={helperText}
                fieldProps={fieldProps}
                inputLabelProps={inputLabelProps}
                formControlProps={formControlProps}
                formHelperTextProps={formHelperTextProps}
                {...props}
            >
                {
                    data &&
                    [
                        (header?.length
                                ? <MenuItem
                                    key={'first-item'}
                                    value={undefined}
                                    disabled
                                    selected={false}
                                >
                                    {header}
                                </MenuItem>
                                : null
                        ),
                        ...data?.map(item => (
                            <MenuItem
                                key={item.id}
                                value={item.value}
                                disabled={item.disabled}
                            >
                                {defaultRenderValue(item.value)}
                            </MenuItem>
                        ))].filter(e => e)
                }
            </FormSelect>
        </div>
    );
}
