import Colors from "../../assets/js/colors";


/**
 * apiCallback methods
 */
export const apiMethods = {
    get: 'GET' as const,
    post: 'POST' as const,
    put: 'PUT' as const,
    delete: 'DELETE' as const,
    patch: 'PATCH' as const,
};

/**
 * Colors used for the questionnaire in to submit ticket section
 */
export const QuestionnaireColors = {
    hint: 'green' as const,
    step: 'purple' as const,
}

/**
 * The Three Principals of BizKey Tech with their colors
 */
export const BizKeys = {
    aimKey: {
        name: 'AIM' as const,
        focusColor: Colors.orange,
        color: Colors.orangeLight,
    },
    actKey: {
        name: 'ACT' as const,
        focusColor: Colors.green,
        color: Colors.greenLight,
    },
    infiniteKey: {
        name: 'INFINITE' as const,
        focusColor: Colors.blue,
        color: Colors.blueLight,
    }
}

/**
 * The lines used in the auth pages
 */
export const LoginLines = {
    topHorizontal: 'topHorizontal' as const,
    topRightVertical: 'topRightVertical' as const,
    bottomRightVertical: 'bottomRightVertical' as const,
    bottomHorizontal: 'bottomHorizontal' as const,
}

//TODO: replace with api call result in production
/**
 * The statuses of a ticket
 */
export const TicketStatuses = {
    submitted: {
        id: 1,
        name: 'Submitted',
    },
    readyForReview: {
        id: 2,
        name: 'Ready For Review',
    },
    underReview: {
        id: 3,
        name: 'Under Review',
    },
    open: {
        id: 4,
        name: 'Open',
    },
    closed: {
        id: 5,
        name: 'Closed',
    },
    notApproved: {
        id: 6,
        name: 'Not Approved',
    },
}

/**
 * The data types used in submission of a ticket to create fields.
 */
export const SubmitTicketFormDataTypes = {
    number: 3 as const,
    options: 4 as const,
    shortText: 5 as const,
    longText: 6 as const,
    date: 7 as const,
    time: 8 as const,
}

/**
 * Different types of file types the file-fetcher of browser accepts
 */
export const InputFileAcceptTypes = {
    png: '.png' as const,
    jpg: '.jpg' as const,
    jpeg: '.jpeg' as const,
    mp4: '.mp4' as const,
    mov: '.mov' as const,
    webm: '.webm' as const,
    flv: '.flv' as const,
    wmv: '.wmv' as const,
    pdf: '.pdf' as const,
    csv: '.csv' as const,
    _spreadsheet: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' as const,
    _excel: 'application/vnd.ms-excel' as const,
    excel: '.xls' as const,
    numbers: '.numbers' as const,
    zip: '.zip' as const,
    rar: '.rar' as const,
}

/**
 * The available colors that are used in the dashboard charts.
 */
export const DashboardChartColors = [
    Colors.red,
    Colors.secondaryColor,
    Colors.secondaryColorDark,
    Colors.green,
    Colors.ternaryColor,
    Colors.primaryColorDark,
    Colors.primaryColor,
]

/**
 * The background color themes for the submitted tickets.
 */
export const SubmittedFileBackgrounds = {
    dark: 'dark' as const,
    light: 'light' as const,
}


/**
 * Types that are supported in the application.
 */
export const SupportedFileTypes = {
    image: 1 as const,
    pdf: 2 as const,
    video: 3 as const,
    unknown: -1 as const,
}

/**
 * The default options to parse or stringify query strings.
 */
export const QueryStringDefaultParseOptions = {
    arrayFormat: 'bracket-separator' as const,
    arrayFormatSeparator: '|' as const,
}

/**
 * The Types of Videos
 */
export const VideoTypes = {
    small: 'small',
    large: 'large',
}

/**
 * The Names of the Tasks statuses
 */
export const LiveTaskStatusNames = {
    toDo: 'To Do' as const,
    inProgress: 'In Progress' as const,
    toTest: 'To Test' as const,
    done: 'Done' as const,

}
