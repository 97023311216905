import React, {useContext, useEffect, useState} from "react";
import useRouter from "../../../../hooks/use-router";
import Api from "../../../../../core/services/api_service";
import useIsMounted from "../../../../hooks/use-is-mounted";
import {Col, Row} from "reactstrap";
import {ReactComponent as CalendarIcon} from "../../../../../assets/images/calendar.svg";
import moment from "moment";
import IconPlaceHolder from "../../../../../assets/images/icon-placeholder.svg";
import {ReactComponent as BackIcon} from "../../../../../assets/images/back-icon.svg";
import {ApplicationTokenContext} from "../../../../contexts";
import TaskCard from "../../../../components/app-specific/task-card";
import classnames from "classnames";
import TryAgain from "../../../../components/app-specific/try-again";
import {ReactComponent as NoResults} from '../../../../../assets/images/no-results.svg';


const Task = () => {
    const {params, history} = useRouter();
    const [task, setTask] = useState();
    const [loading, setLoading] = useState(true);
    const applicationTokenContext = useContext(ApplicationTokenContext);
    const isMounted = useIsMounted();


    /**
     * Listens for the changes in the applicationToken and the code of task and with each change:
     * fetches the data from api.
     */
    useEffect(() => {
        getTaskInformation();
    }, [applicationTokenContext.applicationToken, params.code])

    /**
     * Fetches the information of the task based on the
     */
    const getTaskInformation = () => {
        setLoading(true);
        const forApi = {
            jiraCode: params.code,
            applicationToken: applicationTokenContext.applicationToken,
        };
        Api.getTaskByCode(forApi).then((response) => {
            if (!isMounted()) return;
            if (response?.resultFlag) {
                setTask(response.data);
            } else {
                setTask(undefined);
            }
            setLoading(false);
        })
    }


    return (
        <>
            <Row className={classnames('task ml-2 mt-4', {'loading-div': loading})}>
                {
                    loading
                        ? <>
                            <Col xs={12}>
                                <div className={'d-flex'}>
                                    <div className={'mr-4'}>
                                        <BackIcon onClick={history.goBack} className={'topbar-icon'}/>
                                    </div>
                                    <div className={'d-flex flex-column ml-2 w-100'}>
                                        <div className={'title'}>
                                            <div/>
                                        </div>
                                        <div className={'date'}>
                                            <div/>
                                        </div>
                                        <div className={'mt-4'}>
                                            <div className={'summary'}>
                                                <div/>
                                            </div>
                                        </div>
                                        <div className={'d-flex flex-wrap w-100 '}>
                                            <Col xs={12} lg={7} className={'pr-4 p-0 mt-3'}>
                                                <div className={'description'}>
                                                    {
                                                        Array(15).fill(null).map((e, index) =>
                                                            <div key={index}/>
                                                        )
                                                    }
                                                </div>
                                            </Col>
                                            <div className={'card-container p-0 mt-3'}>
                                                {Array(9).fill(null).map((e, index) => (
                                                    <div key={index}>
                                                        <div/>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </>
                        : !task
                            ? <>
                                <Col xs={12}>
                                    <div className={'d-flex justify-content-center'}>
                                        <NoResults/>
                                    </div>
                                    <TryAgain onClick={getTaskInformation}
                                              text={
                                                  <>There is no task with the code
                                                      <span
                                                          className="primary-color-dark mx-2 font-weight-semi-bold">
                                                      {params.code}
                                              </span>.
                                                  </>
                                              }
                                    />
                                </Col>
                            </>
                            : <>
                                <Col xs={12}>
                                    <div className={'d-flex'}>
                                        <div className={'mr-4'}>
                                            <BackIcon onClick={history.goBack} className={'topbar-icon'}/>
                                        </div>
                                        <div className={'d-flex w-100 flex-column ml-2'}>
                                            <div className={'d-flex align-items-center mb-1'}>
                                                <img className={'mr-2 icon'} src={task?.type?.iconURL ?? IconPlaceHolder}
                                                     alt="icon"/>
                                                <p className={'title'}>
                                                    {task?.jiraCode ?? params.code ?? ''}
                                                </p>
                                            </div>
                                            <div className={'d-flex align-items-center mb-2'}>
                                                <CalendarIcon className={'icon date fill-primary-color-dark'}/>
                                                <p className={'text-xs primary-color-dark font-weight-semi-bold'}>
                                                    {moment(task?.created).format('yyyy-MM-DD')}
                                                </p>
                                            </div>
                                            <div className={'mt-4'}>
                                                <p className={'summary'}>
                                                    {task?.summary ?? ''}
                                                </p>
                                            </div>
                                            <div className={'d-flex flex-wrap w-100 '}>
                                                <Col xs={12} lg={7} className={'pr-4 p-0 mt-3'}>
                                                    <p className={'description'}>
                                                        {task?.description ?? ''}
                                                    </p>
                                                </Col>
                                                <div className={'card-container p-0 mt-3'}>
                                                    {
                                                        [
                                                            <TaskCard key={'status'} name={'Status'} value={task?.status}/>,
                                                            <TaskCard key={'epic'} name={'Epic'} value={task?.epic}/>,
                                                            <TaskCard key={'type'} name={'Type'}
                                                                      value={task?.type?.title}/>,
                                                            <TaskCard key={'code'} name={'Code'} value={task?.jiraCode}/>,
                                                            <TaskCard key={'priority'} name={'Priority'}
                                                                      value={task?.priority}/>,
                                                            <TaskCard key={'version'} name={'Version'}
                                                                      value={task?.version}/>,
                                                            <TaskCard key={'created'} name={'Created'} date
                                                                      value={task?.created}/>,
                                                            <TaskCard key={'updated'} name={'Updated'} date
                                                                      value={task?.updated}/>,
                                                            <TaskCard key={'resolved'} name={'Resolved'} date
                                                                      value={task?.resolved}/>,
                                                        ]
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </>
                }
            </Row>
        </>
    );
}

export default Task;
