import React, {useContext, useEffect, useState} from "react";
import * as $ from 'jquery';
import useIsMounted from "../../../../hooks/use-is-mounted";
import {ApplicationTokenContext, LayoutContext} from "../../../../contexts";
import Api from "../../../../../core/services/api_service";
import {groupBy} from "../../../../../core/services/utils";
import moment from "moment/moment";
import classnames from "classnames";
import TryAgain from "../../../../components/app-specific/try-again";
import {SvgIcon} from "@material-ui/core";
import {ReactComponent as RadioIconChecked} from "../../../../../assets/images/radio-icon-checked.svg";


/**
 * Timeout Function that clears the previous timeout and sets a new one.
 * @type {function(): function(*=, *=): void}
 */
const delay = (() => {
    let timer = 0;
    return (callback, ms) => {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
});

/**
 * Given a list of tasks, groups them based on their status, gets their counts object that has been grouped based on
 * their type.
 * @param versions {any[]}
 */
const groupVersions = (versions) => {
    const groups = {};
    versions.forEach(version => {
        groups[version.name] = {
            date: version.releaseDate,
            notes: {},
        };
        // group the versions based on their type.
        const groupedVersionsByType = Object.entries(groupBy(version?.version ?? [], (e) => e.type?.title));
        for (let [typeName, list] of groupedVersionsByType) {
            groups[version.name].notes[typeName] = [...(groups[version.name].notes[typeName] ?? []), ...list?.map(e => e.summary)];
        }
    })
    return groups;
}

// The easing function used for scrolling the page.
const scrollEase = 'linear';

const Versions = () => {
    const [versions, setVersions] = useState({});
    const [loading, setLoading] = useState(true);
    const [calledOnce, setCalledOnce] = useState(false);
    const applicationTokenContext = useContext(ApplicationTokenContext);
    const [applicationToken, setApplicationToken] = useState(applicationTokenContext.applicationToken);
    const layoutContext = useContext(LayoutContext);
    const isMounted = useIsMounted();

    /**
     * Listens for the changes in the applicationToken and with each change, fetches the data from api.
     */
    useEffect(() => {
        if (applicationTokenContext.applicationToken === applicationToken) {
            if (!calledOnce) {
                setCalledOnce(true);
                setLoading(true);
                getVersions();
            }
            return;
        }
        setApplicationToken(applicationTokenContext.applicationToken);
        setCalledOnce(false);
        setLoading(true);
        getVersions();
    }, [applicationTokenContext.applicationToken])

    /**
     * Listens for the changes in loading and versions and with each change
     * if not loading and versions have a valid length, sets up the required animations for the versions in the DOM.
     */
    useEffect(() => {
        if (loading || !Object.values(versions ?? {})?.length) return;
        setupAnimations();
    }, [loading, versions])


    /**
     * Sets up the animation and event listeners and then scrolls the window to initiate some of the event attachments.
     */
    const setupAnimations = () => {
        setupFade();
        setupClickToScroll();
        setupVersionAnimation();
        setupScrollToTop();
        enableScrollAbortion();
        $(window).scroll();
    }


    /**
     * Fetches the list of live tasks for the current sprint of the users selected application and if the response
     * is successful, sets the single.
     */
    const getVersions = () => {
        Api.getAllVersionsOfApplication(applicationTokenContext.applicationToken).then((response) => {
            if (!isMounted()) return;
            if (response?.resultFlag) {
                setVersions(groupVersions(response.data));
            } else {
                setVersions({});
            }
            setLoading(false);
        });
    }


    /**
     * Allows user to cancel scroll animation by manually scrolling
     */
    const enableScrollAbortion = () => {
        const $viewport = $('html, body');
        $viewport.on('scroll mousedown DOMMouseScroll mousewheel keyup', (event) => {
            if (event.which > 0 || event.type === 'mousedown' || event.type === 'mousewheel') {
                $viewport.stop();
            }
        });
    }

    /**
     * Binds the onClick handler for scrolling to the top of the window
     */
    const setupScrollToTop = () => {
        $('.trigger-scroll-to-top').click((event) => {
            event.preventDefault();
            $('html, body').animate({scrollTop: 0}, 0, scrollEase);
        });

    }

    /**
     * Listens the changes of the scroll and resize of the window and for each change:
     * For each version in the window, if the version is not in the bounds of the window then attaches a hidden class
     * otherwise removes it.
     */
    const setupVersionAnimation = () => {
        const versions = $('.version-wrapper .version');

        $(window).on('scroll resize', () => {
            const currScroll = $(window).scrollTop() > $(document).scrollTop() ? $(window).scrollTop() : $(document).scrollTop(),
                windowHeight = $(window).height(), // Needs to be here because window can resize
                overScroll = Math.ceil(windowHeight * .20),
                threshHold = (currScroll + windowHeight) - overScroll;

            versions.each(function () {
                const version = $(this);
                const versionScroll = version.offset().top;

                if (versionScroll > threshHold) {
                    version.addClass('hidden');
                } else {
                    version.removeClass('hidden');
                }
            });

        });
    }

    /**
     * Listens the changes of the scroll and resize of the window and for each change:
     * For each version in the window if the version in in the top half of the screen, sets an active class and then
     * gets
     * their color and adds their appropriate color.
     */
    const setupFade = () => {
        const versions = $('.version-wrapper .version').reverse();
        const stemWrapper = $('.stem-wrapper');
        const halfScreen = $(window).height() / 2;

        $(window).on('scroll resize', () => {
            delay()(() => {
                const currScroll = $(window).scrollTop() > $(document).scrollTop() ? $(window).scrollTop() : $(document).scrollTop();
                const scrollSplit = currScroll + halfScreen;

                versions.removeClass('active').each(function () {
                    const versions = $(this);
                    const versionOffset = versions.offset().top;

                    if (scrollSplit > versionOffset) {
                        // Add active class to fade in
                        versions.addClass('active')

                        // Get versions color
                        const color = versions.data('stem-color') ? versions.data('stem-color') : null;
                        const allColors = 'color-1 color-2 color-3';

                        stemWrapper.removeClass(allColors);

                        if (color !== null) {
                            stemWrapper.addClass('color-' + color);
                        }
                        return false;
                    }
                });
            }, 20);

        });
    }

    /**
     * Sets up the onClick event for each of the versions and stem icons.
     * By clicking on each of the icons
     */
    const setupClickToScroll = () => {
        $('.version-wrapper .version .stem-overlay .icon').click(function (e) {
            e.preventDefault();
            const icon = $(this);
            const version = icon.closest('.version');
            const versionTopOffset = version.offset().top;
            const versionHeight = version.height();
            const halfScreen = $(window).height() / 2;
            const scrollTo = versionTopOffset - halfScreen + (versionHeight / 2);

            $('html, body').animate({scrollTop: scrollTo}, 0, scrollEase);
        });

    }


    return (
        <div className={classnames('versions', {'hide': !Object.entries(versions)?.length && !loading})}>
            <div className={classnames("stem-wrapper", layoutContext)}>
                {
                    !loading &&
                    <>
                        <div className="stem"/>
                        <div className="stem-background"/>
                        <div className="stem-background-inverse"/>
                    </>
                }
            </div>

            <header className="section header">
                <div className="section-inner">
                    <div className="master-head">
                        <h1 className="page-title">Version History</h1>
                    </div>
                </div>
            </header>

            <div className="section main-content">
                <div className="section-inner">
                    <div className="stem-padding"/>
                    <div className={"version-wrapper"}>
                        {
                            loading
                                ? <></>
                                : !Object.entries(versions)?.length
                                    ? <TryAgain onClick={getVersions}
                                                text={'This application does not have any version' +
                                                    ' history yet. Please contact our support in case of problems. You may try to load' +
                                                    ' the history again.'}
                                                buttonText={'Load Again'}
                                    />
                                    : Object.entries(versions)?.map(([versionName, {date, notes}], index) => {
                                        return (
                                            <article key={versionName}
                                                     className="version version-icon"
                                                     data-stem-color={((index % 3) + 1).toString()}
                                            >
                                                <div className="stem-overlay">
                                                    <div className="icon"/>
                                                    <div className="stem-mask"/>
                                                </div>
                                                <div className="version-content">
                                                    <h2 className="version-title">
                                                        Version {versionName}
                                                    </h2>
                                                    <span className={'version-date'}>
                                                    {
                                                        moment(date).format('DD-MM-yyyy')
                                                    }
                                                </span>
                                                    {
                                                        Object.entries(notes).map(([name, list]) => (
                                                            <div key={name} className={'pt-1'}>
                                                                <h2 className="version-section-title">
                                                                    {name}
                                                                </h2>
                                                                <div className="entry-content">
                                                                    <ul>
                                                                        {
                                                                            list.map(note => (
                                                                                <li key={note}>
                                                                                    <SvgIcon
                                                                                        component={RadioIconChecked}
                                                                                        className={classnames('')}
                                                                                    />
                                                                                    <p>{note}</p>
                                                                                </li>
                                                                            ))
                                                                        }
                                                                    </ul>

                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </article>

                                        );
                                    })
                        }
                    </div>
                    <div className="single-stem-icon scroll-to-top trigger-scroll-to-top"
                         onClick={() => window.scroll(0, 0)}
                    />

                </div>
            </div>
            <div className={'footer'}/>
        </div>
    );
}

export default Versions;
