import {Col} from "reactstrap";
import {ReactComponent as CalendarIcon} from "../../../../../assets/images/calendar.svg";
import moment from "moment";
import React from "react";

const DashboardTicketCard = ({ticket, onClick}) => {

    return (
        <div className={'d-flex w-100 flex-wrap info-card'} onClick={() => onClick(ticket?.ticketId)}>
            <Col xs={12} className={'p-0'}>
                <p className={'title'}>
                    {ticket?.ticketCode ?? ''}
                </p>
            </Col>
            <Col xs={12}
                 className={'p-0 mt-1 d-flex justify-content-between'}>
                <p className={'status'}>
                    {ticket?.type?.title ?? ''}
                </p>
                <p className={'status'}>
                    Status:
                    <span className={'value ml-1'}>
                        {ticket?.status?.title ?? ''}
                    </span>
                </p>
            </Col>
            <Col xs={12}
                 className={'p-0 mt-1 d-flex justify-content-between'}>
                <p className={'service'}>
                    {ticket?.service?.title ?? ''}
                </p>
                <div
                    className={'d-flex align-items-center justify-content-end'}>
                    <CalendarIcon
                        className={'icon fill-ternary-color-light mr-2'}/>
                    <p className={'text-xs black'}>{moment(ticket?.submitDateTime).format('yyyy-MM-DD')}</p>
                </div>
            </Col>
            <Col xs={12} className={'p-0 mt-1'}>
                <p className={'description'}>
                    {
                        ticket?.question?.body?.length > 100
                            ? ticket?.question?.body?.substring(0, 100)?.concat('...')
                            : ticket?.question?.body ?? ''}
                </p>
            </Col>
        </div>
    );
}

export default DashboardTicketCard;
