import React, {useState} from "react";
import {DatePicker as MuiDatePicker} from "@material-ui/pickers";
import {DatePicker as FormDatePicker} from "mui-rff";
import {SvgIcon} from "@material-ui/core";
import {ReactComponent as CalendarIcon} from '../../../../assets/images/calendar.svg';
import classnames from "classnames";
import {Label} from "reactstrap";
import {useDatePickerStyles} from "./_styles";

export const DatePicker = ({
                               disableToolbar = false,
                               disableFuture = true,
                               format = 'DD/MM/yyyy',
                               disabled,
                               placeHolder,
                               name,
                               label,
                               onChange,
                               value,
                               invalidDateMessage,
                               fullWidth = true,
                               searchForm = true,
                               ...props
                           }) => {
    const [open, setOpen] = useState(false);
    const classes = useDatePickerStyles({searchForm});

    /**
     * Closes the current dialog and calls the onChange callback to set the new date
     * @param date
     */
    const onDateAccept = (date) => {
        // setOpen(false);
        if (onChange) onChange(date);
    }

    return (
        <div className={classnames('d-flex flex-column align-items-start')}>
            <Label for={name} className={classnames(classes.label, {'w-100': fullWidth})}>
                <p className={classes.labelText}>{label}</p>
                <div className={'mx-1'}/>
                <SvgIcon className={'icon'} onClick={() => setOpen(prevState => !prevState)}/>
            </Label>
            <div className={classnames('d-flex align-items-start date-picker', {'search-form': searchForm})}>
                <MuiDatePicker
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    onAccept={onDateAccept}
                    placeholder={placeHolder}
                    classes={{input: classes.input}}
                    disableToolbar={disableToolbar}
                    disabled={disabled}
                    format={format}
                    variant={'inline'}
                    inputVariant={'outlined'}
                    name={name}
                    value={value}
                    onChange={onChange}
                    disableFuture={disableFuture}
                    invalidDateMessage={invalidDateMessage}
                    {...props}
                />
                <div className={'mx-1'}/>
                <SvgIcon component={CalendarIcon} className={'icon'} onClick={() => setOpen(prevState => !prevState)}/>
            </div>
        </div>
    );
}

export const DatePickerForm = ({
                                   disableToolbar = false,
                                   disableFuture = true,
                                   format = 'DD/MM/yyyy',
                                   disabled,
                                   placeHolder,
                                   name,
                                   label,
                                   invalidDateMessage,
                                   required,
                                   fullWidth = true,
                                   searchForm = true,
                                   ...props
                               }) => {
    const [open, setOpen] = useState(false);
    const classes = useDatePickerStyles({searchForm});

    /**
     * Closes the current dialog and calls the onChange callback to set the new date
     * @param date
     */
    const onDateAccept = (date) => {
        // setOpen(false);
    }

    return (
        <div className={classnames('d-flex flex-column align-items-start')}>
            <Label for={name} className={classnames(classes.label, {'w-100': fullWidth})}>
                <p className={classes.labelText}>{label}</p>
                <div className={'mx-1'}/>
                <SvgIcon className={'icon'}/>
            </Label>
            <div className={classnames('d-flex align-items-start date-picker', {'search-form': searchForm})}>
                <FormDatePicker
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    InputProps={{classes: {input: classes.input}}}
                    onAccept={onDateAccept}
                    placeholder={placeHolder}
                    disableToolbar={disableToolbar}
                    disabled={disabled}
                    format={format}
                    variant={'inline'}
                    inputVariant={'outlined'}
                    name={name}
                    required={!!required}
                    disableFuture={disableFuture}
                    invalidDateMessage={invalidDateMessage}
                    {...props}
                />
                <div className={'mx-1'}/>
                <SvgIcon component={CalendarIcon} className={'icon'}
                         onClick={() => setOpen(prevState => !prevState)}/>
            </div>
        </div>
    );
}
