import React, {forwardRef} from "react";
import {Dialog as MuiDialog, Paper, Slide} from '@material-ui/core';
import Draggable from 'react-draggable';
import {ReactComponent as CloseIcon} from "../../../../assets/images/dialog-close-icon.svg";
import classnames from "classnames";


const SlideTransitionComponent = forwardRef((
    {direction, ...props},
    ref) => {

    return (
        <Slide direction={direction} ref={ref} {...props} />
    );
});

const DraggableComponent = (props) => {
    const {onCloseClick, slide, slideDirection, extraClassName, ...rest} = props;

    return (
        <>
            <Draggable handle="#draggable-dialog" cancel={'[class*="MuiDialogContent-root"]'}>
                <div className={'d-flex flex-column dialog-paper-container'}>
                    <div className={'dialog-close-container'}>
                        <CloseIcon onClick={onCloseClick} className={'close-icon'}/>
                    </div>
                    <Paper id={'draggable-dialog'} {...{
                        ...rest,
                        className: classnames(rest.className, 'dialog-paper', {
                            [slideDirection]: slide,
                            [extraClassName]: !!extraClassName?.length
                        })
                    }}/>
                </div>
            </Draggable>
        </>

    );
}

const Dialog = ({
                    open,
                    setOpen,
                    disableBackdropClick = false,
                    disableEscapeKeyDown = false,
                    maxWidth = false,
                    fullWidth = false,
                    fullScreen = false,
                    scroll = 'paper',
                    children,
                    slide = false,
                    slideDirection = 'left',
                    className = '',
                    ...props
                }) => {

    return (
        <MuiDialog
            className={classnames({'slide-dialog': slide, [slideDirection]: slide})}
            onClose={() => setOpen(false)}
            PaperComponent={DraggableComponent}
            PaperProps={{onCloseClick: () => setOpen(false), slide, slideDirection, extraClassName: className}}
            disableBackdropClick={disableBackdropClick}
            disableEscapeKeyDown={disableEscapeKeyDown}
            maxWidth={maxWidth}
            fullScreen={fullScreen}
            scroll={scroll}
            fullWidth={fullWidth}
            open={open}
            {
                ...(slide
                    ? {
                        TransitionComponent: SlideTransitionComponent,
                        TransitionProps: {direction: slideDirection}
                    }
                    : {})
            }
            {...props}
        >
            {
                children
            }
        </MuiDialog>
    );
}

export default Dialog;
