import React, {useContext, useState} from "react";
import useRouter from "../../../../../../hooks/use-router";
import {Col, Row} from "reactstrap";
import classnames from "classnames";
import TryAgain from "../../../../../../components/app-specific/try-again";
import {ReactComponent as LinkIcon} from '../../../../../../../assets/images/link-icon.svg';
import OutlinedButton from "../../../../../../components/base/button/outlined-button";
import Api from "../../../../../../../core/services/api_service";
import {ApplicationTokenContext} from "../../../../../../contexts";
import useIsMounted from "../../../../../../hooks/use-is-mounted";
import {routeFunctions} from "../../../../../../routes";

const TicketDefinitionHint = ({hint, fetchAgain, loading}) => {
    const {params: {application, serviceId, typeId, questionId, hintId}, history} = useRouter();
    const [submitting, setSubmitting] = useState(false);
    const applicationTokenContext = useContext(ApplicationTokenContext);
    const isMounted = useIsMounted();

    /**
     * Opens the mentions link of the hint in a new tab of the users' browser
     */
    const openLinkInANewTab = () => {
        const newTab = document.createElement('a');
        newTab.href = hint?.link;
        newTab.target = '_blank';
        newTab.rel = 'noopenner';
        document.body.appendChild(newTab);
        newTab.click();
        document.body.removeChild(newTab);
    }

    /**
     * Submits a new ticket by calling the server api to log the usefulness of the link.
     * If the link was useful => navigates to the dashboard view
     * If the link was not useful => navigates to the submit-form view
     * @param linkUseful {boolean}
     */
    const submitTicketWithHint = (linkUseful = false) => {
        if (!linkUseful) {
            return history.push(routeFunctions.private.withApplication.newTicket.submitForm(application, serviceId, typeId, questionId, hintId));
        }
        setSubmitting(true);
        const forApi = {
            typeId: parseInt(typeId),
            questionId: parseInt(questionId),
            serviceId: parseInt(serviceId),
            hintId: parseInt(hintId),
            applicationToken: applicationTokenContext.applicationToken,
            isYes: linkUseful,
        }
        Api.submitNewTicketOrPositiveFeedback(forApi).then((response) => {
            if (!isMounted()) return;
            if (response?.resultFlag) {
                history.push(routeFunctions.private.withApplication.dashboard(application));
            }
            setSubmitting(false);
        });
    }

    return (
        <>
            <Row className={classnames('ticket-definition', {'loading-div': loading})}>
                {
                    loading
                        ? <>
                            <Col xs={12} className={'mb-4'}>
                                <div className={'title'}>
                                    <div/>
                                </div>
                            </Col>
                            <Col xs={12} className={'mb-3'}>
                                <div className={'question-card loading-div'}>
                                    <div/>
                                </div>
                            </Col>
                            <Col xs={12} className={'mb-4'}>
                                <div className={'description'}>
                                    <div/>
                                </div>
                            </Col>
                        </>
                        : !hint
                            ? <TryAgain onClick={fetchAgain}
                                        text={'Failed to load the hint. Please contact our' +
                                            ' technical support for more information'}
                                        buttonText={'Try Again'}/>
                            : <>
                                <Col xs={12} className={'mb-3'}>
                                    <p className={'title'}>
                                        {hint?.title ?? ''}
                                    </p>
                                </Col>
                                <Col xs={12} className={'px-5 mb-5'}>
                                    <div className={'question-card no-hover'}>
                                        <p className={'description'}>
                                            {hint?.body ?? ''}
                                        </p>
                                    </div>
                                </Col>
                                {
                                    hint?.link &&
                                    <Col xs={12} className={'px-5 mb-5 d-flex flex-wrap align-items-center'}>
                                        <LinkIcon className={'mr-2'} onClick={openLinkInANewTab}/>
                                        <p className={'link'} onClick={openLinkInANewTab}>
                                            {hint?.link ?? ''}
                                        </p>
                                    </Col>
                                }
                                <Col xs={12} className={'px-5 mb-4 d-flex flex-wrap align-items-center'}>
                                    <p className={'mini-title'}>
                                        Was it useful For You?
                                    </p>
                                    <Col className={'d-flex justify-content-start'}>
                                        <OutlinedButton
                                            disabled={submitting}
                                            className={'ml-2 mr-4 text-xs'}
                                            onClick={() => submitTicketWithHint(true)}
                                        >
                                            {submitting ? 'loading...' : 'YES'}
                                        </OutlinedButton>
                                        <OutlinedButton
                                            disabled={submitting}
                                            className={' text-xs'}
                                            onClick={() => submitTicketWithHint(false)}
                                        >
                                            {submitting ? 'loading...' : 'NO, SUBMIT A NEW TICKET'}
                                        </OutlinedButton>
                                    </Col>
                                </Col>
                            </>
                }
            </Row>
        </>
    );
}

export default TicketDefinitionHint;
