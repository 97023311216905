import React from "react";
import classnames from "classnames";
import SidebarItem from "./item";
import routes, {routeFunctions} from "../../../routes";
import {ReactComponent as Logo} from "../../../../assets/images/sidebar/sidebar-logo.svg";
import {ReactComponent as MiniLogo} from "../../../../assets/images/sidebar/sidebar-mini-logo.svg";
import {ReactComponent as DashboardIcon} from "../../../../assets/images/sidebar/dashboard-icon.svg";
import {ReactComponent as LiveTasksIcon} from "../../../../assets/images/sidebar/live-tasks-icon.svg";
import {ReactComponent as AnnouncementsIcon} from "../../../../assets/images/sidebar/announcements-icon.svg";
import {ReactComponent as TicketsIcon} from "../../../../assets/images/sidebar/tickets-icon.svg";
import {ReactComponent as NewTicketIcon} from "../../../../assets/images/sidebar/new-ticket-icon.svg";
import {ReactComponent as VersionsIcon} from "../../../../assets/images/sidebar/versions-icon.svg";
import {SvgIcon} from "@material-ui/core";
import {CSSTransition, SwitchTransition} from "react-transition-group";
import useRouter from "../../../hooks/use-router";
import SidebarFooter from "./footer";

const Sidebar = ({collapsed}) => {
    const {match: {params}} = useRouter(routes.private.withApplication.base);

    return (
        <>
            <div className={classnames('sidebar', {collapsed})}>
                <div className={classnames('sidebar-header', {collapsed})}>
                    <SwitchTransition mode={'out-in'}>
                        <CSSTransition
                            key={!collapsed}
                            addEndListener={(node, done) => {
                                node.addEventListener("transitionend", done, false);
                            }}
                            classNames="fade"
                        >
                            {collapsed
                                ? <MiniLogo className={'sidebar-logo'}/>
                                : <Logo className={'sidebar-logo'}/>
                            }

                        </CSSTransition>
                    </SwitchTransition>
                    <div className={'sidebar-spacer'}/>
                </div>
                <SidebarItem
                    goTo={() => routeFunctions.private.withApplication.dashboard(params?.application)}
                    routeName={routes.private.withApplication.dashboard}
                    icon={<SvgIcon component={DashboardIcon} className={'icon lg'}/>}
                >
                    Dashboard
                </SidebarItem>
                <SidebarItem
                    goTo={() => routeFunctions.private.withApplication.liveTasks(params?.application)}
                    routeName={routes.private.withApplication.liveTasks}
                    icon={<SvgIcon component={LiveTasksIcon} className={'icon lg'}/>}
                >
                    Live Tasks
                </SidebarItem>
                <SidebarItem
                    goTo={() => routeFunctions.private.withApplication.announcements.base(params?.application)}
                    routeName={routes.private.withApplication.announcements.base}
                    icon={<SvgIcon component={AnnouncementsIcon} className={'icon lg'}/>}
                >
                    Announcements
                </SidebarItem>
                <SidebarItem
                    goTo={() => routeFunctions.private.withApplication.tickets.base(params?.application)}
                    routeName={routes.private.withApplication.tickets.base}
                    icon={<SvgIcon component={TicketsIcon} className={'icon lg'}/>}
                >
                    Tickets
                </SidebarItem>
                <SidebarItem
                    goTo={() => routeFunctions.private.withApplication.newTicket.ticketDefinition(params?.application)}
                    routeName={[routes.private.withApplication.newTicket.ticketDefinition, routes.private.withApplication.newTicket.submitForm]}
                    icon={<SvgIcon component={NewTicketIcon} className={'icon lg'}/>}
                >
                    New Ticket
                </SidebarItem>
                <SidebarItem
                    goTo={() => routeFunctions.private.withApplication.versions(params?.application)}
                    routeName={routes.private.withApplication.versions}
                    icon={<SvgIcon component={VersionsIcon} className={'icon lg'}/>}
                >
                    Versions
                </SidebarItem>
                <SidebarFooter/>
            </div>
        </>
    );
}

export default Sidebar;
