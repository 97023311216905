import React, {useEffect} from "react";
import useRouter from "../hooks/use-router";
import CacheService from "../../core/services/cache_service";
import routes, {routeFunctions, routesLists,} from "../routes";
import {matchPath, Redirect, Route, Switch} from "react-router";
import NotFound from "./error/not_found";
import AccessDenied from "./error/access_denied";
import ServerError from "./error/server_error";
import Api from "../../core/services/api_service";
import AuthViews from "./auth";
import PrivateViews from "./private";
import PublicViews from "./public";

const Landing = () => {
    const {pathname, history, location} = useRouter();

    /**
     * Sets the history object for api interface.
     * Sets the location object for api interface
     * Listens for the changes in the location object and sets the updated location for api interface
     */
    useEffect(() => {
        Api.setHistory(history);
        Api.setLocation(location);
        return history.listen((location) => {
            Api.setLocation(location);
        });
    }, [location])

    /**
     * Depending on the current route:
     * if route is public or auth => do nothing
     * if route is private or auth.logout => check if the user is logged in and if not in logout or error pages,
     * cache thr url and navigate to login page.
     */
    useEffect(() => {
        const isInPublicRoute = !!matchPath(pathname, {
            path: [...routesLists.public, ...routesLists.auth].filter(e => e !== routes.auth.logout),
            exact: true
        });
        if (isInPublicRoute) return;
        // if not logged in, save current url and go to login page
        if (!CacheService.isLoggedIn()) {
            CacheService.cacheUrl(location);
            history.replace(routeFunctions.auth.login());
        }
    }, [pathname, history])

    return (
        <>
            <Switch>
                <Route path={routesLists.auth} exact>
                    <AuthViews/>
                </Route>
                <Route path={routesLists.public} exact>
                    <PublicViews/>
                </Route>
                <Route path={routesLists.private} exact>
                    <PrivateViews/>
                </Route>
                <Route path={[routes.error.notFound, routes.error.base]} exact>
                    <NotFound/>
                </Route>
                <Route
                    path={[routes.error.accessDenied, routes.error.accessDeniedForSection, ...routesLists.allWithAccessDeniedSection]}
                    exact>
                    <AccessDenied/>
                </Route>
                <Route
                    path={[routes.error.serverError, routes.error.serverErrorForSection, ...routesLists.allWithServerErrorSection]}
                    exact>
                    <ServerError/>
                </Route>
                <Redirect to={routes.error.notFound}/>
            </Switch>
        </>
    );
}

export default Landing;
