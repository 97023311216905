import React, {useEffect} from "react";
import * as $ from 'jquery';
import useIsMounted from "../../../hooks/use-is-mounted";
import Colors from "../../../../assets/js/colors";

// The number of dots used for the loading of the page.
const numberOfDots = 21;
// The maximum number of dots used for the loading of the page.
const maxNumberOfDots = 99;
// The increase spike in the dots with each animation iteration
const dotsIncreaseSteps = 3;
// The Delay used for the animation of the dots (ms).
const animationDelay = 1001;
// The offset used for the delay animation of the dots (ms).
const animationDelayOffset = 1000;

const PageLoader = () => {
    const isMounted = useIsMounted();

    /**
     * As soon as the component mounts, hides the loader, then creates its dots, shows the loader and starts the
     * loading animation.
     */
    useEffect(() => {
        stopLoader();
        createDotsAndArms();
        startLoader();
        return () => stopLoader();
    }, [])

    /**
     * Shows the loader and then starts the animation of dots.
     */
    const startLoader = () => {
        $("#loader").show();
        loop(true);
    }

    /**
     * Hides the loader.
     */
    const stopLoader = () => {
        $("#loader").hide();
    }

    /**
     * Creates the dots and arms needed for the loader depending on the value of [numberOfDots].
     */
    const createDotsAndArms = (extraDots = numberOfDots) => {
        const spinner = document.getElementById('container');
        for (let index = 0; index < extraDots; ++index) {
            const arm = document.createElement('div')
            const dot = document.createElement('div')
            const armLine = document.createElement('div')
            arm.className = 'arm';
            dot.className = 'dot';
            dot.style.backgroundColor = index % 2 === 0 ? Colors.primaryColorDark : Colors.secondaryColorDark;
            dot.style.opacity = `${Math.random()}`;
            armLine.className = 'arm-line';
            arm.appendChild(dot);
            arm.appendChild(armLine);
            spinner.appendChild(arm);
        }
    }

    /**
     * If the component is still mounted, animates the dots and arms of the loader and sets the new timer to calls
     * itself.
     * @param shouldSkip {boolean} whether the time must be skipped or not
     */
    const loop = (shouldSkip = false) => {
        if (!isMounted()) return;
        if (shouldSkip) {
            performAnimation();
        }
        // Reset random number for setTimout
        const randomTimeout = Math.floor(Math.random() * animationDelay) + animationDelayOffset;
        increaseDots();
        setTimeout(() => {
            performAnimation();
            loop();
        }, randomTimeout);
    }

    /**
     * Increases the number of dots in the loader.
     */
    const increaseDots = () => {
        const currentCounts = document.getElementsByClassName('arm')?.length ?? 0;
        const newCount = Math.min(currentCounts + dotsIncreaseSteps, maxNumberOfDots - numberOfDots);
        if (newCount < (maxNumberOfDots - numberOfDots)) {
            createDotsAndArms(dotsIncreaseSteps);
        }
    }

    /**
     * Animates the loader elements (dots and arms).
     */
    const performAnimation = () => {
        const arms = document.getElementsByClassName('arm');
        for (let index = 0; index < arms.length; ++index) {
            const armRotation = Math.floor(Math.random() * 541) + 20;
            const armTransition = Math.floor(Math.random() * 6) + 3;
            arms[index].style.transform = 'rotate(' + armRotation + 'deg)';
            arms[index].style.transition = armTransition + 's ease-out';
        }
    }

    return (
        <div id="loader">
            <div className={'loading-container'}>
                <p id="loading">LOADING...</p>
            </div>
            <div id="container">
            </div>
        </div>
    );
}

export default PageLoader;
