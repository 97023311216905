import classnames from "classnames";
import React, {useEffect, useState} from "react";
import LocalStorageService from "../../../../core/services/local_storage_service";
import useRouter from "../../../hooks/use-router";
import routes, {routeFunctions} from "../../../routes";
import useIsMounted from "../../../hooks/use-is-mounted";
import Api from "../../../../core/services/api_service";
import CacheService from "../../../../core/services/cache_service";
import {generatePath, matchPath} from "react-router";
import {OutlinedSelect} from "../../../components/base/select/select";
import {SvgIcon} from "@material-ui/core";
import {ReactComponent as LogoutIcon} from '../../../../assets/images/topbar/logout-icon.svg';
import TopbarSearchBox from "./search-box";
import {Col} from "reactstrap";
import TopbarApps from "./apps";
import Notifications from "./notifications";

const Topbar = ({collapsed, selectedApplication, setSelectedApplication}) => {
    const [firstName, lastName] = LocalStorageService.get([LocalStorageService.keys.userFirstName, LocalStorageService.keys.userLastName])
    const {location, history, query, stringifyUrl, params} = useRouter();
    const [applications, setApplications] = useState([]);
    const [applicationsDisabled, setApplicationsDisabled] = useState(false);
    const isMounted = useIsMounted();

    /**
     * Fetches the list of available applications of the user as soon as the component mounts.
     */
    useEffect(() => {
        getAvailableApplications();
    }, [])

    /**
     * Listens for the changes in the location of the url and with each change:
     * if the current route is in newTickets page, disables the applications selection process otherwise enables it.
     */
    useEffect(() => {
        const match = matchPath(location.pathname, {
            path: [routes.private.withApplication.newTicket.ticketDefinition, routes.private.withApplication.newTicket.submitForm],
            exact: true
        });
        const shouldBeDisabled = !!match && !!match.params.serviceId
        if (applicationsDisabled !== shouldBeDisabled) {
            setApplicationsDisabled(shouldBeDisabled);
        }
    }, [location?.pathname])


    /**
     * Listens for the changes in the selected application and with each change:
     * Replaces the applicationName of the current url to trigger any changes of the page the user is in.
     */
    useEffect(() => {
        if (!selectedApplication) {
            return;
        }
        // sets the selected application name and emits a storage Event.
        LocalStorageService.set(LocalStorageService.keys.selectedApplicationName, selectedApplication.name);
        window.dispatchEvent(new Event('storage'));

        const locationSegments = location.pathname.split('/');
        locationSegments[1] = selectedApplication.name;
        // only 2 parts => (/:application) without any suffix, adds the suffix of dashboard to it
        if (locationSegments.length === 2) {
            locationSegments.push(routes.private.withApplication.dashboard.split('/').pop())
        }
        history.push(stringifyUrl({
            url: generatePath(locationSegments.join('/')),
            query: query,
        }))
    }, [selectedApplication])


    /**
     * Fetches the list of available application of the current user by calling the server and if the result of the
     * api call is successful, sets the state. Also sets the current selected application depending on its existence.
     */
    const getAvailableApplications = () => {
        Api.getAvailableApplicationsForUser().then((response) => {
            if (!isMounted()) return;
            if (response?.resultFlag) {
                setApplications(response.data ?? []);
                CacheService.syncUserApplications(response.data);
                if (!response.data?.length) {
                    return history.replace(routes.error.accessDenied);
                }
                const selectedApplicationName = LocalStorageService.get(LocalStorageService.keys.selectedApplicationName);
                // if application is saved in local storage, set it as the selected one
                if (selectedApplicationName) {
                    setSelectedApplication(response.data.find(e => e.name === selectedApplicationName));
                }
                // set the first application as the selected application of the user.
                else {
                    setSelectedApplication(response.data[0]);
                }
            }
        })
    }

    /**
     * Navigates the user to the task page with the given keywords as its code.
     * @param keywords {string}
     * @return {string} The error message of the api call.
     */
    const search = async (keywords) => {
        if (!keywords?.length) return;
        history.replace(routeFunctions.private.withApplication.task(selectedApplication.name, keywords))
    }

    /**
     * Removes users local storage data and then returns them to the login page.
     */
    const logout = () => {
        CacheService.removeUserInformation();
        history.replace(routeFunctions.auth.login());
    }

    return (
        <div className={'top-bar'}>
            <div
                className={classnames('top-bar-inner order-1 d-flex justify-content-center justify-content-md-start', {collapsed})}>
                <OutlinedSelect
                    projects
                    disabled={applicationsDisabled}
                    data={applications?.map(e => ({
                        id: e.applicationToken,
                        value: e,
                    }))}
                    placeholder={'Select Your Project'}
                    value={selectedApplication}
                    onChange={(e) => setSelectedApplication(e.target.value)}
                />
                <Col className={'d-flex align-items-center justify-content-center justify-content-md-end flex-grow-1' +
                    ' order-3 order-md-2'}>
                    <TopbarSearchBox search={search} initialValue={params?.code ?? ''} disabled={!selectedApplication}/>
                </Col>
                <div className={'d-flex align-items-center order-2 order-md-3'}>
                    <div className={'top-bar-icon-spacer-mini'}/>
                    <Notifications/>
                    <div className={'top-bar-icon-spacer'}/>
                    <TopbarApps/>
                    <div className={'top-bar-icon-spacer'}/>
                    <div className={'top-bar-profile'}>
                        {/*TODO: replace with profile image*/}
                        {/*<img src={Image} alt="profile"/>*/}
                        <p>{firstName?.length ? firstName[0] : ''}{lastName?.length ? lastName[0] : ''}</p>
                    </div>
                    <div className={'top-bar-icon-spacer'}/>
                    <SvgIcon onClick={logout} component={LogoutIcon} className={'topbar-icon'}/>
                </div>
            </div>
        </div>
    );
}

export default Topbar;
