import React, {useEffect, useState} from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import store from "./redux/store";
import Landing from "./ui/views";
import routes from "./ui/routes";
import {MuiThemeProvider} from "@material-ui/core";
import theme from "./assets/js/mui-theme";
import {Flip, toast, ToastContainer} from "react-toastify";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import {JsonContentContext} from "./ui/contexts";
import MomentUtils from "@date-io/moment";
import ErrorsStatic from "./static-jsons/errors.json";
import BizKeyStatic from "./static-jsons/bizkey.json";
import Api from "./core/services/api_service";

const initLocalJsonContent = {errors: ErrorsStatic, bizkey: BizKeyStatic};

const App = () => {
    const [localJsonContent, setLocalJsonContent] = useState(initLocalJsonContent);

    /**
     * As soon as the component mounts,
     */
    useEffect(() => {
        getStaticContent();
    }, [])

    /**
     * Fetches the static json content from the project's public folder.
     *
     * The result of the api or the static placeholder is then stored in an internal state.
     */
    const getStaticContent = () => {
        Api.fetchLocalJsonFiles(['content']).then((data) => {
            setLocalJsonContent(data['content.json'] ?? initLocalJsonContent);
        })
    }

    return (
        <JsonContentContext.Provider value={localJsonContent}>
            <MuiThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Provider store={store}>
                        <Router>
                            <Route path={routes.auth.landing}>
                                <Landing/>
                            </Route>
                        </Router>
                        <ToastContainer
                            position={toast.POSITION.TOP_RIGHT}
                            autoClose={5000}
                            hideProgressBar={true}
                            newestOnTop={false}
                            closeOnClick
                            pauseOnFocusLoss={false}
                            draggable
                            pauseOnHover
                            transition={Flip}
                        />
                    </Provider>
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        </JsonContentContext.Provider>
    );
}

export default App;
