import useRouter from "../../../hooks/use-router";
import React, {useEffect} from "react";
import {routeFunctions} from "../../../routes";
import {Col, Row} from "reactstrap";
import Logo from "../../../../assets/logos/bizkeyTech.png";
import CacheService from "../../../../core/services/cache_service";

const Logout = () => {
    const {history, params} = useRouter();

    /**
     * As soon as the component mounts, checks if the params email and token are valid or not.
     */
    useEffect(() => {
        CacheService.removeUserInformation();
        history.replace(routeFunctions.auth.login(params.redirectUrl));
    }, [])

    return (
        <>
            <Row className={'justify-content-center transition'}>
                <Col xs={12} className={'d-flex justify-content-center'}>
                    <img className={'login-logo'} src={Logo} alt="bizkey tech"/>
                </Col>
                <Col xs={12} className={'d-flex justify-content-center mb-3'}>
                    <h3 className={'text-xl font-weight-semi-bold primary-color-dark' +
                    ' text-center'}>
                        Good Bye!
                    </h3>
                </Col>
            </Row>
        </>
    );
}

export default Logout;
