import React, {useEffect, useState} from "react";
import {DashboardChartColors} from "../../../../core/constants/enums";
import Colors from "../../../../assets/js/colors";
import {areaOfCircle, numComparator} from "../../../../core/services/utils";

// The distance between chart lines. DO NOT MODIFY
const chartLinesDistance = 7.5;
// The chart's line width. DO NOT MODIFY
const chartLineWidth = 4.6;
// The diameter of the chart. DO NOT MODIFY
const chartDiameter = 200;
// The minimum percentage of the chart lines.
const minPercentage = ((chartLineWidth + chartLinesDistance) / (areaOfCircle(chartDiameter / 2) / 100) * 100);

const Chart = ({chartData, ratio}) => {
    const [background, setBackground] = useState(Colors.ternaryColorLighter);

    /**
     * Listens for the changes in the chartData and with each change:
     * calculates the color rations based on data's total
     */
    useEffect(() => {
        if (!chartData) return;
        calculateTheColorRatios(parseChartData(chartData));
    }, [chartData])

    /**
     * Given the new chart data, it parses them by inserting the needed minimum width if the percentage of the a
     * specific line was less than the min-percentage and adjusts the other lines accordingly
     * @param chartData {any[]}
     * @return {any[]}
     */
    const parseChartData = (chartData) => {
        const totalNumbers = [...chartData?.map(e => e.total), 0, 0]?.reduce((p, c) => p + c);
        const sorted = [...chartData]?.sort((a, b) => numComparator(a.total, b.total));
        let differenceInPercentage = 0;
        let numberOfDifferentPercentages = 0;
        sorted?.forEach(e => {
            const percentage = e.total / (!totalNumbers ? 1 : totalNumbers) * 100;
            if (e.total > 0 && percentage < minPercentage) {
                differenceInPercentage += minPercentage - percentage;
                numberOfDifferentPercentages++;
            }
        })
        return sorted?.map((e) => {
            if (e.total === 0) {
                return {
                    ...e,
                    percentage: 0,
                }
            }
            const percentage = e.total / (!totalNumbers ? 1 : totalNumbers) * 100;
            if (percentage < minPercentage || (percentage - (differenceInPercentage / numberOfDifferentPercentages)) < minPercentage) {
                return {
                    ...e,
                    percentage: minPercentage,
                }
            }
            return {
                ...e,
                percentage: percentage - (differenceInPercentage / (sorted.length - numberOfDifferentPercentages)),
            }
        }) ?? [];
    }

    /**
     * Based on the number of chartData's total for each of its data, calculates their ratio and sets their color.
     * @param chartData {any[]}
     */
    const calculateTheColorRatios = (chartData) => {
        const dataBackgrounds = chartData?.map((data, index) => {
            const color = data?.color ?? DashboardChartColors[index];
            const prevPercentages = [...chartData.slice(0, index)?.map(e => e.percentage), 0, 0].reduce((p, c) => p + c);
            return `${color} 0 ${prevPercentages + data.percentage}%`;
        })
        setBackground(`conic-gradient(${dataBackgrounds?.join(', ')})`)
    }

    return (
        <div className={'chart-container'}>
            <div className={'chart'}>
                <div className={'background'} style={{background: background,}}/>
                <svg className={'mask-outer'}>
                    <circle r={96} cx={100} cy={100}/>
                </svg>
                <svg className={'mask-inner'}>
                    <circle r={64} cx={100} cy={100}/>
                </svg>
                <div className={'center'}>
                    <p className={'title'}>
                        {((ratio?.ratio ?? 0) * 100).toFixed(1)}%
                    </p>
                    <p className={'sub-title'}>
                        {ratio?.title ?? ''}
                    </p>
                </div>
            </div>
            <div className={'chart-legend d-flex flex-column justify-content-center mt-4 h-auto h-sm-100'}>
                {
                    chartData?.map((data, index) => (
                        <p key={index} style={{color: data?.color ?? DashboardChartColors[index]}}>
                            <span
                                className={'text-sm font-weight-semi-bold mr-1'}>{data?.total ?? 0}</span>{data?.status?.title ?? ''}
                        </p>
                    ))
                }
            </div>
        </div>

    );
}

export default Chart
