import React, {useContext, useEffect, useState} from "react";
import Api from "../../../../../../core/services/api_service";
import useRouter from "../../../../../hooks/use-router";
import {ApplicationTokenContext} from "../../../../../contexts";
import useIsMounted from "../../../../../hooks/use-is-mounted";
import {Col, Row} from "reactstrap";
import classnames from "classnames";
import SliderForm from "../../../../../components/base/slider/slider";
import Form from "../../../../../components/base/form";
import * as Yup from 'yup';
import {makeRequired, makeValidate} from "mui-rff";
import {SubmitTicketFormDataTypes, SupportedFileTypes} from "../../../../../../core/constants/enums";
import ValidateMessages from "../../../../../../core/constants/texts/vallidate_messages";
import {routeFunctions} from "../../../../../routes";
import {OutlinedInputForm} from "../../../../../components/base/input/outlined_input";
import {TimePickerForm} from "../../../../../components/base/date-picker/time_picker";
import {DatePickerForm} from "../../../../../components/base/date-picker/date_picker";
import {OutlinedSelectForm} from "../../../../../components/base/select/select";
import {ReactComponent as AddIcon} from '../../../../../../assets/images/add-icon.svg';
import OutlinedButton from "../../../../../components/base/button/outlined-button";
import UploadButton from "../../../../../components/base/button/upload-button";
import moment from "moment/moment";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import SubmittedFile from "../../../../../components/app-specific/submitted-file";
import RouteLeavingGuard from "../../../../../components/base/route-leaving-guard";
import {createUUId, getSupportedFileType, numComparator} from "../../../../../../core/services/utils";


/**
 * Creates the new schema based on the provided template list of the formData
 */
const createSchema = (formData) => {
    const _schema = {
        description: Yup.string().nullable(),
        priority: Yup.mixed().nullable(),
    };
    formData?.form?.templates?.forEach((template) => {
        switch (template.dataTypes?.id) {
            case SubmitTicketFormDataTypes.longText:
            case SubmitTicketFormDataTypes.shortText:
            case SubmitTicketFormDataTypes.time:
            default:
                _schema[`${identifier}${template.id}`] = Yup.string().nullable().required(ValidateMessages.required);
                break;
            case SubmitTicketFormDataTypes.number:
                _schema[`${identifier}${template.id}`] = Yup.number().nullable().required(ValidateMessages.required).typeError(ValidateMessages.incorrectType('Number'));
                break;
            case SubmitTicketFormDataTypes.date:
                _schema[`${identifier}${template.id}`] = Yup.date().nullable().required(ValidateMessages.required).typeError(ValidateMessages.incorrectType('Date'));
                break;
            case SubmitTicketFormDataTypes.options:
                _schema[`${identifier}${template.id}`] = Yup.mixed().nullable().required(ValidateMessages.required);
                break;
        }
    });
    return Yup.object().shape(_schema);
}

// The identifier of names for form fields
const identifier = 'template-';
const initPreviewFiles = {open: false, files: []};

const TicketDefinitionSubmitForm = () => {
    const {
        params: {hintId, serviceId, typeId, questionId, application},
        history,
        query,
        stringifyUrl,
        location
    } = useRouter();
    const applicationTokenContext = useContext(ApplicationTokenContext);
    const [loading, setLoading] = useState(true);
    const [uploadingAttachments, setUploadingAttachments] = useState(0);
    const [submitting, setSubmitting] = useState(false);
    const [formData, setFormData] = useState({});
    const [submitFiles, setSubmitFiles] = useState([]);
    const [submittedCount, setSubmittedCount] = useState(0);
    const [blockNavigation, setBlockNavigation] = useState(false);
    const [schema, setSchema] = useState(() => createSchema(formData));
    const [previewFiles, setPreviewFiles] = useState(initPreviewFiles);
    const isMounted = useIsMounted();

    const validate = makeValidate(schema);
    const required = makeRequired(schema);
    const colClassName = 'mt-4 px-4';

    /**
     * Listens for the changes in the url parameters of a new ticket submission and with each change:
     * - fetches the form data related to the newly created ticket.
     * - if the page is not reloaded and is navigated to, then navigates the user back to the beginning of ticket
     * definition.
     */
    useEffect(() => {
        debugger
        if (query?.ticketId
            // TODO: uncomment if the reloading of the browser is fine
            // && ['PUSH', 'POP'].includes(history.action) && !loading
        ) {
            return history.replace(routeFunctions.private.withApplication.newTicket.ticketDefinition(application));
        }
        getFormInformation();
    }, [location?.pathname])

    /**
     * Listens for the changes in formData and with each change, creates the initialValues and schema of the form
     */
    useEffect(() => {
        setSchema(createSchema(formData));
    }, [formData])

    /**
     * Submits a new ticket with unuseful-link by calling the server api to
     */
    const getFormInformation = () => {
        let api;
        if (!hintId) {
            api = submitTicketWithoutHint();
        } else {
            api = submitTicketWithHint();
        }
        api.then((response) => {
            if (!isMounted()) return;
            setLoading(false);
            if (response?.resultFlag) {
                setFormData({
                    ...response.data,
                    form: {
                        ...response.data?.form,
                        ticketPriorities: response.data?.form?.ticketPriorities?.reverse() ?? []
                    }
                });
                history.replace(stringifyUrl({
                    url: routeFunctions.private.withApplication.newTicket.submitForm(application, serviceId, typeId, questionId, hintId),
                    query: {
                        ticketId: response.data.ticketId,
                    }
                }));
                setBlockNavigation(true);
            }
        })
    }

    /**
     * Submits a ticket with a negative feedback for the provided hint and if the result of the api call is
     * successful, sets the formData of the state
     */
    const submitTicketWithHint = () => {
        setLoading(true);
        const forApi = {
            typeId: parseInt(typeId),
            questionId: parseInt(questionId),
            serviceId: parseInt(serviceId),
            hintId: parseInt(hintId),
            applicationToken: applicationTokenContext.applicationToken,
            isYes: false,
        }
        return Api.submitNewTicketOrPositiveFeedback(forApi)
    }

    /**
     * Submits a ticket without a hint for the provided hint and if the result of the api call is
     * successful, sets the formData of the state
     */
    const submitTicketWithoutHint = () => {
        setLoading(true);
        const forApi = {
            applicationToken: applicationTokenContext.applicationToken,
            serviceId: parseInt(serviceId),
            typeId: parseInt(typeId),
            questionId: parseInt(questionId),
        }
        return Api.submitNewTicketWithoutHint(forApi)
    }


    /**
     * Submits the form data by calling the server api and if the result of the api is successful, navigates the
     * user to the ticket information view.
     * @param values {any}
     * @return {Promise<void>}
     */
    const submitTicketForm = (values) => {
        const forApi = {
            priorityId: values?.priority?.id ?? Math.ceil((formData?.form?.ticketPriorities?.length ?? 6) / 2),
            ticketId: formData?.ticketId,
            description: {
                content: values?.description ?? '',
                files: submitFiles
                        ?.sort((a, b) => numComparator(a.orderIndex, b.orderIndex))
                        ?.map((e, index) => ({...e, orderIndex: index + 1}))
                    ?? [] ?? [],
            },
            properties: Object
                .entries({...values, description: undefined, priority: undefined})
                ?.filter(([key, val]) => key.match(RegExp(`^${identifier}\\S`)) && val)
                ?.map(([key, val]) => ({
                    templateId: parseInt(key.replace(identifier, '')),
                    value: moment.isMoment(val) ? val.toDate().toDateString() : val,
                })),
        };
        setSubmitting(true);
        setBlockNavigation(false);
        Api.submitANewTicketForm(forApi).then((response) => {
            if (!isMounted()) return;
            if (response?.resultFlag) {
                history.replace(routeFunctions.private.withApplication.tickets.single(application, formData.ticketId));
            } else {
                setSubmitting(false);
                setBlockNavigation(true);
            }
        })
    }

    /**
     * Removes the submitted file from the list of submitFiles
     * @param submitFile {any}
     */
    const removeSubmitFile = (submitFile) => {
        setSubmitFiles(prevState => prevState?.filter(e => e.filename !== submitFile.filename));
    }

    /**
     * For each of the files, upload them to the server api.
     * @param files {FileList}
     */
    const uploadFiles = async (files) => {
        if (!files?.length) return;
        const newSubmittedFiles = [];
        setUploadingAttachments(files.length);
        for (let index = 1; index <= files.length; ++index) {
            const file = files.item(index - 1);
            const response = await uploadFile(file);
            if (response) {
                newSubmittedFiles.push({
                    filename: response.fileName,
                    fileTitle: response.fileTitle,
                    baseURL: response.baseURL,
                    extension: response.type,
                    orderIndex: submittedCount + index,
                    key: createUUId(true),
                    type: getSupportedFileType(response.type),
                });
            }
        }
        setSubmittedCount(prevState => prevState + files.length)
        setSubmitFiles(prevState => [...prevState, ...newSubmittedFiles])
        setUploadingAttachments(0);
    }


    /**
     * Uploads an image to the server then depending on the result of the api, returns the uploaded file
     * @param data {File}
     */
    const uploadFile = async (data) => {
        const formData = new FormData();
        formData.append("file", data, data.name);
        const response = await Api.uploadAFile(formData);
        if (!isMounted()) return;
        if (response?.resultFlag) {
            return response.data;
        }
    }

    /**
     * Opens the preview dialog for all the files that
     * @param index
     */
    const openFilePreviewDialog = (index) => {
        setPreviewFiles({
            open: true,
            files: submitFiles
                ?.map((e, i) => index === i ?
                    {...e, first: true}
                    : e)
                ?.filter(e => e?.type !== SupportedFileTypes.unknown)
        })
    }


    /**
     * Creates the form fields of the form based on the template of the formData and their dataType.
     * @param form {any}
     * @param values {any}
     * @param submittingForm {boolean}
     * @return {JSX.Element[]}
     */
    const renderFormFields = (form, values, submittingForm) => {
        return formData?.form?.templates?.map((template) => {
            switch (template.dataTypes?.id) {
                case SubmitTicketFormDataTypes.shortText:
                default:
                    return (
                        <Col xs={12} key={template.id} className={colClassName}>
                            <OutlinedInputForm
                                fullWidth={false}
                                required={!!required[`${identifier}${template.id}`]}
                                name={`${identifier}${template.id}`}
                                placeholder={'please type here'}
                                label={template.title ?? ''}
                                disabled={!!submitting || !!submittingForm}
                            />
                        </Col>
                    );
                case SubmitTicketFormDataTypes.longText:
                    return (
                        <Col xs={12} key={template.id} className={colClassName}>
                            <OutlinedInputForm
                                fullWidth={false}
                                multiline
                                className={'w-100 w-md-75 w-lg-50'}
                                rows={7}
                                required={!!required[`${identifier}${template.id}`]}
                                name={`${identifier}${template.id}`}
                                placeholder={'please type here'}
                                label={template.title ?? ''}
                                disabled={!!submitting || !!submittingForm}
                            />
                        </Col>
                    );
                case SubmitTicketFormDataTypes.time:
                    return (
                        <Col xs={12} key={template.id} className={colClassName}>
                            <TimePickerForm
                                fullWidth={false}
                                searchForm={false}
                                required={!!required[`${identifier}${template.id}`]}
                                name={`${identifier}${template.id}`}
                                invalidDateMessage={ValidateMessages.incorrectType('Time')}
                                disabled={!!submitting || !!submittingForm}
                                placeholder={'select time'}
                                label={template.title ?? ''}
                            />
                        </Col>
                    );
                case SubmitTicketFormDataTypes.number:
                    return (
                        <Col xs={12} key={template.id} className={colClassName}>
                            <OutlinedInputForm
                                fullWidth={false}
                                required={!!required[`${identifier}${template.id}`]}
                                name={`${identifier}${template.id}`}
                                placeholder={'please type here'}
                                disabled={!!submitting || !!submittingForm}
                                type={'number'}
                                label={template.title ?? ''}
                            />
                        </Col>
                    );
                case SubmitTicketFormDataTypes.date:
                    return (
                        <Col xs={12} key={template.id} className={colClassName}>
                            <DatePickerForm
                                disableFuture={false}
                                searchForm={false}
                                fullWidth={false}
                                required={!!required[`${identifier}${template.id}`]}
                                name={`${identifier}${template.id}`}
                                placeHolder={'select date'}
                                disabled={!!submitting || !!submittingForm}
                                label={template.title ?? ''}
                                minDate={Date.now()}
                            />
                        </Col>
                    );
                case SubmitTicketFormDataTypes.options:
                    return (
                        <Col xs={12} key={template.id} className={colClassName}>
                            <OutlinedSelectForm
                                fullWidth={false}
                                required={!!required[`${identifier}${template.id}`]}
                                name={`${identifier}${template.id}`}
                                disabled={!!submitting || !!submittingForm}
                                header={'Select your value'}
                                label={template?.title ?? ''}
                                data={template.defaultValues?.map((value) => ({
                                    id: value.id,
                                    value: value.value,
                                })) ?? []}
                            />
                        </Col>
                    );
            }
        });
    }


    return (
        <>
            <Row className={classnames('ticket', {'loading-div': loading})}>
                <Col xs={12} className={'mb-4'}>
                    <p className={'title'}>
                        Submit a New Ticket
                    </p>
                </Col>
                <Col xs={12} className={'pl-3'}>
                    {
                        loading
                            ? <>
                                <Col xs={12}>
                                    <div className={'title'}>
                                        <div/>
                                    </div>
                                </Col>
                                <Col xs={12} className={'mt-3'}>
                                    <div className={'slider'}>
                                        <div/>
                                    </div>
                                </Col>
                                {
                                    Array(4).fill(null).map((e, index) => (
                                        <div key={index} className={''}>
                                            <Col xs={12} className={'mt-4'}>
                                                <div className={'title'}>
                                                    <div/>
                                                </div>
                                            </Col>
                                            <Col xs={12} className={'mt-3'}>
                                                <div className={'input'}>
                                                    <div/>
                                                </div>
                                            </Col>

                                        </div>
                                    ))
                                }
                                <Col xs={12} className={'mt-4 mb-5'}>
                                    <div className={'form-button'}>
                                        <div/>
                                    </div>
                                </Col>
                            </>
                            : <>
                                <Form
                                    initialValues={{}}
                                    validate={validate}
                                    onSubmit={submitTicketForm}
                                    render={({form, values, submitting: submittingForm}) => {
                                        return (
                                            <>
                                                <Col xs={12}>
                                                    <p className={'mini-title'}>
                                                        Priority
                                                    </p>
                                                </Col>
                                                <Col xs={12} className={'px-5 my-3'}>
                                                    <SliderForm
                                                        form={form}
                                                        name={'priority'}
                                                        getValue={(priority) => priority?.value}
                                                        defaultValue={Math.ceil((formData?.form?.ticketPriorities?.length ?? 6) / 2)}
                                                        min={1}
                                                        max={formData?.form?.ticketPriorities?.length}
                                                        getSaveData={(e) => ({...e, title: e.label})}
                                                        marks={formData?.form?.ticketPriorities?.map((e, index) => ({
                                                            value: index + 1,
                                                            label: e.title,
                                                            id: e.id,
                                                        }))}
                                                    />
                                                </Col>
                                                {
                                                    renderFormFields(form, values, submitting)
                                                }
                                                <Col xs={12} className={classnames(colClassName)}>
                                                    <OutlinedInputForm
                                                        fullWidth={false}
                                                        multiline
                                                        className={'w-100 w-md-75 w-lg-50'}
                                                        rows={7}
                                                        required={!!required.description}
                                                        name={'description'}
                                                        placeholder={'please type here'}
                                                        label={'Description'}
                                                        disabled={!!submitting || !!submittingForm}
                                                    />
                                                </Col>
                                                <Col xs={12} className={classnames(colClassName)}>
                                                    <div className={'w-100 w-md-75 w-lg-50 d-flex justify-content-between'}>
                                                        <p className={'text-sm font-weight-bold primary-color-dark'}>
                                                            Attachments
                                                        </p>
                                                        <UploadButton
                                                            multiple
                                                            id={'file-submission-upload-button'}
                                                            onFileSelect={uploadFiles}
                                                            disabled={!!submitting || !!submittingForm || uploadingAttachments > 0}
                                                        >
                                                            <AddIcon className={'add-icon'}/>
                                                        </UploadButton>
                                                    </div>
                                                    <TransitionGroup
                                                        className={'d-flex flex-column w-100 w-md-75 w-lg-50 mt-3'}>
                                                        {[
                                                            ...submitFiles?.map((submitFile, index) => (
                                                                <CSSTransition
                                                                    key={submitFile.key}
                                                                    timeout={300}
                                                                    classNames="css-item">
                                                                    <SubmittedFile
                                                                        submitFile={submitFile}
                                                                        removeFile={!uploadingAttachments && !submitting && removeSubmitFile}
                                                                        onClick={() => openFilePreviewDialog(index)}
                                                                    />
                                                                </CSSTransition>
                                                            )),
                                                            ...Array(uploadingAttachments).fill(null).map((e, index) => (
                                                                <CSSTransition
                                                                    key={-(index + 1)}
                                                                    timeout={300}
                                                                    classNames="css-item">
                                                                    <div className={'file-attachment mb-2' +
                                                                        ' loading-div'}>
                                                                        <div/>
                                                                    </div>
                                                                </CSSTransition>
                                                            ))
                                                        ]}
                                                    </TransitionGroup>
                                                </Col>
                                                <Col xs={12} className={classnames(colClassName, 'mt-5')}>
                                                    <OutlinedButton
                                                        type={'submit'}
                                                        disabled={submitting || submittingForm}
                                                        className={' w-100 w-md-75 w-lg-50'}
                                                    >
                                                        {submitting || submittingForm ? 'Submitting...' : 'SUBMIT'}
                                                    </OutlinedButton>
                                                </Col>
                                                <Col xs={12} className={'my-5'}>
                                                    <div style={{height: 1}}/>
                                                </Col>
                                                <Col xs={12} className={'my-5'}>
                                                    <div style={{height: 1}}/>
                                                </Col>
                                            </>
                                        );
                                    }}
                                />
                            </>
                    }
                </Col>
            </Row>
            <RouteLeavingGuard
                when={blockNavigation}
                shouldBlockNavigation={() => blockNavigation}
            />
        </>
    );
}


export default TicketDefinitionSubmitForm;
