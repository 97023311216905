import React, {useEffect} from "react";
import * as $ from "jquery";
import {QuestionnaireColors} from "../../../../core/constants/enums";
import {SvgIcon} from "@material-ui/core";
import {ReactComponent as RadioIconChecked} from "../../../../assets/images/radio-icon-checked.svg";
import {ReactComponent as RadioIcon} from "../../../../assets/images/radio-icon.svg";
import classnames from "classnames";

const QuestionnaireDot = ({filled, color, name, id, onClick}) => {

    /**
     * As soon as the component mounts, sets appropriate styling of the dot.
     */
    useEffect(() => {
        $(`#${id}`).fitText({
            minFontSize: '11px',
            maxFontSize: '14px',
            minFontWeight: 600,
            maxFontWeight: 700,
            minOpacity: color === QuestionnaireColors.hint ? 1 : 0.7,
            maxOpacity: 1,
            minLength: 10,
            maxLength: 120,
        });
    }, [])

    return (
        <div
            className={classnames('d-flex align-items-start justify-content-start questionnaire-dot', {'clickable': !!onClick})}
            onClick={onClick}>
            <SvgIcon
                component={filled ? RadioIconChecked : RadioIcon}
                className={classnames('icon md', color, {'filled': filled})}
            />
            <div className={'mr-1'}/>
            <div className={'text-container'} id={id}>
                <p className={classnames(color, 'text')}>
                    {name}
                </p>
            </div>
        </div>
    );
}

export default QuestionnaireDot;
