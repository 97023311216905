import React from "react";
import {IconButton as MuiButton, makeStyles} from '@material-ui/core';
import Colors from "../../../../assets/js/colors";
import classnames from "classnames";

const IconButton = ({
                        color = Colors.secondaryColor,
                        hoverColor = Colors.primaryColorDark,
                        backgroundColor = Colors.transparent,
                        hoverBackgroundColor = Colors.transparent,
                        erColor = Colors.primaryColor,
                        fillType = 'fill',
                        children,
                        className,
                        ...props
                    }) => {
    const classes = makeStyles((theme) => ({
        root: {
            transition: 'all 0.2s ease-in-out',
            backgroundColor: (props) => props.backgroundColor,
            '& svg': {
                ...(fillType === 'fill'
                        ? {fill: (props) => props.color}
                        : fillType === 'stroke'
                            ? {stroke: (props) => props.color}
                            : {fill: (props) => props.color, stroke: (props) => props.color}
                ),
            },
            '&:hover': {
                backgroundColor: (props) => props.hoverBackgroundColor,
                '& svg': {
                    ...(fillType === 'fill'
                            ? {fill: (props) => props.hoverColor}
                            : fillType === 'stroke'
                                ? {stroke: (props) => props.hoverColor}
                                : {fill: (props) => props.hoverColor, stroke: (props) => props.hoverColor}
                    )
                }
            }
        }
    }))({
        backgroundColor,
        hoverBackgroundColor,
        color,
        hoverColor,
    })

    return (
        <>
            <MuiButton
                className={classnames(className)}
                classes={classes}
                {...props}>
                {children}
            </MuiButton>
        </>
    )
}

export default IconButton;
