import React from "react";
import {Col, Row} from "reactstrap";
import OutlinedButton from "../../base/button/outlined-button";


const TryAgain = ({onClick, text = 'No search results found!', buttonText = 'Search Again', comingSoon = false}) => {
    return (
        <Col className=' pt-5 pb-5 justify-content-center align-items-center'>
            <p className='text-sm text-center text-color-3 mb-2'>{text}</p>
            <Row className='w-100 no-gutters pt-2 justify-content-center align-items-center'>
                <OutlinedButton
                    onClick={onClick}
                    hoverChildren={comingSoon ? 'Coming Soon' : buttonText}
                >
                    {buttonText}
                </OutlinedButton>
            </Row>
        </Col>
    );
}

export default TryAgain;
