import React from "react";
import SubmittedFile from "../submitted-file";
import {getSupportedFileType} from "../../../../core/services/utils";
import moment from "moment";


const TicketCommentCard = ({comment, openFilePreviewDialog}) => {
    const files = comment?.files?.map(e => ({...e, type: getSupportedFileType(e.extension)})) ?? [];

    return (
        <>
            <div className={'comment'}>
                <div className={'d-flex justify-content-between mb-2'}>
                    <p className={'owner'}>
                        {comment?.commentedBy ?? ''}
                    </p>
                    <p className={'date'}>
                        {comment?.submitDateTime ? moment(comment.submitDateTime).fromNow() : ''}
                    </p>
                </div>
                <p className={'description'}>
                    {comment?.content ?? ''}
                </p>
                <div className={'d-flex flex-wrap'}>
                    {
                        files?.map((submitFile, index) => (
                            <SubmittedFile
                                key={submitFile.orderIndex}
                                className={'mr-2 mb-1'}
                                submitFile={submitFile}
                                onClick={() => openFilePreviewDialog(files, index)}
                            />
                        ))
                    }
                </div>
            </div>
        </>
    )
}

export default TicketCommentCard;
