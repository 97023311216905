import {flattenObjectAndReturnAsAList} from "../../core/services/utils";

/**
 * All the route names of the application
 */
const routes = {
    private: {
        withApplication: {
            base: '/:application',
            loading: '/loading-applications',
            dashboard: '/:application/dashboard',
            liveTasks: '/:application/liveTasks',
            task: '/:application/task/:code',
            newTicket: {
                ticketDefinition: '/:application/newTicket/:serviceId?/:typeId?/:questionId?/:hintId?',
                submitForm: '/:application/submitTicketForm/:serviceId/:typeId/:questionId/:hintId?',
            },
            announcements: {
                base: '/:application/announcements',
                single: '/:application/announcements/:id',
            },
            tickets: {
                base: '/:application/tickets',
                single: '/:application/tickets/:id',
            },
            versions: '/:application/app-versions',
        },
        withoutApplication: {},
    },
    public: {},
    auth: {
        landing: '/',
        login: '/login/:redirectUrl?',
        forgetPassword: '/forgetPassword/:redirectUrl?',
        resetPassword: '/resetPassword/:email/:token',
        logout: '/logout/:redirectUrl?',
    },
    error: {
        base: '/error',
        notFound: '/error/notFound',
        accessDenied: '/error/accessDenied',
        serverError: '/error/serverError',
        serverErrorForSection: '/error/serverErrorForSection',
        accessDeniedForSection: '/error/accessDeniedForSection',
    },
}

export default routes;

/**
 * All the routes with variable parts in form of functions
 */
export const routeFunctions = {
    auth: {
        login: (redirectUrl = null) => `/login${redirectUrl ? `/${redirectUrl}` : ''}`,
        logout: (redirectUrl = null) => `/logout${redirectUrl ? `/${redirectUrl}` : ''}`,
        forgotPassword: (redirectUrl = null) => `/forgetPassword/${redirectUrl ? `/${redirectUrl}` : ''}`,
        registration: (token, redirectUrl = null) => `/registerUserViaInvitationLink/${token}${redirectUrl ? `/${redirectUrl}` : ''}`,
        resetPassword: (email, token) => `/registerUserViaInvitationLink/${email}/${token}`,
    },
    private: {
        withApplication: {
            dashboard: (applicationName) => `/${applicationName}/dashboard`,
            liveTasks: (applicationName) => `/${applicationName}/liveTasks`,
            versions: (applicationName) => `/${applicationName}/app-versions`,
            task: (applicationName, code) => `/${applicationName}/task/${code}`,
            newTicket: {
                ticketDefinition: (applicationName, serviceId = null, typeId = null, questionId = null, hintId = null) => {
                    let res = `/${applicationName}/newTicket`;
                    if (!serviceId) return res;
                    res += `/${serviceId}`;
                    if (!typeId) return res;
                    res += `/${typeId}`;
                    if (!questionId) return res;
                    res += `/${questionId}`;
                    if (!hintId) return res;
                    res += `/${hintId}`;
                    return res;
                },
                submitForm: (applicationName, serviceId, typeId, questionId, hintId = null) => `/${applicationName}/submitTicketForm/${serviceId}/${typeId}/${questionId}${hintId ? `/${hintId}` : ''}`,
            },
            tickets: {
                base: (applicationName) => `/${applicationName}/tickets`,
                single: (applicationName, id) => `/${applicationName}/tickets/${id}`,
            },
            announcements: {
                base: (applicationName) => `/${applicationName}/announcements`,
                single: (applicationName, id) => `/${applicationName}/announcements/${id}`,
            },
        },
    }
}


/**
 * The Accumulated list of all routes for difference route entries
 * @type {{all: string[] | any[], allWithServerErrorSection: string[] | any[], private: string[] | any[], public: string[] | any[], publicWithAccessDeniedSection: string[] | any[], publicWithServerErrorSection: string[] | any[], privateWithServerErrorSection: string[] | any[], allWithAccessDeniedSection: string[] | any[], privateWithAccessDeniedSection: string[] | any[]}}
 */
export const routesLists = {
    //                                  ************* ALL ROUTES **************

    all: flattenObjectAndReturnAsAList(routes),
    allWithServerErrorSection: flattenObjectAndReturnAsAList(routes, routes.error.serverErrorForSection),
    allWithAccessDeniedSection: flattenObjectAndReturnAsAList(routes, routes.error.accessDeniedForSection),

    //                                  ************* PUBLIC ROUTES **************

    public: flattenObjectAndReturnAsAList(routes.public),
    publicWithServerErrorSection: flattenObjectAndReturnAsAList(routes.public, routes.error.serverErrorForSection),
    publicWithAccessDeniedSection: flattenObjectAndReturnAsAList(routes.public, routes.error.accessDeniedForSection),

    //                                  ************* PRIVATE ROUTES **************

    private: flattenObjectAndReturnAsAList(routes.private),
    privateWithApplications: flattenObjectAndReturnAsAList(routes.private.withApplication),
    privateWithoutApplications: flattenObjectAndReturnAsAList(routes.private.withoutApplication),
    privateWithServerErrorSection: flattenObjectAndReturnAsAList(routes.private, routes.error.serverErrorForSection),
    privateWithAccessDeniedSection: flattenObjectAndReturnAsAList(routes.private, routes.error.accessDeniedForSection),

    //                                  ************* AUTH ROUTES **************

    auth: flattenObjectAndReturnAsAList(routes.auth),
    authWithServerErrorSection: flattenObjectAndReturnAsAList(routes.auth, routes.error.serverErrorForSection),
    authWithAccessDeniedSection: flattenObjectAndReturnAsAList(routes.auth, routes.error.accessDeniedForSection),
}
