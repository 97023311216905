import {createSlice} from "@reduxjs/toolkit";

//TODO: exists strictly as an example
const User = createSlice({
    name: 'user',
    initialState: {
        data: null,
    },
    reducers: {
        /**
         * Sets the user first name, last name to the user profile section of redux
         * @param state
         * @param action
         */
        set: (state, action) => {
            state.data = {...state.data, ...action.payload};
        },
    }
});

/**
 * User Actions
 */
export const {
    set,
} = User.actions;

/**
 * The User Reducer
 */
export default User.reducer;
