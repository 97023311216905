import React, {useContext, useEffect, useState} from "react";
import Dialog from "../../../../../components/base/dialog";
import Api from "../../../../../../core/services/api_service";
import {ApplicationTokenContext} from "../../../../../contexts";
import useIsMounted from "../../../../../hooks/use-is-mounted";
import {ReactComponent as CalendarIcon} from "../../../../../../assets/images/calendar.svg";
import moment from "moment";
import classnames from "classnames";


const Announcement = ({open, setOpen, selectedAnnouncement}) => {
    const [announcement, setAnnouncement] = useState();
    const [loading, setLoading] = useState(true);
    const applicationTokenContext = useContext(ApplicationTokenContext);
    const isMounted = useIsMounted();

    /**
     * Listens for the changes in the selected announcements or the open state of the modal and with each change, if
     * the modal is not open, sets the state to its initial values,
     * otherwise fetches selected announcement.
     */
    useEffect(() => {
        if (!open) {
            setAnnouncement(undefined);
            return;
        }
        getAnnouncement();
    }, [selectedAnnouncement, open])


    /**
     * Fetches the selected announcement from the server and if the result of the api is successful, sets the state.
     */
    const getAnnouncement = () => {
        setLoading(true);
        Api.getAnnouncementById(applicationTokenContext.applicationToken, selectedAnnouncement.id).then((response) => {
            if (!isMounted()) return;
            if (response?.resultFlag) {
                setAnnouncement(response.data);
            } else {
                setAnnouncement(undefined);
            }
            setLoading(false);
        });
    }

    return (
        <Dialog
            slide
            slideDirection={'left'}
            open={open}
            setOpen={setOpen}
            className={classnames('announcement-dialog', {'loading-div': loading})}
        >
            <>
                {
                    loading
                        ? <>
                            <div className={'d-flex justify-content-between align-items-start mb-3'}>
                                <div className={'loading-div title'}>
                                    <div/>
                                </div>
                                <div className={'loading-div date'}>
                                    <div/>
                                </div>
                            </div>
                            <div className={'loading-div description'}>
                                {
                                    Array(10).fill(null).map((e, index) => (
                                        <div key={index}/>
                                    ))
                                }
                            </div>
                        </>
                        : <>
                            <div className={'d-flex justify-content-between mb-3'}>
                                <p className={'title'}>
                                    {announcement?.title ?? ''}
                                </p>
                                <div className={'d-flex align-items-center justify-content-end'}>
                                    <CalendarIcon className={'icon fill-ternary-color-light mr-2'}/>
                                    <p className={'date'}>{moment(announcement?.publishDate).format('yyyy-MM-DD')}</p>
                                </div>
                            </div>
                            <div className={'description mb-5'}>
                                <p>
                                    {announcement?.longDescription ?? ''}
                                </p>
                            </div>
                        </>
                }
            </>
        </Dialog>
    );
}

export default Announcement;
