import React from "react";
import routes, {routesLists} from "../../routes";
import {Redirect, Switch} from "react-router";
import {Route} from "react-router-dom";
import ServerError from "../error/server_error";
import AccessDenied from "../error/access_denied";
import BaseAuthView from "../../containers/base-auth";
import Login from "./login";
import ForgotPassword from "./forgot-password";
import ResetPassword from "./reset-password";
import Logout from "./logout";


const AuthViews = () => {

    return (
        <BaseAuthView>
            <Switch>
                <Route path={[routes.auth.login, routes.auth.landing]} exact>
                    <Login/>
                </Route>
                <Route path={routes.auth.forgetPassword} exact>
                    <ForgotPassword/>
                </Route>
                <Route path={routes.auth.resetPassword} exact>
                    <ResetPassword/>
                </Route>
                <Route path={routes.auth.logout} exact>
                    <Logout/>
                </Route>
                <Route path={routesLists.authWithServerErrorSection} exact><ServerError/></Route>
                <Route path={routesLists.authWithAccessDeniedSection} exact><AccessDenied/></Route>
                <Redirect to={routes.error.notFound}/>
            </Switch>
        </BaseAuthView>
    );
}

export default AuthViews;
