import {SubmittedFileBackgrounds, SupportedFileTypes} from "../../../../core/constants/enums";
import {ReactComponent as ImageIcon} from "../../../../assets/images/file-extensions/image-icon.svg";
import {ReactComponent as VideoIcon} from "../../../../assets/images/file-extensions/video-icon.svg";
import {ReactComponent as PdfIcon} from "../../../../assets/images/file-extensions/pdf-icon.svg";
import {ReactComponent as CloseIcon} from "../../../../assets/images/close-icon.svg";
import React, {useState} from "react";
import classnames from "classnames";
import {getSupportedFileType} from "../../../../core/services/utils";
import useIsMounted from "../../../hooks/use-is-mounted";
import Api from "../../../../core/services/api_service";
import {CircularProgress} from "@material-ui/core";

const SubmittedFile = ({
                           submitFile: file,
                           removeFile,
                           onClick,
                           className = '',
                           background = SubmittedFileBackgrounds.light
                       }) => {
    const isMounted = useIsMounted();
    const [downloading, setDownloading] = useState(false);

    const supportedFileType = getSupportedFileType(file?.extension);
    const title = `${file?.fileTitle ?? file?.filename ?? 'unknown'}${file?.extension ?? '.unknown'}`;
    const clickable = !!onClick;

    /**
     * Chooses the extension svg of the image based on the file's extension name.
     * @return {JSX.Element}
     */
    const chooseExtensionImage = () => {
        switch (supportedFileType) {
            case SupportedFileTypes.image:
                return <ImageIcon className={'icon extension-icon xl mr-2'}/>
            case SupportedFileTypes.video:
                return <VideoIcon className={'icon extension-icon xl mr-2'}/>
            case SupportedFileTypes.pdf:
                return <PdfIcon className={'icon extension-icon xl mr-2'}/>
            default:
                return <></>
        }
    }

    /**
     * Based on the type of the file, either downloads the file or calls the onClick callback.
     *
     * - if not clickable, does nothing.
     * @param {MouseEvent} e
     */
    const onFileClicked = (e) => {
        if (!clickable) return;
        // download the file
        if (supportedFileType === SupportedFileTypes.unknown) {
            return downloadFile();
        }
        if (onClick) onClick({...file, type: supportedFileType});
    }

    /**
     * Downloads the file in the user machine.
     */
    const downloadFile = () => {
        setDownloading(true);
        Api.downloadUrlIntoUserMachine(
            `${file.baseURL}${file.filename}${file.extension}`,
            title
        ).then((_) => {
            if (!isMounted()) return;
            setDownloading(false);
        })
    }

    return (
        <div className={classnames('submit-file', className)}>
            <div
                onClick={onFileClicked}
                className={classnames('d-flex align-items-start mr-2', {
                    'clickable': clickable
                })}>
                {
                    downloading
                        ? !removeFile && <CircularProgress size={20} color={"secondary"} className={'mr-2'}/>
                        : chooseExtensionImage()
                }
                <p className={classnames('filename', background)}>
                    {title}
                </p>
            </div>
            {
                removeFile && (
                    downloading
                        ? <CircularProgress size={20} color={"secondary"}/>
                        : <CloseIcon
                            className={'close-icon icon sm'}
                            onClick={() => removeFile(file)}
                        />
                )

            }
        </div>
    );
}

export default SubmittedFile;
