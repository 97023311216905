import {combineReducers} from 'redux';
import UserReducer from './user/reducer';

/**
 * Combines all the different redux slices into a single reducer for the store.
 */
export default combineReducers(
    {
        user: UserReducer,
    }
);
