import React from "react";
import routes, {routesLists} from "../../routes";
import {Redirect, Route, Switch} from "react-router";
import ServerError from "../error/server_error";
import AccessDenied from "../error/access_denied";

const PublicViews = () => {

    return (
        <>
            <Switch>
                <Route path={routesLists.publicWithServerErrorSection} exact><ServerError/></Route>
                <Route path={routesLists.publicWithAccessDeniedSection} exact><AccessDenied/></Route>
                <Redirect to={routes.error.notFound}/>
            </Switch>
        </>
    );
}

export default PublicViews;
