import React from "react";
import QuestionnaireDot from "./questionnaire-dot";
import {CSSTransition, TransitionGroup} from "react-transition-group";

const QuestionnaireRoute = ({routes = [], clickable = true}) => {


    return (
        <TransitionGroup className={'d-flex flex-column w-100 questionnaire-routes'}>
            {
                routes.map((route, index) => {
                    const filled = route.filled ?? false;
                    switch (index) {
                        case 0:
                            return (
                                <CSSTransition
                                    key={route.id}
                                    timeout={{appear: 0, enter: 500, exit: 0}}
                                    classNames="route-item"
                                >
                                    <QuestionnaireDot
                                        filled={filled}
                                        color={route.color}
                                        onClick={clickable ? route.onClick : null}
                                        name={route.name}
                                        id={route.id}
                                    />
                                </CSSTransition>

                            );
                        case routes.length - 1:
                            return (
                                <CSSTransition
                                    key={route.id}
                                    timeout={{appear: 0, enter: 500, exit: 0}}
                                    classNames="route-item"
                                >
                                    <div className={'d-flex flex-column align-items-start justify-content-start'}>
                                        <div className={'separator my-2'}/>
                                        <QuestionnaireDot
                                            filled={filled}
                                            color={route.color}
                                            name={route.name}
                                            id={route.id}
                                        />
                                        <div className={'separator invisible my-2'}/>
                                    </div>
                                </CSSTransition>

                            );
                        default:
                            return (
                                <CSSTransition
                                    key={route.id}
                                    timeout={{appear: 0, enter: 500, exit: 0}}
                                    classNames="route-item"
                                >
                                    <div className={'d-flex flex-column align-items-start justify-content-start'}>
                                        <div className={'separator my-2'}/>
                                        <QuestionnaireDot
                                            onClick={clickable ? route.onClick : null}
                                            filled={filled}
                                            color={route.color}
                                            name={route.name}
                                            id={route.id}
                                        />
                                    </div>
                                </CSSTransition>
                            );
                    }
                })
            }
        </TransitionGroup>
    );
}


export default QuestionnaireRoute;
