import React from "react";
import useRouter from "../../../../../../hooks/use-router";
import {Col, Row} from "reactstrap";
import classnames from "classnames";
import TryAgain from "../../../../../../components/app-specific/try-again";
import {routeFunctions} from "../../../../../../routes";
import QuestionCard from "../../../../../../components/app-specific/question-card";


const TicketDefinitionQuestions = ({questions: questionsProps, fetchAgain, loading}) => {
    const {params: {application, serviceId, typeId, questionId}, history} = useRouter();
    const questions = questionsProps?.filter(e => !questionId ? e.parentId === null : e.parentId === parseInt(questionId))


    /**
     * Navigates the user to the appropriate view depending on the attributes of the selected question
     * @param question {any}
     */
    const navigateToNextSection = (question) => {
        // if the selected question has more children that need to be selected, navigates the user to questions view
        if (questionsProps?.filter(e => e.parentId === question.id)?.length) {
            return history.push(routeFunctions.private.withApplication.newTicket.ticketDefinition(application, serviceId, typeId, question.id));
        }
        // if the selected question has a hint that must be showed, navigates to hint view
        if (question.hint) {
            return history.push(routeFunctions.private.withApplication.newTicket.ticketDefinition(application, serviceId, typeId, question.id, question.hint.id));
        }

        // navigates the user to form submission as the question does not have a hint and its the leaf of its tree
        history.push(routeFunctions.private.withApplication.newTicket.submitForm(application, serviceId, typeId, question.id));
    }


    return (
        <>
            <Row className={classnames('ticket-definition', {'loading-div': loading})}>
                <Col xs={12} className={'mb-4'}>
                    <p className={'title'}>
                        Select The Most relevant Question
                    </p>
                </Col>
                <Col xs={12}>
                    {
                        loading
                            ? <>
                                {Array(4).fill(null).map((e, index) => (
                                    <div className={classnames('question-card my-2', {'loading-div': loading})}
                                         key={index}>
                                        <div/>
                                    </div>
                                ))}
                            </>
                            : !questions?.length
                                ? <TryAgain onClick={fetchAgain}
                                            text={'There is no question available for the selected route. Please contact' +
                                                ' our technical support for more information'}
                                            buttonText={'Load Questions Again'}/>
                                : questions?.map((question) => (
                                    <QuestionCard
                                        key={question.id}
                                        question={question}
                                        onClick={navigateToNextSection}
                                    />
                                ))
                    }
                </Col>

            </Row>
        </>
    );
}

export default TicketDefinitionQuestions;
