import React, {useEffect, useMemo, useState} from "react";
import Sidebar from "./sidebar";
import useWindowViewportWidth from "../../hooks/use_window-viewport-width";
import Topbar from "./topbar";
import classnames from "classnames";
import {Container} from "reactstrap";
import {matchPath} from "react-router";
import useRouter from "../../hooks/use-router";
import routes from "../../routes";
import PageLoader from "../../components/app-specific/page-loader";
import {LayoutContext} from "../../contexts";

const Panel = ({children}) => {
    const {location} = useRouter();
    const windowViewportWidth = useWindowViewportWidth();
    const [selectedApplication, setSelectedApplication] = useState();
    const match = matchPath(location.pathname, {path: routes.private.withApplication.loading, exact: true});
    const [layout, setLayout] = useState({
        collapsed: false,
        disabled: false,
        noPadding: false,
    });

    const withoutContainer = !!matchPath(location.pathname, {
        path: [
            routes.private.withApplication.liveTasks,
            routes.private.withApplication.tickets.base,
        ],
        exact: true,
    })

    const preventXScrolling = useMemo(() => !!matchPath(location.pathname, {
        path: [
            routes.private.withApplication.versions,
        ],
        exact: true,
    }), [location.pathname])

    const _children = (
        <>
            {
                !selectedApplication && !match
                    ? (
                        <div className={'w-100 min-vh-50 d-flex justify-content-center align-items-center'}>
                            <PageLoader/>
                        </div>
                    )
                    : children
            }
        </>
    )


    /**
     * Depending on the viewport Width of the window, sets the collpased state.
     */
    useEffect(() => {
        switch (windowViewportWidth) {
            case 'xs':
            case 'sm':
            case 'md':
                if (!layout.collapsed) setLayout(prevState => ({...prevState, collapsed: true}));
                break;
            case  'lg':
            case  'xl':
            case  'xxl':
            default:
                if (layout.collapsed) setLayout(prevState => ({...prevState, collapsed: false}));
                break;
        }
    }, [windowViewportWidth])


    /**
     * Listens for the changes in location and with each change, checks if the sidebar is disabled as it is loading
     * the applications or not.
     */
    useEffect(() => {
        const disabled = !!matchPath(location.pathname, {path: routes.private.withApplication.loading, exact: true});
        const noPadding = !!matchPath(location.pathname, {path: routes.private.withApplication.versions, exact: true});
        setLayout(prevState => ({...prevState, disabled: disabled, noPadding: noPadding}))
    }, [location]);

    return (
        <LayoutContext.Provider value={layout}>
            <div className={'layout'}>
                <Sidebar collapsed={layout.collapsed}/>
                <Topbar collapsed={layout.collapsed}
                        selectedApplication={selectedApplication}
                        setSelectedApplication={setSelectedApplication}
                />
                <div className={classnames('main-section', {
                    'collapsed': layout.collapsed,
                    'no-padding': layout.noPadding,
                    'no-x-scrolling': preventXScrolling,
                })}>
                    {
                        withoutContainer
                            ? _children
                            : <Container>
                                {_children}
                            </Container>
                    }

                </div>
                <div className={'bottom-bar'}/>
            </div>
        </LayoutContext.Provider>
    );
}

export default Panel;
