import {useCallback, useEffect, useState} from 'react';
import useWindowViewportWidth from "../use_window-viewport-width";

/**
 * @typedef {{viewportWidth: viewPortWidth, width: number, height: number}} windowSize
 */

/**
 * Fetches the current size of the window.
 * @param {viewPortWidth} viewportWidth
 * @return {windowSize}
 */
const getSize = (viewportWidth) => ({
    width: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
    height: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
    viewportWidth,
})

/**
 * Determines the current size the window.
 *
 * @return {windowSize}
 */
export default function useWindowSize() {
    const isClient = typeof window === 'object';
    const viewportWidth = useWindowViewportWidth();
    const [windowSize, setWindowSize] = useState(getSize(viewportWidth));


    /**
     * Sets the current viewport width in the state to be returned from this hook.
     * @type {(function(): void)|*}
     */
    const onWindowResize = useCallback(() => {
        const size = getSize(viewportWidth);
        setWindowSize(size);
    }, [viewportWidth])

    /**
     * Listens to the changes in onWindowResize and whether the window is instantiated and with each change:
     * Sets the event listener on the resizing of window for the hook to be effective.
     */
    useEffect(() => {
        if (!isClient) return;
        window.addEventListener('resize', onWindowResize);
        onWindowResize();
        return () => window.removeEventListener('resize', onWindowResize);
    }, [isClient, onWindowResize]);

    return windowSize;
}
