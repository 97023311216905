import React, {useContext, useEffect, useState} from "react";
import {ApplicationTokenContext} from "../../../../contexts";
import useIsMounted from "../../../../hooks/use-is-mounted";
import Api from "../../../../../core/services/api_service";
import {routeFunctions} from "../../../../routes";
import useRouter from "../../../../hooks/use-router";
import Chart from "../../../../components/base/chart";
import classnames from "classnames";
import OutlinedButton from "../../../../components/base/button/outlined-button";
import TryAgain from "../../../../components/app-specific/try-again";
import DashboardTicketCard from "../../../../components/app-specific/dashboard/ticket-card";
import DashboardAnnouncementCard from "../../../../components/app-specific/dashboard/announcement-card";
import Announcement from "../announcements/single";
import {ReactComponent as AnnouncementIcon} from '../../../../../assets/images/announcement-icon.svg'
import {ReactComponent as ProjectIcon} from '../../../../../assets/images/project-icon.svg'
import {Col, Row} from "reactstrap";
import CacheService from "../../../../../core/services/cache_service";

const Dashboard = () => {
    const {history, params: {application}} = useRouter();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState();
    const [selectedAnnouncement, setSelectedAnnouncement] = useState({open: false, data: null})
    const applicationTokenContext = useContext(ApplicationTokenContext);

    const isMounted = useIsMounted();
    const userApplications = CacheService.getApplicationsOfUser();

    /**
     * Listens for the changes in the application token and with each change:
     * Fetches the dashboard information form the server.
     */
    useEffect(() => {
        getDashboardInformation();
    }, [applicationTokenContext.applicationToken])


    /**
     * Fetches the dashboard information form the server and if the result of the api is successful, sets the state.
     */
    const getDashboardInformation = () => {
        if (!applicationTokenContext.applicationToken) return;
        setLoading(true);
        Api.getDashboardInformation(applicationTokenContext.applicationToken).then((response) => {
            if (!isMounted()) return;
            if (response?.resultFlag) {
                setData(response.data);
            } else {
                setData(undefined);
            }
            setLoading(false);
        })
    }

    /**
     * Navigates the user to the tickets view or tickets' single view if ticketId is provided
     * @param ticketId {number | null}
     */
    const navigateToTickets = (ticketId = null) => {
        if (ticketId) {
            return history.push(routeFunctions.private.withApplication.tickets.single(application, ticketId));
        }
        history.push(routeFunctions.private.withApplication.tickets.base(application));
    }

    /**
     * Navigates the user to the announcements view
     */
    const navigateToAnnouncements = () => {
        history.push(routeFunctions.private.withApplication.announcements.base(application));
    }

    /**
     * Selects the announcement by opening the modal and saving the selected announcement.
     * @param announcement {any}
     */
    const selectAnnouncement = (announcement) => {
        setSelectedAnnouncement({open: true, data: announcement})
    }

    return (
        <>
            <Row className={classnames('dashboard', {'loading-div': loading})}>
                {
                    loading
                        ? <>
                            <Col xs={12} className={'py-2'}>
                                <div className={'d-flex flex-wrap w-100 justify-content-around align-items-center'}>
                                    <div className={'dashboard-card loading-div'}>
                                        <div/>
                                    </div>
                                    <div className={'dashboard-card loading-div'}>
                                        <div/>
                                    </div>
                                    <div className={'dashboard-card loading-div announcements-projects'}>
                                        <div/>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} className={'py-2'}>
                                <div className={'d-flex justify-content-around justify-content-xl-start flex-wrap' +
                                    ' w-100'}>
                                    <div className={'dashboard-card loading-div large'}>
                                        <div/>
                                    </div>
                                    <div className={'dashboard-card loading-div large'}>
                                        <div/>
                                    </div>
                                </div>
                            </Col>
                        </>
                        : !data
                            ? <TryAgain
                                onClick={getDashboardInformation}
                                text={'Filed to load the dashboard information. You may try to load them again or contact' +
                                    ' our technical support for any problems'}
                                buttonText={'Try Again'}
                            />
                            : (
                                <>
                                    <Col xs={12} className={'py-2'}>
                                        <div className={'d-flex flex-wrap w-100 justify-content-around align-items-center'}>
                                            <div className={'dashboard-card'}>
                                                <p className={'title'}>
                                                    Tickets
                                                </p>
                                                <Chart
                                                    ratio={{
                                                        title: data?.ticketsInfo ? (data?.ticketsInfo?.find(e => e.isDone)?.status?.title ?? '') : '',
                                                        ratio: data?.ticketsInfo ? ((data?.ticketsInfo?.find(e => e.isDone)?.total ?? 0) / Math.max([...data?.ticketsInfo?.map(e => e.total), 0, 0]?.reduce((p, c) => p + c) ?? 1, 1)) : 0,
                                                    }}
                                                    chartData={data?.ticketsInfo ?? []}
                                                />
                                            </div>
                                            <div className={'dashboard-card'}>
                                                <p className={'title -margin'}>
                                                    Tasks
                                                </p>
                                                <Chart
                                                    ratio={{
                                                        title: data?.tasksInfo ? (data?.tasksInfo?.find(e => e.isDone)?.status?.title ?? '') : '',
                                                        ratio: data?.tasksInfo ? ((data?.tasksInfo?.find(e => e.isDone)?.total ?? 0) / Math.max([...data?.tasksInfo?.map(e => e.total), 0, 0]?.reduce((p, c) => p + c) ?? 1, 1)) : 0,
                                                    }}
                                                    chartData={data?.tasksInfo ?? []}
                                                />
                                            </div>
                                            <div className={'dashboard-card announcements-projects'}>
                                                <div className={'triangle'}/>
                                                <div className={'announcements'}>
                                                    <div
                                                        className={'d-flex flex-column justify-content-start align-items-center'}>
                                                        <div className={'d-flex align-items-center mb-2'}>
                                                            <AnnouncementIcon className={'mr-3'}/>
                                                            <p className={'text-title mt-4'}> {data?.announcementTotal ?? 0}</p>
                                                        </div>
                                                        <p>Announcements</p>
                                                    </div>
                                                </div>
                                                <div className={'projects'}>
                                                    <div
                                                        className={'d-flex flex-column justify-content-start align-items-center'}>
                                                        <p>Projects</p>
                                                        <div className={'d-flex align-items-center mt-2'}>
                                                            <ProjectIcon className={'mr-3'}/>
                                                            <p className={'text-title'}> {userApplications?.length ?? 0}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={12} className={'py-2'}>
                                        <div className={'d-flex justify-content-around justify-content-xl-start flex-wrap' +
                                            ' w-100'}>
                                            <div className={'dashboard-card large'}>
                                                <div
                                                    className={'d-flex w-100  pl-4 pr-3 justify-content-between' +
                                                        ' align-items-center mb-4'}>
                                                    <p className={'title'}>
                                                        Latest Tickets
                                                    </p>
                                                    <OutlinedButton
                                                        onClick={() => navigateToTickets()}
                                                        className={'font-weight-bold text-xs'}
                                                    >
                                                        SEE ALL
                                                    </OutlinedButton>
                                                </div>
                                                {
                                                    !data?.latestTickets?.length
                                                        ? <TryAgain
                                                            onClick={getDashboardInformation}
                                                            buttonText={'Try Again'}
                                                            text={'There is currently no new tickets for this project.'}
                                                        />
                                                        : data?.latestTickets?.map(ticket => (
                                                            <DashboardTicketCard
                                                                key={ticket?.ticketId}
                                                                ticket={ticket}
                                                                onClick={navigateToTickets}

                                                            />
                                                        ))
                                                }
                                            </div>
                                            <div className={'dashboard-card large'}>
                                                <div
                                                    className={'d-flex w-100 pl-4 pr-3 justify-content-between' +
                                                        ' align-items-center mb-4'}>
                                                    <p className={'title'}>
                                                        Latest Announcements
                                                    </p>
                                                    <OutlinedButton
                                                        onClick={navigateToAnnouncements}
                                                        className={'font-weight-bold text-xs'}
                                                    >
                                                        SEE ALL
                                                    </OutlinedButton>
                                                </div>
                                                {
                                                    !data?.latestAnnouncements?.length
                                                        ? <TryAgain
                                                            onClick={getDashboardInformation}
                                                            buttonText={'Try Again'}
                                                            text={'There is currently no new announcements for this project.'}
                                                        />
                                                        : data?.latestAnnouncements?.map(announcement => (
                                                            <DashboardAnnouncementCard
                                                                key={announcement?.id}
                                                                announcement={announcement}
                                                                onClick={selectAnnouncement}
                                                            />
                                                        ))
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                </>
                            )
                }
            </Row>
            <Announcement
                open={selectedAnnouncement.open}
                selectedAnnouncement={selectedAnnouncement.data}
                setOpen={(value) => setSelectedAnnouncement(prevState => ({...prevState, open: value}))}
            />
            <Col xs={12} className={'my-5'}>
                <div style={{height: 1}}/>
            </Col>
        </>

    );
}


export default Dashboard;
