import apiCallback from "./api_callback";
import {
    announcementsApis,
    applicationApis,
    authApis,
    dashboardApis,
    fileSubmissionApis,
    tasksApis,
    ticketingApis,
    userApis,
    versionsApis,
} from "../constants/endpoints/core_api_endpoints";
import {apiMethods} from "../constants/enums";
import {History, Location} from 'history';
import ApiRequestDs from "../models/requests/api_request.ds";
import CrudDs from "../models/response/crud.ds";
import {downloadBlob} from "./utils";

/**
 * Interface that handles all the api calls to the server.
 */
export default class Api {
    static history: History;
    static location: Location;

    // ########################################     Helper Methods    ########################################

    /**
     * Updates the history of Api
     * @param history
     */
    static setHistory = (history: History) => {
        if (!history) return;
        Api.history = history;
    }

    /**
     * Updates the location of Api
     * @param location
     */
    static setLocation = (location: Location) => {
        if (!location) return;
        Api.location = location;
    }

    /**
     * Prepares the APi Request by injecting the history and location into the api call back
     * @param request {ApiRequestDs | any}
     * @return {ApiRequestDs}
     */
    static prepareRequest = (request: ApiRequestDs): ApiRequestDs => {
        return {
            ...request,
            history: Api.history,
            location: Api.location,
        }
    }

    // ########################################     AUTH APIS     ########################################


    /**
     * Logs the user in the system
     *
     * Checks the given email and password as well as the user access levels of different application and then logs
     * the user into the system.
     *
     * @param loginRequest {email: string, password: string} the request of the login api
     * @return {Promise<CrudDs<any> | undefined>}
     */
    static login = async (loginRequest: any): Promise<CrudDs<any> | undefined> => {
        return await apiCallback(Api.prepareRequest({
            url: authApis.login,
            method: apiMethods.post,
            body: loginRequest,
            loginRequired: false,
        }));
    }

    /**
     * Logs the user out of the system
     *
     * It will remove the user ID from MongoDB database.
     * @return {Promise<CrudDs<any> | undefined>}
     */
    static logout = async (): Promise<CrudDs<any> | undefined> => {
        return await apiCallback(Api.prepareRequest({
            url: authApis.logout,
            method: apiMethods.post,
            loginRequired: false,
        }));
    }

    /**
     * Checks if the user is logged into the system
     *
     * Checks the token in Mongo to see if it exists. If the token is in Mongo, the expiry date will be checked, and
     * if the token has not been expired yet, the information of the user will be returned which means the user is
     * logged in
     * @param token {string} the token to be checked
     * @return {Promise<CrudDs<any> | undefined>}
     */
    static isLogin = async (token: string): Promise<CrudDs<any> | undefined> => {
        return await apiCallback(Api.prepareRequest({
            url: authApis.isLogin,
            method: apiMethods.post,
            body: {token},
            loginRequired: false,
        }));
    }


    // ########################################     USER APIS     ########################################

    /**
     * Sends an email to the given email address with request password link
     *
     * This API is for creating a reset email request for the given email address. The address will be sent to the
     * entered email address if it exists in the system.
     * @param email {string}
     * @return {Promise<CrudDs<any> | undefined>}
     */
    static requestForgetPassword = async (email: string): Promise<CrudDs<any> | undefined> => {
        return await apiCallback(Api.prepareRequest({
            url: userApis.requestForgetPassword(email),
            method: apiMethods.get,
            loginRequired: false,
        }));
    }


    /**
     * Checks the Token and the Email of the url in the system for resetting password
     *
     * This authenticates the token and the email used in the generated link that was sent to the user.
     * @param checkRequest {{email: string, token: string}}
     * @return {Promise<CrudDs<any> | undefined>}
     */
    static checkForgetPasswordLinkParams = async (checkRequest: any): Promise<CrudDs<any> | undefined> => {
        return await apiCallback(Api.prepareRequest({
            url: userApis.checkForgetPasswordLinkParams,
            body: checkRequest,
            method: apiMethods.post,
            loginRequired: false,
        }));
    }

    /**
     * Resets user's password in the system
     *
     * Resets the users password if their email address exists in the system.
     * @param resetRequest {{email: string, token: string, newPassword: string}}
     * @return {Promise<CrudDs<any> | undefined>}
     */
    static resetPassword = async (resetRequest: any): Promise<CrudDs<any> | undefined> => {
        return await apiCallback(Api.prepareRequest({
            url: userApis.resetPassword,
            body: resetRequest,
            method: apiMethods.post,
            loginRequired: false,
        }));
    }


    // ########################################     APPLICATION APIS     ########################################

    /**
     * Fetches the available applications of the user.
     *
     * @return {Promise<CrudDs<any> | undefined>}
     */
    static getAvailableApplicationsForUser = async (): Promise<CrudDs<any> | undefined> => {
        return await apiCallback(Api.prepareRequest({
            url: applicationApis.getAllAvailable,
            method: apiMethods.get,
        }));
    }

    /**
     * Gets the addresses of all available applications in the application.
     *
     * @return {Promise<CrudDs<any> | undefined>}
     */
    static getAllPanelApplication = async (): Promise<CrudDs<any> | undefined> => {
        return await apiCallback(Api.prepareRequest({
            url: applicationApis.getPanelApplications,
            method: apiMethods.get,
        }));
    }

    // ########################################     TICKETING APIS     ########################################

    /**
     * Fetches all the services and types for a specific application of a specific user.
     *
     * @return {Promise<CrudDs<any> | undefined>}
     */
    static getAllServicesAndTypes = async (applicationToken: string): Promise<CrudDs<any> | undefined> => {
        return await apiCallback(Api.prepareRequest({
            url: ticketingApis.getAllServicesAndTypes(applicationToken),
            method: apiMethods.get,
        }));
    }


    /**
     * Gets all the questions based on the given type id.
     *
     * @param typeId {number} the id of the selected type
     * @return {Promise<CrudDs<any> | undefined>}
     */
    static getAllQuestionsAndHintsOfType = async (typeId: number): Promise<CrudDs<any> | undefined> => {
        return await apiCallback(Api.prepareRequest({
            url: ticketingApis.getAllQuestionsAndHintsByTypeId(typeId),
            method: apiMethods.get,
        }));
    }

    /**
     * Submit a new ticket when the given question does not have a child or hint.
     *
     * @param request {any} the request data
     * @return {Promise<CrudDs<any> | undefined>}
     */
    static submitNewTicketWithoutHint = async (request: any): Promise<CrudDs<any> | undefined> => {
        return await apiCallback(Api.prepareRequest({
            url: ticketingApis.submitTicketWithoutHint,
            method: apiMethods.post,
            body: request,
        }));
    }

    /**
     * Submit a new ticket when the given question has a hint.
     *
     * This endpoint submits a positive feedback if the feedback is useful. If the feedback is not useful, it will
     * submit a new ticket and the user should fill the form
     * @param request {any} the request data
     * @return {Promise<CrudDs<any> | undefined>}
     */
    static submitNewTicketOrPositiveFeedback = async (request: any): Promise<CrudDs<any> | undefined> => {
        return await apiCallback(Api.prepareRequest({
            url: ticketingApis.submitTicketOrFeedback,
            method: apiMethods.post,
            body: request,
            showSuccessToast: true,
        }));
    }

    /**
     * Submit the form information of a newly created ticket.
     *
     * @param request {any} the request data
     * @return {Promise<CrudDs<any> | undefined>}
     */
    static submitANewTicketForm = async (request: any): Promise<CrudDs<any> | undefined> => {
        return await apiCallback(Api.prepareRequest({
            url: ticketingApis.submitForm,
            method: apiMethods.post,
            body: request,
            showSuccessToast: true
        }));
    }


    /**
     * Gets all the submitted tickets based on the given applicationToken.
     *
     * @param applicationToken {string} the request data
     * @return {Promise<CrudDs<any> | undefined>}
     */
    static getAllSubmittedTickets = async (applicationToken: string): Promise<CrudDs<any> | undefined> => {
        return await apiCallback(Api.prepareRequest({
            url: ticketingApis.getAllSubmittedTickets(applicationToken),
            method: apiMethods.get,
        }));
    }


    /**
     * Submits a new comment of a user for a specific ticket.
     *
     * This endpoint submits comments of the user for the ticket.
     * @param request {any} the request data
     * @return {Promise<CrudDs<any> | undefined>}
     */
    static submitCommentForTicket = async (request: any): Promise<CrudDs<any> | undefined> => {
        return await apiCallback(Api.prepareRequest({
            url: ticketingApis.submitComment,
            method: apiMethods.post,
            body: request,
            showSuccessToast: true
        }));
    }

    /**
     * Gets the full information of a specific ticket given its Id.
     *
     * This endpoint submits comments of the user for the ticket.
     * @param applicationToken {string} the token of the application
     * @param ticketId {number} the if of the specific ticket
     * @return {Promise<CrudDs<any> | undefined>}
     */
    static getTicketById = async (applicationToken: string, ticketId: number): Promise<CrudDs<any> | undefined> => {
        return await apiCallback(Api.prepareRequest({
            url: ticketingApis.getTicketById(applicationToken, ticketId),
            method: apiMethods.get,
        }));
    }

    /**
     * Approves a specific ticket of a specific application by the admin of the application
     *
     * Only admins of the application would be able to use this endpoint.
     * @param request {any} the request data
     * @return {Promise<CrudDs<any> | undefined>}
     */
    static approveTicketByAdmin = async (request: any): Promise<CrudDs<any> | undefined> => {
        return await apiCallback(Api.prepareRequest({
            url: ticketingApis.submitTicketAdminApproval,
            method: apiMethods.post,
            body: request,
        }));
    }


    /**
     * Searches through the tickets of an specific application.
     *
     * This API is for filtering and getting all the tickets based on the given fields.
     * @param request {any} the request data
     * @return {Promise<CrudDs<any> | undefined>}
     */
    static searchTickets = async (request: any): Promise<CrudDs<any> | undefined> => {
        return await apiCallback(Api.prepareRequest({
            url: ticketingApis.search,
            method: apiMethods.post,
            body: request,
        }));
    }


    // ########################################     ANNOUNCEMENTS APIS     ########################################


    /**
     * Get the announcement that is associated with the given application token and announcement id..
     *
     * @param applicationToken {string} the token of the application
     * @param announcementId {number} the if of the specific ticket
     * @return {Promise<CrudDs<any> | undefined>}
     */
    static getAnnouncementById = async (applicationToken: string, announcementId: number): Promise<CrudDs<any> | undefined> => {
        return await apiCallback(Api.prepareRequest({
            url: announcementsApis.getAnnouncementById(applicationToken, announcementId),
            method: apiMethods.get,
        }));
    }


    /**
     * Searches through the announcements of an specific application.
     *
     * This API is for filtering and getting all the announcements based on the given fields.
     * @param request {any} the request data
     * @return {Promise<CrudDs<any> | undefined>}
     */
    static searchAnnouncements = async (request: any): Promise<CrudDs<any> | undefined> => {
        return await apiCallback(Api.prepareRequest({
            url: announcementsApis.search,
            method: apiMethods.post,
            body: request,
        }));
    }

    // ########################################     TASKS APIS     ########################################

    /**
     * Get the full information of a task based on its Code
     *
     * This api will return nothing if the given task code is partial or invalid
     * @param request {any} the request data
     * @return {Promise<CrudDs<any> | undefined>}
     */
    static getTaskByCode = async (request: any): Promise<CrudDs<any> | undefined> => {
        return await apiCallback(Api.prepareRequest({
            url: tasksApis.getByCode,
            method: apiMethods.post,
            body: request,
        }));
    }


    /**
     * Gets the list of live tasks for a specific application of a user.
     *
     * @param applicationToken {string} the token of the application.
     * @return {Promise<CrudDs<any> | undefined>}
     */
    static getLiveTasks = async (applicationToken: string): Promise<CrudDs<any> | undefined> => {
        return await apiCallback(Api.prepareRequest({
            url: tasksApis.getLiveTasks(applicationToken),
            method: apiMethods.get,
        }));
    }


    // ########################################     VERSIONS APIS     ########################################

    /**
     * Gets the list of versions for a specific application of the user.
     *
     * @param applicationToken {string} the token of the application.
     * @return {Promise<CrudDs<any> | undefined>}
     */
    static getAllVersionsOfApplication = async (applicationToken: string): Promise<CrudDs<any> | undefined> => {
        return await apiCallback(Api.prepareRequest({
            url: versionsApis.getAllVersions(applicationToken),
            method: apiMethods.get,
        }));
    }

    // ########################################     FILE SUBMISSION APIS     ########################################

    /**
     * Uploads a file into the system.
     *
     * Only admins of the application would be able to use this endpoint.
     * @param formData {FormData} the request data
     * @param onUploadProgress {function} the observer for the upload progress.
     * @return {Promise<CrudDs<any> | undefined>}
     */
    static uploadAFile = async (formData: FormData, onUploadProgress: any = null): Promise<CrudDs<any> | undefined> => {
        return await apiCallback(Api.prepareRequest({
            url: fileSubmissionApis.uploadFile,
            method: apiMethods.post,
            body: formData,
            onUploadProgress: onUploadProgress,
        }));
    }


    // ########################################     DASHBOARD APIS     ########################################

    /**
     * Gets the dashboard information of a specific application of a specific user.
     *
     * @param applicationToken {string} the token of the application.
     * @return {Promise<CrudDs<any> | undefined>}
     */
    static getDashboardInformation = async (applicationToken: string): Promise<CrudDs<any> | undefined> => {
        return await apiCallback(Api.prepareRequest({
            url: dashboardApis.getInformation(applicationToken),
            method: apiMethods.get,
        }));
    }


    // ########################################     LOCAL DOWNLOADER     ########################################

    /**
     * Downloads the given url into the user local machine with the specified name.
     * @param {string} url the url that needs to be fetched.
     * @param {string} name the name of the downloadable file.
     */
    static downloadUrlIntoUserMachine = async (url: string, name: string): Promise<boolean> => {
        let success = false;
        await fetch(url).then((resp) => {
            if (resp.status !== 200) return;
            return resp.blob();
        }).then((blob) => {
            if (!blob) return;
            downloadBlob(blob, name);
        }).catch(() => false);
        return success;
    }

    // ########################################     LOCAL FETCHER     ########################################

    /**
     * Fetches the local json files of the application that are located in the public folder.
     *
     * @param fileNames             the name of the files to be fetched
     * @param directory             the directory path to get the local json files from.
     * @return {Promise<{[k: string: any]}[]>}
     */
    static fetchLocalJsonFiles = async (fileNames: string[], directory: string = '/assets/content/')
        : Promise<{ data: any, fileName: string }[]> => {
        const localFiles: any = {};
        const promises: Promise<{ data: any, fileName: string }>[] = [];
        fileNames.forEach((fileName) => {
            promises.push(Api._localFileFetcher(`${fileName}.json`, directory));
        });
        return await Promise.all(promises).then((results) => {
            if (!results) {
                return localFiles;
            }
            for (const result of results) {
                // make sure to use camelCase notation for the fileNames after the fetch in case they were snakeCase
                const fileName = result.fileName.replaceAll(new RegExp(/-[a-z]/g), (string) => `${string.charAt(1).toUpperCase()}`)
                localFiles[fileName] = result.data;
            }
            return localFiles;
        });
    }

    /**
     * Fetches a single file from the public folder of the application
     *
     * @param {string} directory             the directory path to get the local json files from.
     * @param {string} filename
     * @private
     */
    static _localFileFetcher = async (filename: string, directory: string)
        : Promise<{ data: any | null, fileName: string }> => {
        try {
            const fetchResult = await fetch(`${process.env.PUBLIC_URL}${directory}${filename}`);
            return {data: await fetchResult.json(), fileName: filename}
        } catch (e) {
            return {data: null, fileName: filename};
        }
    }
}
