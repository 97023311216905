import React, {useEffect, useState} from "react";
import {LazyLoadImage as Lazy} from "react-lazy-load-image-component";
import NoImage from "../../../../assets/images/no-image.svg";


const LazyImage = ({src: srcProp, alt, placeholder, ...props}) => {
    const [src, setSrc] = useState(srcProp);

    /**
     * Listens for the changes in srcProp and with each change:
     * sets the src of the state.
     */
    useEffect(() => {
        setSrc(srcProp);
    }, [srcProp])

    /**
     * Changes the src of the image to noImage if there is noImage available and the src is not already noImage
     *
     * if the placeholder is a reactElement, then sets the src to null, to force render the placeholder element
     */
    const useDefaultImage = () => {
        if (React.isValidElement(placeholder)) {
            setSrc(null);
            return
        }
        if (src !== (placeholder ?? NoImage)) {
            setSrc(placeholder ?? NoImage);
        }
    }

    return (
        <>
            {
                src
                    ? <Lazy src={src} alt={alt} onError={useDefaultImage} {...props}/>
                    : React.isValidElement(placeholder)
                        ? placeholder
                        : <img className={'placeholder'} src={placeholder ?? NoImage} alt="No" {...props}/>
            }
        </>
    );
}

export default LazyImage;
