// **************** APPLICATION COLORS ****************

class Colors {
    static backgroundColor = 'rgba(255, 255, 255, 1)';

    static primaryColorDark = 'rgba(59, 36, 98, 1)';
    static primaryColor = 'rgba(93, 58, 158, 1)';
    static primaryColorLight = 'rgba(93, 58, 158, 0.8)';
    static primaryColorLighter = 'rgba(93, 58, 158, 0.7)';

    static secondaryColorDark = 'rgba(212, 119, 0, 1)';
    static secondaryColor = 'rgba(248, 163, 13, 1)';
    static secondaryColorLight = 'rgba(248, 163, 13, 0.8)';
    static secondaryColorLighter = 'rgba(248, 163, 13, 0.7)';

    static ternaryColor = 'rgba(39, 94, 184, 1)';
    static ternaryColorLight = 'rgba(147, 175, 219, 1)';
    static ternaryColorLighter = 'rgba(212, 223, 241, 1)';

    static white = 'white';
    static transparent = 'transparent';

    static blue = 'rgba(31, 93, 190, 1)';
    static blueLight = 'rgba(31, 93, 190, 0.3)';

    static orangeDark = 'rgba(212, 119, 0, 1)';
    static orange = 'rgba(248, 163, 13, 1)';
    static orangeLight = 'rgba(248, 163, 13, 0.3)';

    static green = 'rgba(45, 155, 109, 1)';
    static greenLight = 'rgba(45, 155, 109, 0.3)';

    static red = '#f44336';
    static redLight = '#ef9a9a';

    static defaultShadowColor = '#4F4F4F';

    static primaryTextColor = 'rgba(51, 51, 51, 1)';
    static secondaryTextColor = 'rgba(79, 79, 79, 1)';
    static disabledTextColor = 'rgba(189, 189, 189, 0.5)';
    static hintTextColor = 'rgba(189, 189, 189, 1)';
}

export default Colors;
