import React from "react";
import PageLoader from "../../../../components/app-specific/page-loader";


const LoadingApplications = () => {

    return (
        <div className={'w-100 min-vh-50 d-flex justify-content-center align-items-center'}>
            <PageLoader/>
        </div>
    );
}

export default LoadingApplications;
