import React from "react";
import IconPlaceHolder from "../../../../../assets/images/icon-placeholder.svg";
import useRouter from "../../../../hooks/use-router";
import {routeFunctions} from "../../../../routes";

const BoardListTaskCard = ({item}) => {
    const {history, params} = useRouter();

    /**
     * Navigates the user to the tasks single page
     * @param e
     */
    const navigateToSingle = (e) => {
        history.push(routeFunctions.private.withApplication.task(params.application, item.code));
    }

    return (
        <div className={'card task-card'} onClick={navigateToSingle}>
            <div className={'description'}>
                <p>
                    {
                        item?.summary?.length > 100
                            ? item?.summary?.substring(0, 100)?.concat('...')
                            : item?.summary ?? ''
                    }
                </p>
            </div>
            <div className={'d-flex align-items-center ticket-type mt-1'}>
                <img src={item?.iconURL ?? IconPlaceHolder} alt="icon"/>
                <p className={'title text-xs'}>
                    {item?.code ?? ''}
                </p>
            </div>

        </div>
    );
}

export default BoardListTaskCard;
