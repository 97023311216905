import {BizKeys, LoginLines} from "../../../core/constants/enums";
import React, {useContext, useEffect, useLayoutEffect, useRef, useState} from "react";
import {Col, Container, Row} from "reactstrap";
import useWindowSize from "../../hooks/use-window-size";
import {setAttributes} from "../../../core/services/utils";
import debounce from "lodash.debounce";
import classnames from "classnames";
import {ReactComponent as InfiniteKey} from "../../../assets/images/login-page/infinite_key.svg";
import {ReactComponent as ActKey} from "../../../assets/images/login-page/act_key.svg";
import {ReactComponent as AimKey} from "../../../assets/images/login-page/aim_key.svg";
import {Fade} from "@material-ui/core";
import {JsonContentContext} from "../../contexts";


// The Max-Height of this page
const maxHeight = 690;
// The Min-Height of this page
const minHeight = 535;
// The Height of the Line Container
const lineContainerHeight = 40;
// The Height of the line. It must be less than lineContainerHeight
const lineHeight = 6;
// The Width of the Line
const lineWidth = 25;
// The height of each of the Biz Principle images
const logoWidth = 210;
// The duration in which the debounce wait before changing the
const changeLineNumbersWaitDuration = 300;
// The duration in which the debounce wait before the changing the color of lines.
const changeLineColorsWaitDuration = 50;


/**
 * Randomly chooses a new title and message for each of the biz phases and returns the new data.
 * @param jsonContent
 * @return {*&{bizkey: {actKey, aimKey, infiniteKey}}}
 */
const selectContentForBizPhases = (jsonContent) => {
    if (Array.isArray(jsonContent?.bizkey?.aimKey)) {
        return {
            ...jsonContent,
            bizkey: {
                aimKey: jsonContent.bizkey.aimKey[Math.trunc(Math.random() * (jsonContent.bizkey.aimKey.length - 1))],
                actKey: jsonContent.bizkey.actKey[Math.trunc(Math.random() * (jsonContent.bizkey.actKey.length - 1))],
                infiniteKey: jsonContent.bizkey.infiniteKey[Math.trunc(Math.random() * (jsonContent.bizkey.infiniteKey.length - 1))],
            }
        }
    }
    return jsonContent;
}

const BaseAuthView = ({children}) => {
    const [selectedKey, setSelectedKey] = useState(BizKeys[Object.keys(BizKeys)[Math.round(Math.random() * 2)]]);
    const jsonContent = useContext(JsonContentContext);
    const [selectedJsonContent, setSelectedJsonContent] = useState(() => selectContentForBizPhases(jsonContent));
    const containerRef = useRef();

    /**
     * Sets the newKey as the selected key if the new key is not the same as the selected key
     * @param newKey
     */
    const selectNewKey = (newKey) => {
        if (newKey === selectedKey) return;
        setSelectedKey(newKey);
        setSelectedJsonContent(selectContentForBizPhases(jsonContent))
    }

    return (
        <>
            <Container className={'w-100 vh-100'}>
                <Row className={'h-100'}>
                    <Col xs={12} lg={4} xl={3} className={'h-100 d-flex justify-content-center align-items-center'}>
                        <div ref={containerRef} className={'h-100 d-flex justify-content-center align-items-center'}>
                            <FormSection containerRef={containerRef} selectedKey={selectedKey}>
                                {children}
                            </FormSection>
                        </div>
                    </Col>
                    <Col xs={0} lg={8} xl={9} className={'h-100 d-none d-lg-block'}>
                        <Row className={'h-100'}>
                            <Col className={'d-flex justify-content-center align-items-center'}>
                                <BizKeySection
                                    jsonContent={selectedJsonContent}
                                    selectedKey={selectedKey}
                                    onKeyClicked={selectNewKey}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

const FormSection = ({selectedKey, children, containerRef}) => {
    const windowSize = useWindowSize();

    /**
     * Listens for the changes in the selected key and with each change, changes the color of the lines to the color
     * fo the selected key
     */
    useEffect(() => {
        const lines = document.getElementsByClassName('line');
        const container = containerRef?.current;
        if (lines?.length <= 0 || !container) return;
        changeLineColors(lines, container);
        return () => changeLineColors.cancel();
    }, [containerRef?.current, selectedKey]);

    /**
     * Listens for the changes in the size of the window and with each change, invokes the moveLines function with a
     * debounced property.
     */
    useEffect(() => {
        const lines = document.getElementsByClassName('line');
        const container = containerRef?.current;
        if (lines?.length <= 0 || !container) return;
        changeLinePositions(lines, container);
        return () => changeLinePositions.cancel();
    }, [containerRef?.current, windowSize.width, windowSize.height]);

    /**
     * As soon as the component renders, creates the background lines for the login form section
     */
    useLayoutEffect(() => {
        if (!containerRef.current)
            return;
        createLines();
    }, [containerRef.current]);


    /**
     * Creates a single line and returns it
     * @param containerWidth {number}
     * @param index {number}
     */
    const createLine = (index, containerWidth) => {
        const div = document.createElement('div');
        setAttributes(div,
            {
                style: {
                    position: 'absolute',
                    top: `${(index * lineContainerHeight) + ((lineContainerHeight - lineHeight) / 2)}px`,
                    left: `${(Math.random() * (containerWidth - lineWidth - 5))}px`,
                    backgroundColor: selectedKey.color,
                    width: `${lineWidth}px`,
                    height: `${lineHeight}px`,
                    zIndex: 1,
                }
            });
        div.classList.add('rounded-md', 'transition', 'line');
        return div
    }

    /**
     * Creates the lines of bizkey.json tech login page for the first time. and appends them to element with the
     * container containerRef.
     */
    const createLines = () => {
        const container = containerRef.current;
        const w = container.clientWidth;
        const h = container.clientHeight;
        const numberOfLines = Math.trunc(h / lineContainerHeight);
        for (let index = 0; index < numberOfLines; ++index) {
            container.appendChild(createLine(index, w));
        }
    }

    /**
     * Moves the lines of the page when the user changes the size of them
     * if there is a vertical change in the window, will create or remove lines accordingly to fit the screen
     * @type {function}
     */
    const changeLinePositions = debounce((lines, container) => {
        const w = container.clientWidth;
        const h = container.clientHeight;
        const numberOfNewLines = Math.trunc(h / lineContainerHeight);
        // remove extra lines from the end when there are extra lines
        if (numberOfNewLines < lines?.length) {
            for (let index = lines?.length - 1; index > numberOfNewLines; --index) {
                try {
                    container.removeChild(lines.item(index));
                } catch (e) {
                }
            }
        }
        // add extra lines to the end when there are less lines
        if (numberOfNewLines > lines?.length) {
            for (let index = lines?.length; index < numberOfNewLines; ++index) {
                container.appendChild(createLine(index, w));
            }
        }
        // change the position of each line
        for (let index = 0; index < lines?.length; ++index) {
            let newLeft = (Math.random() * (w - lineWidth));
            if (index > 0) {
                const prev = lines.item(index - 1);
                const prevLeft = parseFloat(prev.style.left.substring(0, prev.style.left.length - 2));
                while ((prevLeft + lineWidth >= newLeft && prevLeft + lineWidth <= newLeft + lineWidth) || (prevLeft >= newLeft && prevLeft <= newLeft + lineWidth)) {
                    newLeft = (Math.random() * (w - lineWidth));
                }
            }
            lines.item(index).style.left = `${newLeft}px`;
        }
    }, changeLineNumbersWaitDuration);

    /**
     * Changes the color of the lines depending on the selected key color
     * @type {function}
     */
    const changeLineColors = debounce((lines) => {
        for (let index = 0; index < lines?.length; ++index) {
            lines.item(index).style.backgroundColor = selectedKey.color;
        }
    }, changeLineColorsWaitDuration);

    return (
        <>
            <div className={'h-100 z-index-100'}
                 style={{
                     minHeight: minHeight,
                     maxHeight: maxHeight,
                 }}
            >
                <div className={'overflow-hidden bg-white'}
                     style={{marginTop: 60}}
                >
                    {children}
                </div>
            </div>
        </>
    );
}

const BizKeySection = ({selectedKey, onKeyClicked, jsonContent}) => {

    return (
        <>
            <div className={'h-100'}
                 style={{
                     minHeight: minHeight,
                     maxHeight: maxHeight,
                 }}
            >
                <Row className={'h-100'}>
                    {/** The Vertical Line at left */}
                    <Col className={'h-100 d-flex align-items-center flex-grow-0'}>
                        <div id={LoginLines.leftVertical}
                             className={classnames(
                                 'line-vertical',
                                 'arrow-up',
                                 'transition',
                                 'h-75',
                             )}
                        />
                    </Col>
                    <Col className={'h-100'}>
                        <Row className={'h-100'}>
                            <Col xs={12}>
                                <Row>
                                    {/** The Horizontal Line at top */}
                                    <Col
                                        className={'d-flex flex-column justify-content-center align-items-center'}>
                                        <div id={LoginLines.topHorizontal}
                                             className={classnames(
                                                 'line-horizontal',
                                                 'arrow-right',
                                                 'transition',
                                                 'w-100',
                                             )}
                                        />
                                        <MessageContainer
                                            selectedKey={selectedKey}
                                            bizkey={BizKeys.aimKey}
                                            jsonContent={jsonContent.bizkey?.aimKey}
                                        />
                                    </Col>
                                    {/** AimKey */}
                                    <Col className={'flex-grow-0'}>
                                        <AimKey
                                            style={{width: `${logoWidth}px`}}
                                            className={'transition cursor-pointer-hover'}
                                            onClick={() => onKeyClicked(BizKeys.aimKey)}
                                            {...(selectedKey !== BizKeys.aimKey ? {opacity: '0.3'} : {})}
                                        />
                                        <BizPhaseName
                                            bizkey={BizKeys.aimKey}
                                            selectedKey={selectedKey}
                                            onClick={() => onKeyClicked(BizKeys.aimKey)}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} className={'d-flex flex-column justify-content-center align-items-end h-50'}>
                                <Row className={'h-100'}>
                                    {/** Right Top vertical Line */}
                                    <Col xs={12} className={'d-flex justify-content-center'}>
                                        <div id={LoginLines.topRightVertical}
                                             className={classnames(
                                                 'line-vertical',
                                                 'arrow-down',
                                                 'transition',
                                                 'h-100',
                                             )}
                                        />
                                    </Col>
                                    {/** ActKey */}
                                    <Col xs={12} className={'d-flex justify-content-end align-items-center'}>
                                        <ActKey {...(selectedKey !== BizKeys.actKey ? {opacity: '0.3'} : {})}
                                                className={'transition cursor-pointer-hover'}
                                                onClick={() => onKeyClicked(BizKeys.actKey)}
                                                style={{width: `${logoWidth}px`}}
                                        />
                                        <BizPhaseName
                                            bizkey={BizKeys.actKey}
                                            selectedKey={selectedKey}
                                            onClick={() => onKeyClicked(BizKeys.actKey)}
                                        />
                                    </Col>
                                    {/** Right Bottom vertical Line */}
                                    <Col xs={12} className={'d-flex justify-content-center'}>
                                        <div id={LoginLines.bottomRightVertical}
                                             className={classnames(
                                                 'line-vertical',
                                                 'arrow-down',
                                                 'transition',
                                                 'h-100',
                                             )}
                                        />
                                    </Col>
                                </Row>
                                <MessageContainer
                                    selectedKey={selectedKey}
                                    bizkey={BizKeys.actKey}
                                    jsonContent={jsonContent.bizkey?.actKey}
                                />
                            </Col>
                            <Col xs={12}>
                                <Row>
                                    {/** The Horizontal Line at Bottom */}
                                    <Col
                                        className={'d-flex flex-column justify-content-center align-items-center'}
                                    >
                                        <div id={LoginLines.bottomHorizontal}
                                             className={classnames(
                                                 'line-horizontal',
                                                 'arrow-left',
                                                 'transition',
                                                 'w-100',
                                             )}
                                        />
                                        <MessageContainer
                                            selectedKey={selectedKey}
                                            bizkey={BizKeys.infiniteKey}
                                            jsonContent={jsonContent.bizkey?.infiniteKey}
                                        />
                                    </Col>
                                    {/** InfiniteKey */}
                                    <Col className={'flex-grow-0'}>
                                        <InfiniteKey
                                            {...(selectedKey !== BizKeys.infiniteKey ? {opacity: '0.3'} : {})}
                                            className={'transition cursor-pointer-hover'}
                                            onClick={() => onKeyClicked(BizKeys.infiniteKey)}
                                            style={{width: `${logoWidth}px`}}
                                        />
                                        <BizPhaseName
                                            bizkey={BizKeys.infiniteKey}
                                            selectedKey={selectedKey}
                                            onClick={() => onKeyClicked(BizKeys.infiniteKey)}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
    );
}

const MessageContainer = ({bizkey, selectedKey, jsonContent}) => {

    if (bizkey === BizKeys.actKey) {
        return (
            <Row style={{marginRight: '13rem', left: 0, right: 0}} className={'w-75 h-100' +
                ' justify-content-center' +
                ' align-items-center' +
                ' position-absolute'}>
                <Fade in={selectedKey === bizkey} mountOnEnter unmountOnExit>
                    <div className={'p-2 bg-white position-absolute m-auto transition w-50'}>
                        <div className={'p-3 rounded-xxl transition'} style={{backgroundColor: selectedKey.focusColor}}>
                            <h1 className={'text-title white font-weight-medium mb-3'}>
                                {jsonContent.title}
                            </h1>
                            <p className={'text-sm white soft-wrap font-weight-medium text-wrap'}>
                                {jsonContent.message}
                            </p>
                        </div>
                    </div>
                </Fade>
            </Row>
        );
    }

    return (
        <Fade in={selectedKey === bizkey} mountOnEnter unmountOnExit>
            <div className={'p-2 bg-white z-index-100 position-absolute m-auto transition w-50'}>
                <div className={'p-3 rounded-xxl transition'} style={{backgroundColor: selectedKey.focusColor}}>
                    <h1 className={'text-title white font-weight-medium mb-3'}>
                        {jsonContent.title}
                    </h1>
                    <p className={'text-sm white soft-wrap font-weight-medium text-wrap'}>
                        {jsonContent.message}
                    </p>
                </div>
            </div>
        </Fade>


    );
}

const BizPhaseName = ({bizkey, selectedKey, onClick}) => {
    let top, left, color, padding;
    const focus = selectedKey === bizkey;
    switch (bizkey) {
        case BizKeys.aimKey:
            top = 86;
            left = 112;
            color = focus ? 'bg-orange' : 'bg-orange-light';
            padding = 'px-4-5';
            break;
        case BizKeys.actKey:
            top = 128;
            left = 176;
            color = focus ? 'bg-green' : 'bg-green-light';
            padding = 'px-4-5';
            break;
        case BizKeys.infiniteKey:
            color = focus ? 'bg-blue' : 'bg-blue-light';
            top = 89.1;
            left = 112;
            padding = 'px-3-1';
            break;
    }

    return (
        <div className={classnames('position-absolute py-0 bg-white cursor-pointer-hover')}
             onClick={onClick}
             style={{top: top, left: left}}>
            <div className={classnames(color, padding, 'transition')}>
                <p>
                    <span className={'black font-weight-bold text-xs '}>{bizkey.name}</span>
                    <span className={'white font-weight-bold text-xs'}>KEY</span>
                </p>
            </div>
        </div>

    );
}

export default BaseAuthView;
