import React, {useContext} from "react";
import {LayoutContext} from "../../../../contexts";
import classnames from "classnames";
import useRouter from "../../../../hooks/use-router";
import {NavLink} from "react-router-dom";


const SidebarItem = ({
                         routeName,
                         icon,
                         matchIcon = icon,
                         children,
                         goTo,
                     }) => {
    const sidebarContext = useContext(LayoutContext);
    const {match} = useRouter({path: routeName});

    return (
        <NavLink to={goTo ? goTo() : routeName}>
            <div className={classnames('sidebar-item', {...sidebarContext, 'active': !!match})}>
                <div className={'rect'}/>
                <span className="sidebar-icon-wrapper">
                     <span className="sidebar-icon">
                    {!!match
                        ? matchIcon ?? icon
                        : icon
                    }
                </span>
                </span>
                <span className="item-content">
                    {children}
                </span>
                {/*<NavLink className={''} to={goTo ? goTo() : routeName}/>*/}
            </div>
        </NavLink>

    );
}

export default SidebarItem;
