import React, {useEffect, useState} from "react";
import Dialog from "../../base/dialog";
import * as Yup from "yup";
import ValidateMessages from "../../../../core/constants/texts/vallidate_messages";
import {makeRequired, makeValidate} from "mui-rff";
import Api from "../../../../core/services/api_service";
import useRouter from "../../../hooks/use-router";
import useIsMounted from "../../../hooks/use-is-mounted";
import {Col} from "reactstrap";
import classnames from "classnames";
import {OutlinedInputForm} from "../../base/input/outlined_input";
import UploadButton from "../../base/button/upload-button";
import {ReactComponent as AddIcon} from "../../../../assets/images/add-icon.svg";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import SubmittedFile from "../../app-specific/submitted-file";
import OutlinedButton from "../../base/button/outlined-button";
import Form from "../../base/form";
import Colors from "../../../../assets/js/colors";
import {SubmittedFileBackgrounds} from "../../../../core/constants/enums";
import {createUUId, getSupportedFileType, numComparator} from "../../../../core/services/utils";

// the schema of adding a comment
const schema = Yup.object().shape({
    description: Yup.string().nullable().required(ValidateMessages.required),
})

const AddCommentToTicketDialog = ({open, setOpen, onSuccess}) => {
    const {params} = useRouter();
    const [submitting, setSubmitting] = useState(false);
    const [uploadingAttachments, setUploadingAttachments] = useState(0);
    const [submittedFiles, setSubmittedFiles] = useState([]);
    const [submittedCount, setSubmittedCount] = useState(0);
    const isMounted = useIsMounted();
    const validate = makeValidate(schema);
    const required = makeRequired(schema);
    const colClassName = 'mt-4 px-4';

    /**
     * Listens to the changes in the open status of this dialog and with each change:
     * - if the dialog is closed, resets the submitted files.
     */
    useEffect(() => {
        if (!open) {
            setUploadingAttachments(0);
            setSubmittedFiles([]);
            setSubmittedCount(0);
        }
    }, [open])

    /**
     * Submits a new comment by calling the server api and if the result of the api is successful, adds the newly
     * added comment to the
     * @param values {any}
     * @return {Promise<any>}
     */
    const submitComment = async (values) => {
        if (!values.description?.trim().length) {
            return {
                description: "your message must at least contain one non-space character."
            }
        }
        const forApi = {
            description: {
                files: submittedFiles
                        ?.sort((a, b) => numComparator(a.orderIndex, b.orderIndex))
                        ?.map((e, index) => ({...e, orderIndex: index + 1}))
                    ?? [],
                content: values?.description ?? '',
            },
            ticketId: parseInt(params?.id),
        };
        setSubmitting(true);
        const response = await Api.submitCommentForTicket(forApi)
        if (!isMounted()) return;
        if (response?.resultFlag) {
            onSuccess(response.data);
            setOpen(false);
        }
        setSubmitting(false);
    }

    /**
     * For each of the files, upload them to the server api.
     * @param files {FileList}
     */
    const uploadFiles = async (files) => {
        if (!files?.length) return;
        const newSubmittedFiles = [];
        setUploadingAttachments(files.length);
        for (let index = 1; index <= files.length; ++index) {
            const file = files.item(index - 1);
            const response = await uploadFile(file);
            if (response) {
                newSubmittedFiles.push({
                    filename: response.fileName,
                    fileTitle: response.fileTitle,
                    baseURL: response.baseURL,
                    extension: response.type,
                    orderIndex: submittedCount + index,
                    type: getSupportedFileType(response.type),
                    key: createUUId(true),
                });
            }
        }
        setSubmittedFiles(prevState => [...prevState, ...newSubmittedFiles])
        setUploadingAttachments(0);
        setSubmittedCount(prevState => prevState + files.length);
    }


    /**
     * Uploads an image to the server then depending on the result of the api, returns the uploaded file
     * @param data {File}
     */
    const uploadFile = async (data) => {
        const formData = new FormData();
        formData.append("file", data, data.name);
        const response = await Api.uploadAFile(formData);
        if (!isMounted()) return;
        if (response?.resultFlag) {
            return response.data;
        }
    }

    /**
     * Removes the submitted file from the list of submitFiles
     * @param submitFile {any}
     */
    const removeSubmitFile = (submitFile) => {
        setSubmittedFiles(prevState => prevState?.filter(e => e.filename !== submitFile.filename));
    }


    return (
        <Dialog
            open={open}
            setOpen={setOpen}
            maxWidth={'md'}
            className={'add-comment-dialog'}
        >
            <Form
                initialValues={{}}
                validate={validate}
                onSubmit={submitComment}
                className={'add-comment w-100'}
                render={({submitting: submittingForm}) => {
                    return (
                        <>
                            <Col xs={12} className={classnames('px-4 mb-3')}>
                                <OutlinedInputForm
                                    multiline
                                    className={'w-100'}
                                    rows={7}
                                    required={!!required.description}
                                    name={'description'}
                                    placeholder={'please type your message here...'}
                                    label={'Your Message'}
                                    disabled={!!submitting || !!submittingForm}
                                />
                            </Col>
                            <div className={'d-flex flex-wrap'} style={{backgroundColor: 'rgba(75, 60, 111, 0.8)'}}>
                                <Col xs={12} className={classnames(colClassName)}>
                                    <div className={'w-100 d-flex justify-content-between'}>
                                        <p className={'text-sm font-weight-bold white'}>
                                            Attachments
                                        </p>
                                        <UploadButton
                                            multiple
                                            id={'file-submission-upload-button'}
                                            onFileSelect={uploadFiles}
                                            disabled={!!submitting || !!submittingForm || uploadingAttachments > 0}
                                        >
                                            <AddIcon className={'add-icon'}/>
                                        </UploadButton>
                                    </div>
                                    <TransitionGroup
                                        className={'d-flex flex-column w-100 mt-3'}>
                                        {[
                                            ...submittedFiles?.map(submitFile => (
                                                <CSSTransition
                                                    key={submitFile.key}
                                                    timeout={300}
                                                    classNames="css-item"
                                                >
                                                    <SubmittedFile
                                                        background={SubmittedFileBackgrounds.dark}
                                                        submitFile={submitFile}
                                                        removeFile={removeSubmitFile}
                                                    />
                                                </CSSTransition>
                                            )),
                                            ...Array(uploadingAttachments).fill(null).map((e, index) => (
                                                <CSSTransition
                                                    key={-(index + 1)}
                                                    timeout={300}
                                                    classNames="css-item">
                                                    <div className={'file-attachment mb-2' +
                                                        ' loading-div'}>
                                                        <div/>
                                                    </div>
                                                </CSSTransition>
                                            ))
                                        ]}
                                    </TransitionGroup>
                                </Col>
                                <Col xs={12} className={classnames(colClassName, 'mt-3 mb-3 d-flex' +
                                    ' justify-content-center')}>
                                    <OutlinedButton
                                        type={'submit'}
                                        disabled={submitting || submittingForm}
                                        className={'w-100 w-md-75'}
                                        backgroundColor={Colors.white}
                                    >
                                        {submitting || submittingForm ? 'Sending...' : 'SEND'}
                                    </OutlinedButton>
                                </Col>
                            </div>
                        </>
                    );
                }}
            />

        </Dialog>
    );
}

export default AddCommentToTicketDialog;
