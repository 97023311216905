import React from "react";
import {ReactComponent as QuestionIcon} from '../../../../assets/images/question-icon.svg';

const QuestionCard = ({question, onClick}) => {

    return (
        <div className={'question-card'} onClick={() => onClick(question)}>
            <div className={'d-flex flex-wrap'}>
                <p className={''}>
                    <QuestionIcon className={'mr-2'}/>
                    {question?.body}
                </p>
            </div>

        </div>
    );
}

export default QuestionCard;
