import {Redirect, Switch} from "react-router";
import routes from "../../../routes";
import React from "react";

const PrivateViewsWithoutApplication = () => {
    return (
        <>
            <Switch>
                <Redirect to={routes.error.notFound}/>
            </Switch>
        </>
    );
}

export default PrivateViewsWithoutApplication;

