import {Col} from "reactstrap";
import {ReactComponent as CalendarIcon} from "../../../../../assets/images/calendar.svg";
import moment from "moment";
import React from "react";

const DashboardAnnouncementCard = ({announcement, onClick}) => {

    return (
        <div className={'d-flex w-100 flex-wrap info-card'} onClick={() => onClick(announcement)}>
            <Col xs={12}
                 className={'p-0 mt-1 d-flex justify-content-between'}>
                <p className={'title'}>
                    {announcement?.title ?? ''}
                </p>
                <div
                    className={'d-flex align-items-center justify-content-end'}>
                    <CalendarIcon
                        className={'icon fill-ternary-color-light mr-2'}/>
                    <p className={'text-xs black'}>{moment(announcement?.publishDate).format('yyyy-MM-DD')}</p>
                </div>
            </Col>
            <Col xs={12} className={'p-0 mt-1'}>
                <p className={'description'}>
                    {
                        announcement?.longDescription?.length > 100
                            ? announcement?.longDescription?.substring(0, 100)?.concat('...')
                            : announcement?.longDescription ?? ''}
                </p>
            </Col>
        </div>
    );
}

export default DashboardAnnouncementCard;
