import React from 'react';
import {OutlinedInput as MuiOutlinedInput} from '@material-ui/core';
import {TextField} from "mui-rff";
import {Label} from "reactstrap";
import classnames from "classnames";
import {useInputStyles as useStyles} from './_styles'

export const OutlinedInput = ({
                                  type = 'text',
                                  ariaLabel = 'input',
                                  placeholder = 'type...',
                                  name,
                                  fullWidth = true,
                                  className = '',
                                  label,
                                  value,
                                  onChange,
                                  ...props
                              }) => {
    const classes = useStyles({hasLabel: false});

    return (
        <div className={classnames('d-flex flex-column align-items-start input', className)}>
            <Label for={name} className={classnames(classes.label, {'w-100': fullWidth})}>
                {label}
            </Label>
            <MuiOutlinedInput
                id={name}
                classes={{root: classes.innerInput, input: classes.input}}
                fullWidth={fullWidth}
                placeholder={placeholder}
                type={type}
                value={value}
                onChange={onChange}
                {...props}
            />
        </div>
    );
}


export const OutlinedInputForm = ({
                                      placeholder = 'type...',
                                      ariaLabel = 'input',
                                      type = 'text',
                                      required = false,
                                      fullWidth = true,
                                      className = '',
                                      label,
                                      helperText,
                                      name,
                                      disabled = false,
                                      multiline,
                                      rows,
                                      ...props
                                  }) => {
    const classes = useStyles({hasLabel: false});
    return (
        <div className={classnames('d-flex flex-column align-items-start input')}>
            <Label for={name} className={classnames(classes.label, {'w-100': fullWidth})}>
                {label}
            </Label>
            <TextField
                id={name}
                rows={rows}
                aria-multiline={multiline}
                multiline={multiline}
                disabled={disabled}
                helperText={helperText}
                fullWidth={fullWidth}
                required={required}
                type={type}
                variant={'outlined'}
                name={name}
                classes={{root: classnames(classes.innerInput)}}
                InputProps={{
                    classes: {input: classes.input}
                }}
                className={classnames(className)}
                placeholder={placeholder}
                inputProps={{'aria-label': ariaLabel}}
                {...props}
            />
        </div>
    );
}

