import React, {useState} from "react";
import {ReactComponent as SearchIcon} from '../../../../../assets/images/topbar/search-icon.svg';
import {SvgIcon} from "@material-ui/core";
import classnames from "classnames";

const TopbarSearchBox = ({
                             search,
                             placeholder = 'Task Look-Up',
                             disabledPlaceholder = 'loading...',
                             disabled,
                             initialValue
                         }) => {
    const [res, setRes] = useState(initialValue);
    const [errorMessage, setErrorMessage] = useState();


    /**
     * Searches the result with the search callback
     */
    const onSearchPressed = async () => {
        if (!search) return;
        setErrorMessage(await search(res));
    }

    /**
     * Listens for the key of enter, and if pressed, sets the res and also calls the search function
     * @param e
     */
    const onKeyPress = async (e) => {
        // if enter is pressed
        if (e.charCode === 13) {
            setRes(e.target.value);
            if (!search) return;
            setErrorMessage(await search(res));
        }
    }

    return (
        <div className={classnames('topbar-search-box', {disabled, error: !!errorMessage?.length})}
             onKeyPress={onKeyPress}>
            <input
                disabled={disabled}
                className={'search-input'}
                type="text"
                value={res}
                name={'top-bar-search-box'}
                onChange={e => setRes(e.target.value)}
                placeholder={disabled ? disabledPlaceholder : placeholder}
                aria-label={'The input for searching tasks with their code'}
            />
            <SvgIcon onClick={onSearchPressed} component={SearchIcon} className={'search-icon'}/>
        </div>
    )
}

export default TopbarSearchBox;
