import useRouter from "../../../hooks/use-router";
import React, {useEffect, useState} from "react";
import useIsMounted from "../../../hooks/use-is-mounted";
import CacheService from "../../../../core/services/cache_service";
import Api from "../../../../core/services/api_service";
import LocalStorageService from "../../../../core/services/local_storage_service";
import routes, {routeFunctions} from "../../../routes";
import {Col, Row} from "reactstrap";
import * as Yup from "yup";
import ValidateMessages from "../../../../core/constants/texts/vallidate_messages";
import {makeRequired, makeValidate} from "mui-rff";
import Form from "../../../components/base/form";
import Logo from "../../../../assets/logos/bizkeyTech.png";
import {InputForm} from "../../../components/base/input/input";
import {Fade} from "@material-ui/core";
import Button from "../../../components/base/button/button";
import Colors from "../../../../assets/js/colors";

/**
 * The Schema of the login Form
 */
const loginSchema = Yup.object().shape({
    email: Yup.string().email(ValidateMessages.incorrectType('email')).nullable(),
    password: Yup.string().nullable(),
});

const Login = () => {
    const {history, params} = useRouter();
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState();
    const isMounted = useIsMounted();
    const loginValidate = makeValidate(loginSchema);
    const loginRequired = makeRequired(loginSchema);


    /**
     * As soon as the component mounts, checks if the user is logged in or not and reacts accordingly
     */
    useEffect(() => {
        checkIfUserIsLoggedIn();
    }, [])

    /**
     * Checks if the user is logged in locally and then checks the system if the user is logged in.
     *
     * If the response of the api is successful, redirects the user to their cached url.
     */
    const checkIfUserIsLoggedIn = () => {
        setLoading(true);
        const tokenAndExpiration = CacheService.retrieveTokenAndExpiration();
        if (!tokenAndExpiration || tokenAndExpiration?.tokenExpiration <= 1) {
            setLoading(false);
            return;
        }
        Api.isLogin(tokenAndExpiration.token).then((response) => {
            if (!isMounted()) return;
            if (!response) {
                setLoading(false);
                return;
            }
            if (response.resultFlag) {
                redirectUserAfterLogin();
                return;
            }
            if (!isMounted()) return;
            setLoading(false);
        });

    }

    /**
     * Logs the user into the system by calling the api and if the response is successful, redirects the user to the
     * appropriate route.
     * @param loginInfo {{email: string, password: string}}
     */
    const login = async (loginInfo) => {
        setLoading(true);
        const response = await Api.login(loginInfo);
        if (!isMounted()) return;
        if (response?.resultFlag) {
            CacheService.setLoginInfo(
                response.data.token,
                response.data.tokenLifeSpanMinutes,
                response.data.firstName,
                response.data.lastName,
                loginInfo.email,
                response.data.type,
            );
            redirectUserAfterLogin();
            if (!isMounted()) return;
        }
        setLoading(false);
        return response?.resultFlag ? undefined : response?.message ?? 'Login Error';
    }

    /**
     * Redirects the logged in user depending on the parameters.
     *
     * if redirectUrl is present, redirects the page to the given url with token and expiration date.
     * if there exists a cached url in local storage, redirects to that page
     * else redirects to the landing page.
     */
    const redirectUserAfterLogin = () => {
        if (params.redirectUrl) {
            const {token, tokenExpiration} = CacheService.retrieveTokenAndExpiration();
            const redirectUrl = `${decodeURIComponent(params.redirectUrl)}/${encodeURIComponent(token)}/${tokenExpiration}`;
            window.location.href = decodeURIComponent(redirectUrl);
            return;
        }
        const cachedUrl = CacheService.getCachedUrl();
        if (cachedUrl) {
            history.replace(cachedUrl);
            LocalStorageService.remove(LocalStorageService.keys.cachedUrl);
        } else {
            const applicationName = LocalStorageService.get(LocalStorageService.keys.selectedApplicationName);
            if (applicationName) {
                history.replace(routeFunctions.private.withApplication.dashboard(applicationName));
            } else {
                history.replace(routes.private.withApplication.loading);
            }
        }
    };

    /**
     * Submits the login form and if there is any errors resets the form and shows the error
     * @param values {{email: string, password: string}}
     * @param form {any}
     */
    const submitLoginForm = async (values, form) => {
        if (!values.email || !values.password) {
            const submitErrors = {};
            if (!values.email) {
                submitErrors['email'] = ValidateMessages.required;
            }
            if (!values.password) {
                submitErrors['password'] = ValidateMessages.required;
            }
            return submitErrors;
        }
        const loginResponse = await login(values);
        // contains error
        if (loginResponse) {
            form.reset();
            setMessage({resultFlag: false, message: loginResponse})
        }
    }

    /**
     * Navigates the user into forgot password section
     */
    const navigateToForgotPassword = () => {
        history.replace(routeFunctions.auth.forgotPassword(params?.redirectUrl));
    }

    return (
        <>
            <Form
                className={'container justify-content-center align-items-center'}
                validate={loginValidate}
                onSubmit={submitLoginForm}
                render={({submitting}) => {
                    return (
                        <Row className={'justify-content-center transition'}>
                            <Col xs={12} className={'d-flex justify-content-center'}>
                                <img className={'login-logo'} src={Logo} alt="bizkey tech"/>
                            </Col>
                            <Col xs={12} className={'d-flex justify-content-center mb-3'}>
                                <h3 className={'text-title font-weight-bold primary-color-dark' +
                                    ' text-center'}>
                                    Log in to Your Account
                                </h3>
                            </Col>
                            <Col xs={12} className={'my-2 px-4'}>
                                <InputForm
                                    label={'Email'}
                                    name={'email'}
                                    ariaLabel={'email address of the user'}
                                    required={loginRequired.email}
                                    type={'email'}
                                />
                            </Col>
                            <Col xs={12} className={'my-2 px-4'}>
                                <InputForm
                                    label={'Password'}
                                    name={'password'}
                                    ariaLabel={'password of the user'}
                                    required={loginRequired.password}
                                    type={'password'}
                                />
                            </Col>
                            <Col xs={12} className={'mt-3 d-flex justify-content-center'}>
                                <Button
                                    variant={'outlined'}
                                    disabled={loading || submitting}
                                    type={'submit'}
                                    ariaLabel={'login form button'}
                                    className={'w-50 py-2 font-weight-bold transition'}
                                >
                                    {loading || submitting ? "loading..." : 'LOG IN'}
                                </Button>
                            </Col>
                            <Col xs={12} className={'d-flex justify-content-center'}>
                                <Fade in={message?.resultFlag === false} mountOnEnter unmountOnExit
                                      className={'my-2 rounded-lg font-weight-medium text-center red py-1 w-100' +
                                          ' d-flex' +
                                          ' justify-content-center' +
                                          ' text-sm'}>
                                    <p>{message?.message}</p>
                                </Fade>
                            </Col>
                            <Col xs={12} className={'mt-2 d-flex justify-content-center'}>
                                <Button
                                    disabled={loading || submitting}
                                    color={Colors.primaryColor}
                                    hoverColor={Colors.primaryColorLight}
                                    backgroundColor={Colors.transparent}
                                    hoverBackgroundColor={Colors.transparent}
                                    variant={'text'}
                                    ariaLabel={'forget password'}
                                    className={'w-100 font-weight-bold'}
                                    onClick={navigateToForgotPassword}
                                >
                                    Forgot password?
                                </Button>
                            </Col>
                        </Row>
                    );
                }}
            />
        </>
    );
}

export default Login;
