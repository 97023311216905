import React from "react";
import IconPlaceHolder from '../../../../../assets/images/icon-placeholder.svg';
import {CSSTransition, TransitionGroup} from "react-transition-group";
import Colors from "../../../../../assets/js/colors";


const BoardList = ({group, getKey, getItem}) => {

    return (
        <div className={'board-list'}>
            <div className={'header'}>
                <h4>
                    {group.status?.name ?? ''}
                </h4>
                {/*TODO: change when made available*/}
                <div className={'triangle'} style={{backgroundColor: group.status?.color ?? Colors.primaryColorDark}}>
                    <p className={'length'}>{group.list?.length}</p>
                </div>
            </div>
            <TransitionGroup className={'content'}>
                {
                    group?.list?.map(boardItem => (
                        <CSSTransition
                            key={getKey(boardItem)}
                            timeout={300}
                            classNames="css-item"
                        >
                            {getItem(boardItem)}
                        </CSSTransition>
                    ))
                }
            </TransitionGroup>
            <div className={'footer'}>
                {
                    group?.counts?.map(typeGroup => {
                        return (
                            <div key={typeGroup?.type?.id} className={'d-flex align-items-center ticket-type'}>
                                <img src={typeGroup?.type?.iconURL ?? IconPlaceHolder} alt="icon"/>
                                <p>{typeGroup?.length ?? 0}</p>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}

export default BoardList;
