import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import classnames from "classnames";
import {Fade} from "@material-ui/core";
import {ReactComponent as Play} from "../../../../../assets/images/video/play.svg";
import {ReactComponent as Pause} from "../../../../../assets/images/video/pause.svg";
import {ReactComponent as Unmute} from "../../../../../assets/images/video/unmute.svg";
import {ReactComponent as Mute} from "../../../../../assets/images/video/mute.svg";
import {ReactComponent as Fullscreen} from "../../../../../assets/images/video/full-screen.svg";
import {VideoTypes} from "../../../../../core/constants/enums";


const NativeVideoPlayer = ({id, src, title = 'Video Player', type = VideoTypes.small, className}, ref) => {
    const [state, setState] = useState({
        paused: true,
        duration: -1,
        currentTime: 0,
        muted: false,
        loaded: false,
    });
    /**@type {React.MutableRefObject<HTMLVideoElement>}*/
    const player = useRef();

    /**
     * Attaches the video player functionalities to the given ref so that the parent components can directly use them.
     */
    useImperativeHandle(ref, () => ({
        pause,
        play,
        mute,
        unmute,
        fullscreen,
    }), [player])

    /**
     * Listens to the changes in the paused state and with each change:
     * if the state is playing, then creates an interval that will track the progression of the time and video's
     * duration.
     * Removes the interval before the next call of this listener.
     */
    useEffect(() => {
        if (!player.current) return;
        const _player = player.current;
        _player.addEventListener('timeupdate', updatePlayerTime)
        _player.addEventListener('durationchange', updatePlayerTime)
        return () => {
            _player.removeEventListener('timeupdate', updatePlayerTime)
            _player.removeEventListener('durationchange', updatePlayerTime)
        }
    }, [player.current])

    /**
     * Updates the player duration and current time
     */
    const updatePlayerTime = () => {
        setState(prevState => ({
            ...prevState,
            currentTime: player.current.currentTime,
            duration: player.current?.duration ?? -1,
        }))
    }

    /**
     * Plays the current video of the player
     */
    const play = () => {
        player.current?.play();
        setState(prevState => ({
            ...prevState,
            progress: player.current?.currentTime ?? 0,
            paused: false,
        }))
    }

    /**
     * Pauses the current video of the player
     */
    const pause = () => {
        setState(prevState => ({...prevState, paused: true}));
        player.current?.pause();
    }

    /**
     * Mutes the video of the player
     */
    const mute = () => {
        setState(prevState => ({...prevState, muted: true}));
        if (player.current) {
            player.current.muted = true;
        }
    }

    /**
     * Un-Mutes the video of the player
     */
    const unmute = () => {
        setState(prevState => ({...prevState, muted: false}));
        if (player.current) {
            player.current.muted = false;
        }
    }

    /**
     * Makes the video of the player fullscreen
     */
    const fullscreen = () => {
        /**@type {Element}*/
        const videoElement = player.current;
        const requestFullScreen = videoElement.requestFullscreen || videoElement.mozRequestFullScreen || videoElement.webkitRequestFullScreen;
        if (requestFullScreen) {
            requestFullScreen.bind(videoElement)();
        }
    }

    /**
     * Updates the current time of the video player
     * @param value
     */
    const changeCurrentTime = (value) => {
        if (!player.current) return;
        player.current.currentTime += value;
    }

    /**
     * Either plays or pauses the video
     */
    const changePlayerState = (e) => {
        if (e) {
            if (e.detail !== 1) {
                if (state.paused) {
                    play();
                } else {
                    pause();
                }
            }
        }
        if (state.paused) {
            play();
        } else {
            pause();
        }
    }

    /**
     * Handles the key presses for this video component.
     * @param {KeyboardEvent} e
     */
    const onKeyUp = (e) => {
        switch (e.key) {
            case "ArrowLeft":
                changeCurrentTime(-5);
                break;
            case "ArrowRight":
                changeCurrentTime(5);
                break;
            case " ":
                e.preventDefault();
                e.stopPropagation();
                changePlayerState();
                break;
            default:
                break;
        }
    }

    /**
     * Sets the curernt time of the player with respect to the clicked portion of the video
     * @param {MouseEvent} e
     */
    const onProgressClicked = (e) => {
        const boundingRect = e.target.getBoundingClientRect();
        const percentage = (e.clientX - boundingRect.left) / boundingRect.width
        player.current.currentTime = player.current.duration * percentage;
    }

    return (
        <>
            <div className={classnames('video-container-layout', className, {
                'active': state.duration !== -1,
                [type]: true
            })}
                 onKeyUp={onKeyUp}
                 onKeyDown={e => e.stopPropagation() || e.preventDefault()}
                 tabIndex={-1}
            >
                <div className={'video-container'}>
                    <Fade in={state.duration === -1} unmountOnExit mountOnEnter>
                        <div className={'play-button-container'}>
                            <button className={'play-button'} onClick={play}>
                                <Play/>
                            </button>
                        </div>
                    </Fade>
                    <video onClick={changePlayerState}
                           onDoubleClick={fullscreen}
                           controls={false}
                           title={title}
                           ref={player}
                           id={id}
                           preload={'metadata'}
                    >
                        <source src={src} type={`video/${src ? src.split('.')[src.split('.')?.length - 1] : ''}`}/>
                        unable to play the current format
                    </video>
                </div>
                <Fade in={state.duration !== -1} unmountOnExit mountOnEnter>
                    <div className={classnames('video-controls', {[type]: true})}>
                        <div className={classnames('inner')}>
                            {
                                state.paused
                                    ? <Play className={'video-icon'} onClick={play}/>
                                    : <Pause className={'video-icon'} onClick={pause}/>
                            }
                            <progress
                                className={'progress'}
                                value={state.currentTime.toString()}
                                max={state.duration}
                                onClick={onProgressClicked}
                            />
                            {
                                state.muted
                                    ? <Unmute className={'video-icon'} onClick={unmute}/>
                                    : <Mute className={'video-icon'} onClick={mute}/>
                            }
                            <Fullscreen className={'video-icon'} onClick={fullscreen}/>
                        </div>

                    </div>
                </Fade>
            </div>
        </>
    )
}

export default forwardRef(NativeVideoPlayer);
