import React, {useState} from "react";
import {Popover, SvgIcon} from "@material-ui/core";
import {ReactComponent as NotificationIcon} from "../../../../../assets/images/topbar/notification-icon.svg";


const Notifications = () => {
    const [popover, setPopover] = useState();


    return (
        <>
            <SvgIcon onClick={(e) => setPopover(e.currentTarget)}
                     component={NotificationIcon}
                     className={'topbar-icon'}/>
            <Popover
                id={!!popover ? 'notifications' : undefined}
                elevation={2}
                className={'top-bar-popover'}
                open={!!popover}
                onClose={() => setPopover(null)}
                anchorReference={'anchorEl'}
                anchorEl={popover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
            </Popover>
        </>
    );
}

export default Notifications;
