import React, {useContext, useEffect, useState} from "react";
import useIsMounted from "../../../../hooks/use-is-mounted";
import {groupBy} from "../../../../../core/services/utils";
import Api from "../../../../../core/services/api_service";
import {Col, Row} from "reactstrap";
import BoardList from "../../../../components/app-specific/board/board-list";
import TryAgain from "../../../../components/app-specific/try-again";
import {ApplicationTokenContext} from "../../../../contexts";
import BoardListTaskCard from "../../../../components/app-specific/board/task-card";
import {LiveTaskStatusNames} from "../../../../../core/constants/enums";


/**
 * Given a list of tasks, groups them based on their status and for each status single, gets their counts object
 * that has been grouped based on their type.
 * @param statuses {any[]}
 * @param tasks {any[]}
 */
const groupTasks = (tasks, statuses) => {
    const groups = {};
    statuses.forEach(status => {
        groups[status.id] = [];
    })
    // group the tasks based on their status.
    const groupedTasksByStatus = Object.entries(groupBy(tasks, (e) => e.statusCode));
    for (let [statusId, list] of groupedTasksByStatus) {
        groups[statusId] = [...groups[statusId], ...list];
    }
    // group each group of tasks by their types.
    const groupedListOfTasks = Object.entries(groups).map(([statusId, list]) => {
        const countsGrouped = [];
        const groupedTasksByType = Object.entries(groupBy(list, (e) => e.iconURL));
        for (let [iconURL, typeList] of groupedTasksByType) {
            countsGrouped.push({
                type: {id: iconURL, iconURL: iconURL},
                length: typeList?.length ?? 0,
            });
        }
        return {
            status: statuses.find(e => e.id === statusId),
            list: list || [],
            counts: countsGrouped,
        };
    });
    // Sorts the list of tasks based on their statuses
    groupedListOfTasks.sort((a, b) => {
        if (a.status.name === LiveTaskStatusNames.toDo) return -1;
        if (a.status.name === LiveTaskStatusNames.inProgress && b.status.name !== LiveTaskStatusNames.toDo) return -1;
        if (a.status.name === LiveTaskStatusNames.toTest && b.status.name === LiveTaskStatusNames.done) return -1;
        if (b.status.name === LiveTaskStatusNames.done) return -1;
        return 1;
    });
    return groupedListOfTasks;
}

const LiveTasks = () => {
    const [loading, setLoading] = useState(true);
    const [ticketGroups, setTicketGroups] = useState([]);
    const [calledOnce, setCalledOnce] = useState(false);
    const applicationTokenContext = useContext(ApplicationTokenContext);
    const [applicationToken, setApplicationToken] = useState(applicationTokenContext.applicationToken);
    const isMounted = useIsMounted();


    /**
     * Listens for the changes in the applicationToken and with each change, fetches the data from api.
     */
    useEffect(() => {
        if (applicationTokenContext.applicationToken === applicationToken) {
            if (!calledOnce) {
                setCalledOnce(true);
                setLoading(true);
                getLiveTasks();
            }
            return;
        }
        setApplicationToken(applicationTokenContext.applicationToken);
        setCalledOnce(false);
        setLoading(true);
        getLiveTasks();
    }, [applicationTokenContext.applicationToken])


    /**
     * Fetches the list of live tasks for the current sprint of the users selected application and if the response
     * is successful, sets the single.
     */
    const getLiveTasks = () => {
        Api.getLiveTasks(applicationTokenContext.applicationToken).then((response) => {
            if (!isMounted()) return;
            if (response?.resultFlag) {
                setTicketGroups(groupTasks(response.data.tasks, response.data.statuses));
            } else {
                setTicketGroups([]);
            }
            setLoading(false);
        });
    }

    return (
        <>
            <Row className={''}>
                <Col xs={12}>
                    <p className={'text-lg font-weight-bold primary-color-dark my-2'}>
                        Live Tasks
                    </p>
                </Col>
                <div className={'board p-5'}>
                    {
                        loading
                            ? <div className={'board-lists loading-div'}>
                                {
                                    Array(4).fill(null).map((e, index) => (
                                        <div key={index} className={'board-list'}/>
                                    ))
                                }
                            </div>
                            : !ticketGroups?.length
                                ? <TryAgain
                                    onClick={() => getLiveTasks()}/>
                                : <div className={'board-lists'}>
                                    {
                                        ticketGroups?.map(group => (
                                            <BoardList
                                                key={group.status?.id}
                                                group={group}
                                                getKey={e => e.code}
                                                getItem={(boardItem) => <BoardListTaskCard item={boardItem}/>}
                                            />
                                        ))
                                    }
                                </div>
                    }
                </div>
            </Row>
        </>

    );
}

export default LiveTasks;
