import React, {forwardRef} from "react";
import VideoPlayer from "../../../../base/video-player";
import {VideoTypes} from "../../../../../../core/constants/enums";

const Video = ({video}, ref) => {
    const title = video.fileTitle ?? video.filename ?? 'Video';
    const url = `${video.baseURL}${video.filename}${video.extension}`;

    /**
     * Prevent the bubbling of the event form this video to not let the dialog close.
     * @param {Event} e
     */
    const preventBubbling = (e) => {
        e.stopPropagation();
        e.preventDefault();
    }

    return (
        <>
            <div
                className={'file'}
                style={{'--transition': 'all 0.2s ease-out'}}
                onClick={preventBubbling}>
                <VideoPlayer
                    title={title}
                    type={VideoTypes.large}
                    url={url}
                    ref={ref}
                />
            </div>
        </>
    );
}

export default forwardRef(Video);


