import React, {forwardRef, useRef} from "react";
import {correctUrl, createUUId} from "../../../../core/services/utils";
import YoutubeVideoPlayer from "./youtube";
import NativeVideoPlayer from "./native";

const VideoPlayer = ({id, url: urlProps, title, type}, ref) => {
    const idRef = useRef(id ?? createUUId(true));
    const url = correctUrl(urlProps)
    const isVideoFromYoutube = url?.startsWith('https://www.youtube') ?? false;

    return (
        <>
            {
                isVideoFromYoutube
                    ? <YoutubeVideoPlayer
                        key={idRef.current}
                        ref={ref}
                        id={`video-${idRef.current}`}
                        videoId={url?.split('/')[url?.split('/').length - 1]}
                        title={title}
                        type={type}
                    />
                    : <NativeVideoPlayer
                        key={idRef.current}
                        ref={ref}
                        id={`video-${idRef.current}`}
                        src={url}
                        title={title}
                        type={type}
                    />
            }
        </>
    )
}

export default forwardRef(VideoPlayer);
