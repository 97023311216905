import React from "react";
import {ReactComponent as CalendarIcon} from '../../../../../assets/images/calendar.svg';
import moment from "moment";
import IconPlaceHolder from "../../../../../assets/images/icon-placeholder.svg";
import useRouter from "../../../../hooks/use-router";
import {routeFunctions} from "../../../../routes";

const BoardListTicketCard = ({item}) => {
    const {history, params} = useRouter();

    /**
     * Navigates the user to the ticket single page
     * @param e
     */
    const navigateToSingle = (e) => {
        history.push(routeFunctions.private.withApplication.tickets.single(params.application, item.ticketId))
    }

    return (
        <div className={'card'} onClick={navigateToSingle}>
            <div className={'d-flex w-100 align-items-center justify-content-between mb-1'}>
                <p className={'title'}>
                    {item?.ticketCode ?? ''}
                </p>
                <div className={'d-flex align-items-center justify-content-end'}>
                    <CalendarIcon className={'icon fill-ternary-color-light mr-2'}/>
                    <p className={'text-xxs black'}>{moment(item?.submitDateTime).format('yyyy-MM-DD')}</p>
                </div>
            </div>
            <div className={'d-flex align-items-center ticket-type'}>
                <img src={item?.type?.iconURL ?? IconPlaceHolder} alt="icon"/>
                <p>{item?.type?.title ?? ''}</p>
            </div>
            <div className={'w-100 mt-3 text-wrap'}>
                <p className={'ternary-color text-xs font-weight-semi-bold'}>
                    {item?.service?.title ?? ''}
                </p>
            </div>
            <div className={'description'}>
                <p>
                    {
                        item?.question?.body?.length > 100
                            ? item?.question?.body?.substring(0, 100)?.concat('...')
                            : item?.question?.body ?? ''}
                </p>
            </div>
        </div>
    );
}

export default BoardListTicketCard;
