import React, {useState} from "react";
import {TimePicker as MuiTimePicker} from "@material-ui/pickers";
import {TimePicker as FormTimePicker} from "mui-rff";
import {SvgIcon} from "@material-ui/core";
import classnames from "classnames";
import {Label} from "reactstrap";
import {useDatePickerStyles} from "./_styles";
import {AccessAlarm} from "@material-ui/icons";

export const TimePicker = ({
                               disableToolbar = false,
                               disableFuture = true,
                               disabled,
                               placeHolder,
                               name,
                               label,
                               onChange,
                               value,
                               invalidDateMessage,
                               fullWidth = true,
                               searchForm = true,
                               ...props
                           }) => {
    const [open, setOpen] = useState(false);
    const classes = useDatePickerStyles({searchForm});

    /**
     * Closes the current dialog and calls the onChange callback to set the new date
     * @param date
     */
    const onDateAccept = (date) => {
        if (onChange) onChange(date);
    }

    return (
        <div className={classnames('d-flex flex-column align-items-start')}>
            <Label for={name} className={classnames(classes.label, {'w-100': fullWidth})}>
                <p className={classes.labelText}>{label}</p>
                <div className={'mx-1'}/>
                <SvgIcon className={'icon'}/>
            </Label>
            <div className={classnames('d-flex align-items-start date-picker', {'search-form': searchForm})}>
                <MuiTimePicker
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    onAccept={onDateAccept}
                    placeholder={placeHolder}
                    classes={{input: classes.input}}
                    disableToolbar={disableToolbar}
                    disabled={disabled}
                    variant={'inline'}
                    inputVariant={'outlined'}
                    name={name}
                    value={value}
                    onChange={onChange}
                    disableFuture={disableFuture}
                    invalidDateMessage={invalidDateMessage}
                    {...props}
                />
                <div className={'mx-1'}/>
                <AccessAlarm className={'icon time lg'} onClick={() => setOpen(prevState => !prevState)}/>
            </div>
        </div>
    );
}

export const TimePickerForm = ({
                                   disableToolbar = false,
                                   disableFuture = true,
                                   disabled,
                                   placeHolder,
                                   name,
                                   label,
                                   invalidDateMessage,
                                   required,
                                   fullWidth = true,
                                   searchForm = true,

                                   ...props
                               }) => {
    const [open, setOpen] = useState(false);
    const classes = useDatePickerStyles({searchForm});

    /**
     * Closes the current dialog and calls the onChange callback to set the new date
     * @param date
     */
    const onDateAccept = (date) => {
    }

    return (
        <div className={classnames('d-flex flex-column align-items-start')}>
            <Label for={name} className={classnames(classes.label, {'w-100': fullWidth})}>
                <p className={classes.labelText}>{label}</p>
                <div className={'mx-1'}/>
                <SvgIcon className={'icon'}/>
            </Label>
            <div className={classnames('d-flex align-items-start date-picker', {'search-form': searchForm})}>
                <FormTimePicker
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    InputProps={{classes: {input: classes.input}}}
                    onAccept={onDateAccept}
                    placeholder={placeHolder}
                    disableToolbar={disableToolbar}
                    disabled={disabled}
                    variant={'inline'}
                    inputVariant={'outlined'}
                    name={name}
                    required={!!required}
                    disableFuture={disableFuture}
                    invalidDateMessage={invalidDateMessage}
                    {...props}
                />
                <div className={'mx-1'}/>
                <AccessAlarm className={'icon time lg'} onClick={() => setOpen(prevState => !prevState)}/>
            </div>
        </div>
    );
}
