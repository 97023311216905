import React, {forwardRef} from "react";
import Colors from "../../../../assets/js/colors";
import Button from "./button";

const OutlinedButton = forwardRef(({
                                       disabled = false,
                                       color = Colors.orangeDark,
                                       hoverColor = Colors.white,
                                       backgroundColor = Colors.transparent,
                                       hoverBackgroundColor = Colors.orangeDark,
                                       borderColor = Colors.orangeDark,
                                       borderRadius = 7,
                                       borderWidth = 1,
                                       variant = 'outlined',
                                       type = 'button',
                                       onClick,
                                       name,
                                       children,
                                       className,
                                       disableRipple = false,
                                       hoverChildren,
                                       ariaLabel,
                                       ...props
                                   }, ref) => {
    return (
        <Button
            ref={ref}
            color={color}
            hoverColor={hoverColor}
            backgroundColor={backgroundColor}
            hoverBackgroundColor={hoverBackgroundColor}
            borderColor={borderColor}
            borderRadius={borderRadius}
            borderWidth={borderWidth}
            variant={variant}
            type={type}
            disabled={disabled}
            onClick={onClick}
            name={name}
            children={children}
            className={className}
            disableRipple={disableRipple}
            hoverChildren={hoverChildren}
            ariaLabel={ariaLabel}
            {...props}
        >
            {children}
        </Button>
    );
});

export default OutlinedButton;
