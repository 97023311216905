import {makeStyles} from "@material-ui/core/styles";
import Colors from "../../../../assets/js/colors";

export const useDatePickerStyles = makeStyles((theme) => ({
    input: {
        padding: '16px 16px',
    },
    label: {
        display: 'flex',
        justifyContent: 'start',
        fontSize: 14,
        fontWeight: 700,
        color: Colors.primaryColorDark,
    },
    labelText: {
        width: props => props.searchForm ? '100%' : '100%',
    }
}))
