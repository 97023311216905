import React from "react";
import useRouter from "../../../../../hooks/use-router";
import TicketDefinitionServices from "./services";
import TicketDefinitionTicketTypes from "./types";
import TicketDefinitionQuestions from "./questions";
import TicketDefinitionHint from "./hint";

const TicketDefinition = ({services, types, questions, loading, fetchAgain}) => {
    const {params: {serviceId, typeId, questionId, hintId}} = useRouter();

    /**
     * Determines the correct route to show depending on the provided parameters from the url
     *
     * The possible routes are:
     * 1. services => if there is not serviceId in params
     * 2. types => if there is a serviceId and no typeId in params
     * 3. questions => if there is a serviceId, typeId and no hintId
     * 4. hint => if there is a serviceId, typeId, questionId, and hintId
     * @return {JSX.Element}
     */
    const determineRoute = () => {
        if (!serviceId) {
            return <TicketDefinitionServices
                loading={loading}
                services={services}
                fetchAgain={fetchAgain}
            />
        }
        if (!typeId) {
            return <TicketDefinitionTicketTypes
                loading={loading}
                ticketTypes={types}
                fetchAgain={fetchAgain}
            />
        }
        if (!hintId) {
            return <TicketDefinitionQuestions
                loading={loading}
                questions={questions}
                fetchAgain={fetchAgain}
            />
        }
        return <TicketDefinitionHint
            loading={loading}
            hint={questions?.find(e => e.id === parseInt(questionId) && e.hint)?.hint}
            fetchAgain={fetchAgain}
        />
    }

    return (
        <>
            {
                determineRoute()
            }
        </>
    );
}

export default TicketDefinition;

