import useRouter from "../../../hooks/use-router";
import React, {useState} from "react";
import useIsMounted from "../../../hooks/use-is-mounted";
import Api from "../../../../core/services/api_service";
import {routeFunctions} from "../../../routes";
import {Col, Row} from "reactstrap";
import * as Yup from "yup";
import ValidateMessages from "../../../../core/constants/texts/vallidate_messages";
import {makeRequired, makeValidate} from "mui-rff";
import Form from "../../../components/base/form";
import Logo from "../../../../assets/logos/bizkeyTech.png";
import {InputForm} from "../../../components/base/input/input";
import {Fade} from "@material-ui/core";
import Button from "../../../components/base/button/button";
import Colors from "../../../../assets/js/colors";
import classnames from "classnames";


/**
 * The Schema of the forgot password Form
 */
const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email(ValidateMessages.incorrectType('email')).nullable()
})

const ForgotPassword = () => {
    const {history, params} = useRouter();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState();
    const validate = makeValidate(forgotPasswordSchema);
    const required = makeRequired(forgotPasswordSchema);
    const isMounted = useIsMounted();

    /**
     * Sends a forgot password request to the given email address if it exists in the database.
     * @param email {string}
     */
    const sendForgotPasswordRequest = async ({email}) => {
        setLoading(true);
        const response = await Api.requestForgetPassword(email);
        if (!isMounted()) return;
        setLoading(false);
        return {resultFlag: response?.resultFlag ?? false, message: response?.message ?? 'Request Failed'};
    }


    /**
     * Submits the Forgot password request form and then resets the form
     * @param values {{email: string}}
     * @param form {any}
     */
    const submitForgotPasswordForm = async (values, form) => {
        if (!values.email) {
            return {email: ValidateMessages.required}
        }
        const sendForgotPasswordResponse = await sendForgotPasswordRequest(values);
        setMessage(sendForgotPasswordResponse);
        if (!sendForgotPasswordResponse.resultFlag) {
            form.reset();
        }
    }


    /**
     * Navigates the user back to the login page.
     */
    const navigateToLogin = () => {
        history.replace(routeFunctions.auth.login(params?.redirectUrl))
    }


    return (
        <>
            <Form
                className={'container justify-content-center align-items-center'}
                validate={validate}
                onSubmit={submitForgotPasswordForm}
                render={({submitting}) => {
                    return (
                        <Row className={'justify-content-center transition'}>
                            <Col xs={12} className={'d-flex justify-content-center'}>
                                <img className={'login-logo'} src={Logo} alt="bizkey tech"/>
                            </Col>
                            <Col xs={12} className={'d-flex justify-content-center mb-3'}>
                                <h3 className={'text-xl font-weight-bold primary-color-dark' +
                                    ' text-center'}>
                                    Send Reset Password Link to Your Email
                                </h3>
                            </Col>
                            <Col xs={12} className={'my-2 px-4'}>
                                <InputForm
                                    label={'Email'}
                                    name={'email'}
                                    ariaLabel={'email address for reset password'}
                                    required={required.email}
                                    type={'email'}
                                />
                            </Col>

                            <Col xs={12} className={'mt-3 d-flex justify-content-center'}>
                                <Button
                                    disabled={loading || submitting}
                                    type={'submit'}
                                    ariaLabel={'send reset password link'}
                                    className={'w-50 py-2 font-weight-bold transition'}
                                >
                                    {loading || submitting ? "loading..." : 'Submit'}
                                </Button>
                            </Col>
                            <Col xs={12} className={'d-flex justify-content-center'}>
                                <Fade in={message?.resultFlag === true || message?.resultFlag === false}
                                      mountOnEnter
                                      unmountOnExit
                                      className={classnames('my-2 rounded-lg font-weight-medium',
                                          'text-center py-1 w-100 d-flex',
                                          'justify-content-center text-sm',
                                          {
                                              'red': message?.resultFlag === false,
                                              'green': message?.resultFlag === true,
                                          }
                                      )}>
                                    <p>{message?.message}</p>
                                </Fade>
                            </Col>
                            <Col xs={12} className={'mt-2 d-flex justify-content-center'}>
                                <Button
                                    disabled={loading || submitting}
                                    color={Colors.primaryColor}
                                    hoverColor={Colors.primaryColorLight}
                                    backgroundColor={Colors.transparent}
                                    hoverBackgroundColor={Colors.transparent}
                                    variant={'text'}
                                    ariaLabel={'back to login'}
                                    className={'w-75 font-weight-bold'}
                                    onClick={navigateToLogin}
                                >
                                    Back to Login
                                </Button>
                            </Col>
                        </Row>
                    );
                }}
            />
        </>
    );
}

export default ForgotPassword;
