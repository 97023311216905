import {Redirect, Route, Switch} from "react-router";
import routes from "../../../../routes";
import React from "react";
import TicketsList from "./list";
import Ticket from "./single";

const Tickets = () => {
    return (
        <>
            <Switch>
                <Route path={routes.private.withApplication.tickets.base} exact>
                    <TicketsList/>
                </Route>
                <Route path={routes.private.withApplication.tickets.single} exact>
                    <Ticket/>
                </Route>
                <Redirect to={routes.error.notFound}/>
            </Switch>
        </>
    );
}

export default Tickets;

