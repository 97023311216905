import useRouter from "../../../hooks/use-router";
import React, {useEffect, useState} from "react";
import useIsMounted from "../../../hooks/use-is-mounted";
import Api from "../../../../core/services/api_service";
import {routeFunctions} from "../../../routes";
import {Col, Row} from "reactstrap";
import * as Yup from "yup";
import {makeRequired, makeValidate} from "mui-rff";
import Form from "../../../components/base/form";
import Logo from "../../../../assets/logos/bizkeyTech.png";
import {InputForm} from "../../../components/base/input/input";
import {Fade} from "@material-ui/core";
import Button from "../../../components/base/button/button";
import classnames from "classnames";

/**
 * The Schema of the forgot password Form
 */
const resetPasswordSchema = Yup.object().shape({
    password: Yup.string().nullable(),
    rePassword: Yup.string().oneOf([Yup.ref('password'), null], 'passwords must match')
})

const ResetPassword = () => {
    const {history, params} = useRouter();
    const [loading, setLoading] = useState(true);
    const [paramsValid, setParamsValid] = useState(true);
    const [message, setMessage] = useState();
    const resetPasswordValidate = makeValidate(resetPasswordSchema);
    const resetPasswordRequired = makeRequired(resetPasswordSchema);
    const isMounted = useIsMounted();

    /**
     * As soon as the component mounts, checks if the params email and token are valid or not.
     */
    useEffect(() => {
        validateResetPasswordParams();
    }, [])


    /**
     * Validates the email and token from the server to see if the reset password link has been expired or not.
     */
    const validateResetPasswordParams = () => {
        setLoading(true);
        const forApi = {
            email: params?.email,
            token: params?.token,
        };
        Api.checkForgetPasswordLinkParams(forApi).then((response) => {
            if (!isMounted()) return;
            if (!response?.resultFlag) {
                setParamsValid(false);
                setMessage(response?.message);
            }
            setLoading(false);
        });
    }

    /**
     * Resets user's password in the system and returns the response data message and resultFlag from api
     * @param requestData {{email: string, token:string, newPassword: string}}
     */
    const resetUsersPassword = async (requestData) => {
        setLoading(true);
        const response = await Api.resetPassword(requestData);
        if (!isMounted()) return;
        setLoading(false);
        if (response?.resultFlag) {
            history.replace(routeFunctions.auth.login());
        }
        return {resultFlag: response?.resultFlag, message: response?.message};
    }


    /**
     * Submits the Reset password request form and then resets the form
     * @param values {{password: string, rePassword: string}}
     * @param form {any}
     */
    const submitResetPasswordForm = async (values, form) => {
        const forApi = {
            email: params.email,
            token: params.token,
            newPassword: values.password,
        };
        const resetPasswordResponse = await resetUsersPassword(forApi);
        setMessage(resetPasswordResponse);
        if (!resetPasswordResponse?.resultFlag) {
            form.reset();
        }
    }

    /**
     * Navigates the user into forgot password section
     */
    const navigateToForgotPassword = () => {
        history.replace(routeFunctions.auth.forgotPassword());
    }


    return (
        <>
            <Form
                className={'container justify-content-center align-items-center'}
                validate={resetPasswordValidate}
                onSubmit={submitResetPasswordForm}
                render={({values, submitting}) => {
                    return (
                        <Row className={'justify-content-center transition'}>
                            <Col xs={12} className={'d-flex justify-content-center'}>
                                <img className={'login-logo'} src={Logo} alt="bizkey tech"/>
                            </Col>
                            {paramsValid
                                ? <>
                                    <Col xs={12} className={'d-flex justify-content-center mb-3'}>
                                        <h3 className={'text-xl font-weight-bold primary-color-dark' +
                                            ' text-center'}>
                                            Enter your new password for your account.
                                        </h3>
                                    </Col>
                                    <Col xs={12} className={'my-2 px-4'}>
                                        <InputForm
                                            label={'Password'}
                                            name={'password'}
                                            ariaLabel={'password input'}
                                            required={resetPasswordRequired.password}
                                            type={'password'}
                                        />
                                    </Col>
                                    <Col xs={12} className={'my-2 px-4'}>
                                        <InputForm
                                            label={'Password Confirmation'}
                                            name={'rePassword'}
                                            ariaLabel={'re-enter password input'}
                                            required={resetPasswordRequired.rePassword}
                                            type={'password'}
                                        />
                                    </Col>
                                    <Col xs={12} className={'mt-3 d-flex justify-content-center'}>
                                        <Button
                                            disabled={loading || submitting || !values.password || !values.rePassword || (values.password !== values.rePassword)}
                                            type={'submit'}
                                            ariaLabel={'send reset password link'}
                                            className={'w-50 py-2 font-weight-bold transition'}
                                        >
                                            {loading || submitting ? "loading..." : 'Submit'}
                                        </Button>
                                    </Col>
                                    <Col xs={12} className={'d-flex justify-content-center'}>
                                        <Fade in={message?.resultFlag === true || message?.resultFlag === false}
                                              mountOnEnter
                                              unmountOnExit
                                              className={classnames('my-2 rounded-lg font-weight-medium',
                                                  'text-center py-1 w-100 d-flex',
                                                  'justify-content-center text-sm',
                                                  {
                                                      'red': message?.resultFlag === false,
                                                      'green': message?.resultFlag === true,
                                                  }
                                              )}>
                                            <p>{message?.message}</p>
                                        </Fade>
                                    </Col>
                                </>
                                : <>
                                    <Col xs={12} className={'d-flex justify-content-center mb-3'}>
                                        <h3 className={'text-xl font-weight-bold primary-color-dark' +
                                            ' text-center'}>
                                            Your Link has been expired. You may need to request a reset password again.
                                        </h3>
                                    </Col>
                                    <Col xs={12} className={'mt-3 d-flex justify-content-center'}>
                                        <Button
                                            disabled={loading || submitting}
                                            onClick={navigateToForgotPassword}
                                            ariaLabel={'back to forgot password'}
                                            className={'w-100 py-2 font-weight-bold transition'}
                                        >
                                            {loading || submitting ? "loading..." : 'Enter your email for a new link'}
                                        </Button>
                                    </Col>
                                </>

                            }
                        </Row>
                    );
                }}
            />
        </>
    );
}

export default ResetPassword;
