import React, {useState} from "react";
import BaseSlider from './base/base';
import {useSliderStyles} from "./_styles";
import Colors from "../../../../assets/js/colors";
import Label from "./base/label";
import Thumb from "./base/thumb";


const SliderForm = ({
                        form,
                        trackHeight = 9,
                        markHeight = trackHeight * 2,
                        railHeight = trackHeight,
                        thumbHeight = 20,
                        trackColor = Colors.ternaryColorLight,
                        railColor = Colors.ternaryColorLight,
                        markColor = Colors.secondaryColor,
                        activeMarkColor = 'currentColor',
                        orientation = 'horizontal',
                        track = 'normal',
                        valueLabelDisplay = 'on',
                        step = 1,
                        disabled,
                        max,
                        min,
                        name,
                        marks,
                        getValue,
                        getSaveData,
                        defaultValue,
                        ...props
                    }) => {
    const classes = useSliderStyles({
        trackHeight,
        railHeight,
        trackColor,
        railColor,
        thumbHeight,
        markColor,
        activeMarkColor,
        markHeight,
    });
    const [value, setValue] = useState(defaultValue <= marks?.length && defaultValue > 0 ? marks[defaultValue - 1] : marks?.length ? marks[0] : undefined);

    /**
     * On Every change of the values, sets the internal state
     * @param e {Event}
     * @param value {number[]}
     */
    const onChange = async (e, value) => {
        if (disabled) return;
        const newVal = marks.find(mark => mark.value === value);
        if (newVal === undefined || newVal === null) return;
        setValue(newVal);
    }


    /**
     * On Every Stop of the Change, sets the form values
     * @param e {Event}
     * @param value {number[]}
     */
    const onChangeCommit = (e, value) => {
        if (disabled) return;
        if (!form) return;
        form.change(name, getSaveData(marks.find(mark => mark.value === value)));
    }


    return (
        <BaseSlider
            classes={{
                root: classes.root,
                thumb: classes.thumb,
                active: classes.active,
                valueLabel: classes.valueLabel,
                track: classes.track,
                rail: classes.rail,
                mark: classes.mark,
                markActive: classes.markActive
            }}
            className={'my-3'}
            valueLabelDisplay={valueLabelDisplay}
            defaultValue={defaultValue}
            disabled={disabled}
            max={max}
            min={min}
            name={name}
            step={step}
            marks={marks}
            ValueLabelComponent={Label}
            ThumbComponent={Thumb}
            onChangeCommitted={onChangeCommit}
            onChange={onChange}
            orientation={orientation}
            track={track}
            value={getValue(value) ?? defaultValue}
            {...props}
        />
    );
}

export default SliderForm;
