import {Redirect, Route, Switch} from "react-router";
import routes from "../../../routes";
import React, {useEffect, useState} from "react";
import Dashboard from "./dashboard";
import LoadingApplications from "./loading-applications";
import Tickets from "./tickets";
import CacheService from "../../../../core/services/cache_service";
import {ApplicationTokenContext} from "../../../contexts";
import LocalStorageService from "../../../../core/services/local_storage_service";
import LiveTasks from "./live-tasks";
import AnnouncementsList from "./announcements";
import Versions from "./versions";
import Task from "./task";
import NewTicket from "./new-ticket";

const PrivateViewsWithApplication = () => {
    const [applicationToken, setApplicationToken] = useState(() => CacheService.getApplicationToken());

    /**
     * As soon as the component mounts, adds an event listener to the local storage and remove it as soon as the
     * component unmounts.
     */
    useEffect(() => {
        window.addEventListener('storage', listenForChangesInSelectedApplication);
        listenForChangesInSelectedApplication();
        return () => window.removeEventListener('storage', listenForChangesInSelectedApplication)
    }, [])

    /**
     * Listens for the changes in the local storage and with each change, if an application is selected, updates the
     * value of the application token context to update its dependant views
     */
    const listenForChangesInSelectedApplication = () => {
        const applicationName = LocalStorageService.get(LocalStorageService.keys.selectedApplicationName);
        if (!applicationName) return;
        const res = CacheService.getApplicationToken();
        setApplicationToken(res);
    }

    return (
        <ApplicationTokenContext.Provider value={{applicationToken}}>
            <Switch>
                <Route path={routes.private.withApplication.loading} exact>
                    <LoadingApplications/>
                </Route>
                <Route path={routes.private.withApplication.dashboard} exact>
                    <Dashboard/>
                </Route>
                <Route path={routes.private.withApplication.announcements.base} exact>
                    <AnnouncementsList/>
                </Route>
                <Route path={routes.private.withApplication.liveTasks} exact>
                    <LiveTasks/>
                </Route>
                <Route path={routes.private.withApplication.task} exact>
                    <Task/>
                </Route>
                <Route
                    path={[routes.private.withApplication.newTicket.ticketDefinition, routes.private.withApplication.newTicket.submitForm,]}
                    exact>
                    <NewTicket/>
                </Route>
                <Route
                    path={[routes.private.withApplication.tickets.base, routes.private.withApplication.tickets.single]}
                    exact>
                    <Tickets/>
                </Route>
                <Route path={routes.private.withApplication.versions} exact>
                    <Versions/>
                </Route>
                <Redirect to={routes.error.notFound}/>
            </Switch>
        </ApplicationTokenContext.Provider>
    );
}

export default PrivateViewsWithApplication;

