import {configureStore as CStore, getDefaultMiddleware} from '@reduxjs/toolkit';
import reducer from "./entities/reducer";

/**
 * Configures the redux store and makes it ready for injection in react.
 */
const configureStore = () => {
    return CStore({
        reducer,
        middleware: [
            ...getDefaultMiddleware(),
            //TODO: place for custom middle wares
        ],
    });
}

/**
 * The Singleton State of the redux store
 */
const store = configureStore();
export default store;
