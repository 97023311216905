import {SvgIcon} from "@material-ui/core";
import {ReactComponent as Icon} from '../../../../../assets/images/radio-icon-checked.svg';

/* The minimum opacity for the tracker*/
const minOpacity = 0.3;
/* The maximum opacity for the tracker*/
const maxOpacity = 1;

const Thumb = (props) => {

    /**
     * Calculates the opacity of the thumb component given the current position of the thumb element.
     * @return {number}
     */
    const getOpacity = () => {
        if (props.disabled) return 1;
        const minValue = props['aria-valuemin'];
        const maxValue = props['aria-valuemax'];
        const currentValue = isNaN(props['aria-valuenow'] ?? maxValue) ? maxValue : props['aria-valuenow'] ?? maxValue;
        return ((currentValue) * ((maxOpacity - minOpacity) / Math.abs(maxValue - minValue))) + minOpacity;
    }

    return (
        <span {...props}>
            <SvgIcon component={Icon} className={'slider-icon'} style={{opacity: getOpacity()}}/>
        </span>
    )
}

export default Thumb;
