import {makeStyles} from "@material-ui/core/styles";
import Colors from "../../../../assets/js/colors";

export const useInputStyles = makeStyles((theme) => ({
    formControl: {
        flex: 1,
    },
    input: {
        padding: props => props.hasLabel ? '18.5px 16px' : '16px 16px',
    },
    innerInput: {
        borderRadius: 7,
        '&:hover': {
            backgroundColor: Colors.transparent,
        },
        '&:focus': {
            backgroundColor: Colors.transparent,
        },
    },
    label: {
        fontSize: 14,
        fontWeight: 700,
        color: Colors.primaryColorDark,
    },
}))
