import React, {forwardRef, useMemo, useRef, useState} from "react";
import LazyImage from "../../../../app-specific/lazy-lmage";
import PrismaZoom from "react-prismazoom";
import IconButton from "../../../../base/button/icon-button";
import Colors from "../../../../../../assets/js/colors";
import {ZoomIn, ZoomOut} from "@material-ui/icons";
import {formatMoney} from "../../../../../../core/services/utils";


const Image = ({image}, ref) => {
    const [zoom, setZoom] = useState(1);
    const [transition, setTransition] = useState('none');
    const timerRef = useRef(undefined);

    const url = `${image.baseURL}${image.filename}${image.extension}`;

    const Image = useMemo(() => <LazyImage
        src={url}
        alt={image.alt}
        onClick={e => e.preventDefault() || e.stopPropagation()}
    />, [image])

    /**
     * Changes the zoom scale of this image.
     * @param {Event} e
     * @param {number} modifier the modifier to specify the direction and how much to change the zoom
     */
    const changeZoom = (e, modifier) => {
        e.stopPropagation();
        e.preventDefault();
        if (transition === 'none') {
            setTransition('transform 0.25s ease-out 0s')
        }
        if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = undefined;
        }
        timerRef.current = setTimeout(() => {
            setTransition('none')
        }, 250);
        if (modifier > 0) {
            ref.current?.zoomIn(modifier);
        } else {
            ref.current?.zoomOut(Math.abs(modifier));
        }
    }

    return (
        <div className={'file'} style={{'--transition': transition}}>
            <PrismaZoom ref={ref} onZoomChange={setZoom} minZoom={1} maxZoom={8}>
                {Image}
            </PrismaZoom>
            <div className={'controller'}>
                <IconButton
                    color={Colors.primaryColorDark}
                    hoverColor={Colors.secondaryColorDark}
                    onClick={(e) => changeZoom(e, -0.1)}>
                    <ZoomOut className={'icon'}/>
                </IconButton>
                <p>
                    {`${formatMoney(zoom * 100).slice(1, formatMoney(zoom * 100).length - 3)}%`}
                </p>
                <IconButton
                    color={Colors.primaryColorDark}
                    hoverColor={Colors.secondaryColorDark}
                    onClick={(e) => changeZoom(e, 0.1)}>
                    <ZoomIn className={'icon'}/>
                </IconButton>
            </div>
        </div>
    );
}

export default forwardRef(Image);
