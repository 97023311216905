import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import reportWebVitals from './report-web-vitals';
import setUpJQueryExtensions from "./core/services/jquery_extensions";

//    ***** CSS IMPORTS *******
import './assets/css/_index.scss';
import "react-toastify/dist/ReactToastify.css";

setUpJQueryExtensions();
ReactDOM.render(
    <App/>,
    document.getElementById('root')
);
reportWebVitals();
