import * as React from 'react';
import classnames from 'classnames';
import {withStyles} from "@material-ui/core";
import {baseValueLabelStyles} from "./_styles";


const Label = ({children, className, open, valueLabelDisplay, classes}) => {

    /**
     * The Label of the Slider. Keyboard Date Picker of Form and the Thumb
     * @type {JSX.Element}
     */
    const label = (
        <span className={classnames(classes.offset, className)}/>
    );

    /**
     * The Props of the Thumb
     * @type {{className: string, style}}
     */
    const thumbProps = {
        className: classnames(
            children.props.className,
            {
                [classes.open]: open || valueLabelDisplay === 'on',
            },
            classes.thumb,
        ),
        style: children.props.style,
    }

    return React.cloneElement(children, thumbProps, label);
}

export default withStyles(baseValueLabelStyles, {name: 'PrivateValueLabel'})(Label);
