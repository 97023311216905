import React, {useContext, useEffect, useState} from "react";
import Api from "../../../../../../core/services/api_service";
import useRouter from "../../../../../hooks/use-router";
import {ApplicationTokenContext} from "../../../../../contexts";
import useIsMounted from "../../../../../hooks/use-is-mounted";
import {Col, Row} from "reactstrap";
import classnames from "classnames";
import SliderForm from "../../../../../components/base/slider/slider";
import OutlinedButton from "../../../../../components/base/button/outlined-button";
import {routeFunctions} from "../../../../../routes";
import {ReactComponent as CalendarIcon} from "../../../../../../assets/images/calendar.svg";
import moment from "moment";
import {SupportedFileTypes, TicketStatuses} from "../../../../../../core/constants/enums";
import Colors from "../../../../../../assets/js/colors";
import TryAgain from "../../../../../components/app-specific/try-again";
import AddCommentToTicketDialog from "../../../../../components/dialogs/add-comment-to-ticket-dialog";
import TicketCommentCard from "../../../../../components/app-specific/ticket-comment-card";
import SubmittedFile from "../../../../../components/app-specific/submitted-file";
import FilesPreviewDialog from "../../../../../components/dialogs/files-preview-dialog";
import {getSupportedFileType} from "../../../../../../core/services/utils";

const initPreviewFiles = {open: false, files: []};

const Ticket = () => {
    const {params, history, location} = useRouter();
    const [ticket, setTicket] = useState();
    const [loading, setLoading] = useState(true);
    const [accepting, setAccepting] = useState(false);
    const [openNewMessage, setOpenNewMessage] = useState(false);
    const applicationTokenContext = useContext(ApplicationTokenContext);
    const [applicationToken, setApplicationToken] = useState(applicationTokenContext.applicationToken);
    const [previewFiles, setPreviewFiles] = useState(initPreviewFiles);
    const isMounted = useIsMounted();

    /**
     * Listens for the changes in the applicationToken and the url parameters and with each chnage
     * Fetches the ticket information from the server
     */
    useEffect(() => {
        if (!applicationTokenContext.applicationToken) return;
        if (applicationToken !== applicationTokenContext.applicationToken) {
            setApplicationToken(applicationTokenContext.applicationToken);
        }
        getTicketInformation();
    }, [location?.pathname, applicationTokenContext.applicationToken])

    /**
     * Fetches the information of the ticket based on the selected applicationToken and the provided ticketId. If
     * the result of the api is successful, sets the ticket.
     */
    const getTicketInformation = () => {
        setLoading(true);
        Api.getTicketById(applicationTokenContext.applicationToken, params.id).then((response) => {
            if (!isMounted()) return;
            if (response?.resultFlag) {
                setTicket({
                    ...response.data,
                    ticketPriorities: response.data?.ticketPriorities?.reverse() ?? [],
                    attachments: response?.data?.attachments?.map(e => ({
                        ...e,
                        type: getSupportedFileType(e.extension),
                    }))
                });
            } else {
                setTicket(undefined);
            }
            setLoading(false);
        });
    }

    /**
     * Accepts admins' response regarding the new ticket
     * @param isApproved
     */
    const acceptAdminsResponse = (isApproved) => {
        setAccepting(true);
        const forApi = {
            ticketId: parseInt(params.id),
            applicationToken: applicationTokenContext.applicationToken,
            isApproved,
        }
        Api.approveTicketByAdmin(forApi).then((response) => {
            if (!isMounted()) return;
            if (response?.resultFlag) {
                setTicket(prevState => ({
                    ...prevState,
                    status: {id: response.data?.updatedStatusId, title: response.data?.updatedStatus},
                }))
            }
            setAccepting(false);
        })
    }

    /**
     * Navigates the user to the page of the linked task for the current ticket.
     */
    const navigateToTask = () => {
        history.push(routeFunctions.private.withApplication.task(params.application, ticket?.taskCode));
    }

    /**
     * Adds the newly created comment to the list of comments of the ticket.
     * @param comment {any}
     */
    const addNewlyCreatedComment = (comment) => {
        setTicket(prevState => ({...prevState, comments: [...prevState.comments, comment]}))
    }

    /**
     * Opens the preview dialog for all the files that
     * @param {any[]} files
     * @param {number} index
     */
    const openFilePreviewDialog = (files, index) => {
        setPreviewFiles({
            open: true,
            files: files
                ?.map((e, i) => index === i ?
                    {...e, first: true}
                    : e)
                ?.filter(e => e?.type !== SupportedFileTypes.unknown)
        })
    }

    return (
        <>
            <Row className={classnames('ticket', {'loading-div': loading})}>
                {
                    !ticket && !loading
                        ? <TryAgain
                            onClick={getTicketInformation}
                            buttonText={'Load Again'}
                            text={'The ticket' +
                                ' information failed to load. please contact our technical support for further information'}
                        />
                        : (
                            <>
                                {
                                    ticket?.isAdmin && ticket?.status?.id === TicketStatuses.submitted.id &&
                                    <Col xs={12} className={'mb-4'}>
                                        <div className={'admin-confirmation'}>
                                            <div className={'title'}>
                                                Confirmation
                                            </div>
                                            <div className={'message'}>
                                                Do you want to accept this ticket?
                                            </div>
                                            <div className={'d-flex justify-content-end'}>
                                                <OutlinedButton
                                                    disabled={accepting}
                                                    backgroundColor={Colors.white}
                                                    onClick={() => acceptAdminsResponse(true)}
                                                    className={'mr-1 text-xs'}
                                                >
                                                    {accepting ? 'Loading...' : 'YES'}
                                                </OutlinedButton>
                                                <OutlinedButton
                                                    disabled={accepting}
                                                    backgroundColor={Colors.white}
                                                    onClick={() => acceptAdminsResponse(false)}
                                                    className={'ml-1 text-xs'}
                                                >
                                                    {accepting ? 'Loading...' : 'NO'}
                                                </OutlinedButton>
                                            </div>
                                        </div>
                                    </Col>
                                }
                                <Col xs={12} className={'mb-4'}>
                                    <p className={'title'}>
                                        Ticket Information
                                    </p>
                                </Col>
                                <Col xs={12} className={'pl-3 mb-4'}>
                                    {
                                        loading
                                            ? <>
                                                <Col xs={12}>
                                                    <div className={'title'}>
                                                        <div/>
                                                    </div>
                                                </Col>
                                                <Col xs={12} className={'mt-3'}>
                                                    <div className={'slider w-100 w-md-75 w-lg-50'}>
                                                        <div/>
                                                    </div>
                                                </Col>
                                            </>
                                            : <>
                                                <Col xs={12}>
                                                    <p className={'mini-title'}>
                                                        Priority
                                                    </p>
                                                </Col>
                                                <Col xs={12} className={'px-5 mt-3'}>
                                                    <div className={'w-100 w-md-75 w-lg-50'}>
                                                        <SliderForm
                                                            name={'priority'}
                                                            getValue={(priority) => priority?.value}
                                                            defaultValue={ticket.ticketPriorities?.indexOf(ticket.ticketPriorities?.find(e => e.id === ticket.priority?.id)) + 1}
                                                            min={1}
                                                            disabled
                                                            max={ticket?.ticketPriorities?.length}
                                                            marks={ticket?.ticketPriorities?.map((e, index) => ({
                                                                value: index + 1,
                                                                label: e.title,
                                                                id: e.id,
                                                            }))}
                                                        />
                                                    </div>
                                                </Col>
                                            </>
                                    }
                                </Col>
                                <Col xs={12} md={6} className={'pl-3'}>
                                    {
                                        loading
                                            ? <>
                                                {
                                                    Array(5).fill(null).map((e, index) => (
                                                        <div key={index} className={''}>
                                                            <Col xs={12} className={'mt-4'}>
                                                                <div className={'title'}>
                                                                    <div/>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} className={'mt-3'}>
                                                                <div className={'input'}>
                                                                    <div/>
                                                                </div>
                                                            </Col>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                            : <>
                                                {
                                                    ticket?.submittedForm?.map((question, index) => (
                                                        <div key={index}>
                                                            <Col xs={12}>
                                                                <div className={'mini-title'}>
                                                                    {question?.questionContent ?? ''}
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} className={'mt-3 w-100 mb-4'}>
                                                                <div className={'ticket-card'}>
                                                                    {question?.value ?? ''}
                                                                </div>
                                                            </Col>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                    }
                                </Col>
                                <Col xs={12} md={6} className={'pl-3'}>
                                    {
                                        loading
                                            ? <>
                                                <div className={classnames('ticket-card w-100', {'loading-div': loading})}>
                                                    <div/>
                                                </div>
                                                <div
                                                    className={classnames('ticket-card w-100 comments', {'loading-div': loading})}>
                                                    <div/>
                                                </div>
                                            </>
                                            : <>
                                                <div className={'ticket-card w-100'}>
                                                    <div className={'d-flex justify-content-between'}>
                                                        <Col>
                                                            <p className={'code'}>
                                                                {ticket?.ticketCode ?? ''}
                                                            </p>
                                                            <p className={'type'}>
                                                                {ticket?.type?.title ?? ''}
                                                            </p>
                                                            <p className={'code'}>
                                                                {ticket?.service?.title ?? ''}
                                                            </p>
                                                        </Col>
                                                        <div className={'d-flex flex-column'}>
                                                            <p className={'text-xs black'}>
                                                                {ticket?.status?.title ?? ''}
                                                            </p>
                                                            <div className={'d-flex align-items-center mt-1'}>
                                                                <CalendarIcon
                                                                    className={'icon lg fill-ternary-color-light mr-2'}/>
                                                                <p className={'date'}>{moment(ticket?.submittedOn).format('yyyy-MM-DD')}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Col xs={12}/>
                                                    <Col xs={12}>
                                                        {
                                                            ticket?.taskCode &&
                                                            <OutlinedButton
                                                                onClick={navigateToTask}
                                                                className={'w-100 text-xs'}
                                                            >
                                                                LINK OF TASK
                                                            </OutlinedButton>
                                                        }
                                                    </Col>
                                                </div>
                                                {
                                                    !!ticket?.attachments?.length &&
                                                    <div className={'ticket-card w-100'}>
                                                        <Col xs={12} className={'mb-2'}>
                                                            <p className={'mini-title'}>
                                                                Attachments
                                                            </p>
                                                        </Col>
                                                        <div className={'d-flex flex-wrap px-3'}>
                                                            {
                                                                ticket?.attachments?.map((submitFile, index) => (
                                                                    <SubmittedFile
                                                                        key={submitFile.orderIndex}
                                                                        className={'mr-2 mb-1'}
                                                                        submitFile={submitFile}
                                                                        onClick={() => openFilePreviewDialog(ticket?.attachments, index)}
                                                                    />
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                <div className={'ticket-card w-100 comments'}>
                                                    <Col xs={12} className={'mb-2'}>
                                                        <p className={'mini-title'}>
                                                            Comments
                                                        </p>
                                                    </Col>
                                                    <Col xs={12} className={'mb-3'}>
                                                        {
                                                            !ticket?.comments?.length
                                                                ? <p className={'description my-5 text-center'}>
                                                                    There is currently no comments for this ticket. be the first to
                                                                    comment on
                                                                    it
                                                                </p>
                                                                : ticket?.comments
                                                                    ?.filter(e => !(e.content?.includes('*Description*') ?? false))
                                                                    ?.map((comment, index) => (
                                                                        <TicketCommentCard
                                                                            key={index}
                                                                            comment={comment}
                                                                            openFilePreviewDialog={openFilePreviewDialog}
                                                                        />
                                                                    ))
                                                        }
                                                    </Col>
                                                    <Col xs={12} className={''}>
                                                        <OutlinedButton
                                                            onClick={() => setOpenNewMessage(true)}
                                                            className={'w-100 text-xs'}
                                                        >
                                                            NEW MESSAGE
                                                        </OutlinedButton>
                                                    </Col>
                                                </div>
                                            </>
                                    }

                                </Col>
                            </>
                        )
                }
                <Col xs={12} className={'my-5'}>
                    <div style={{height: 1}}/>
                </Col>
                <Col xs={12} className={'my-5'}>
                    <div style={{height: 1}}/>
                </Col>
            </Row>
            <AddCommentToTicketDialog
                open={openNewMessage}
                setOpen={setOpenNewMessage}
                onSuccess={addNewlyCreatedComment}
            />
            <FilesPreviewDialog
                {...previewFiles}
                setOpen={() => setPreviewFiles(initPreviewFiles)}
            />
        </>
    );
}


export default Ticket;
