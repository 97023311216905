import React, {useEffect, useState} from "react";
import {Popover, SvgIcon} from "@material-ui/core";
import {ReactComponent as AppsIcon} from "../../../../../assets/images/topbar/apps-icon.svg";
import Api from "../../../../../core/services/api_service";
import useIsMounted from "../../../../hooks/use-is-mounted";


const TopbarApps = () => {
    const [popover, setPopover] = useState();
    const [apps, setApps] = useState([]);
    const [loading, setLoading] = useState(false);
    const isMounted = useIsMounted();

    /**
     * As soon as the component mounts:
     * Fetches the list of available application from the server and sets the state
     */
    useEffect(() => {
        getUserAvailableApplications();
    }, [])

    /**
     * Fetches the list of available applications of the user and sets the state
     */
    const getUserAvailableApplications = () => {
        setLoading(true);
        Api.getAllPanelApplication().then((response) => {
            if (!isMounted()) return;
            setApps(response?.data ?? []);
            setLoading(false);
        })
    }


    return (
        <>
            <SvgIcon onClick={(e) => setPopover(e.currentTarget)}
                     component={AppsIcon}
                     className={'topbar-icon'}/>
            <Popover
                id={!!popover ? 'apps' : undefined}
                elevation={2}
                classes={{paper: 'top-bar-popover apps'}}
                open={!!popover}
                onClose={() => setPopover(null)}
                anchorReference={'anchorEl'}
                anchorEl={popover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <div className={'content'}>
                    <div className={'d-flex justify-content-between w-100 mx-3'}>
                        <div className={'title'}>
                            Your Applications
                        </div>
                    </div>
                    <div className={'d-flex flex-wrap mt-3'}>
                        {
                            apps?.map(app => (
                                <a
                                    key={app.id}
                                    href={app.panelAddress}
                                    className={'app'}
                                    style={{'--color': app?.color}}
                                >
                                    <img src={app.logoURL}
                                         alt={app.name + ' logo'}
                                    />
                                    {app.name}
                                </a>
                            ))
                        }
                    </div>
                </div>
            </Popover>
        </>
    );
}

export default TopbarApps;
