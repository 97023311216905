export const apiUrl = process.env.REACT_APP_API_URL;

/** Auth APIs.*/
const authBaseUrl = apiUrl + 'Auth/';
export const authApis = {
    baseUrl: authBaseUrl,
    login: authBaseUrl + 'Login',
    logout: authBaseUrl + 'Logout',
    directLogin: authBaseUrl + 'DirectLogin',
    isLogin: authBaseUrl + 'IsLogin',
    validateLogin: authBaseUrl + 'ValidateLogin',
};

/** User APIs.*/
const userBaseUrl = apiUrl + 'Users/';
export const userApis = {
    baseUrl: userBaseUrl,
    requestForgetPassword: (email: string) => userBaseUrl + `RequestForgetPassword?email=${email}`,
    checkForgetPasswordLinkParams: userBaseUrl + 'CheckForgetPasswordLinkParams',
    resetPassword: userBaseUrl + 'ResetPassword',
};


/** Application APIs.*/
const applicationBaseUrl = apiUrl + 'Applications/';
export const applicationApis = {
    baseUrl: applicationBaseUrl,
    getAllAvailable: applicationBaseUrl + 'GetAllAvailableApplicationsForUser',
    getPanelApplications: applicationBaseUrl + 'GetAvailablePanels',

};


/** Ticketing APIs.*/
const ticketingBaseUrl = apiUrl + 'Ticketing/';
export const ticketingApis = {
    baseUrl: ticketingBaseUrl,
    getAllServicesAndTypes: (token: string) => ticketingBaseUrl + `GetAllServicesTypes/${token}`,
    submitTicketWithoutHint: ticketingBaseUrl + 'SubmitTicketWithoutHint',
    submitTicketOrFeedback: ticketingBaseUrl + 'SubmitTicketOrFeedback',
    submitForm: ticketingBaseUrl + 'SubmitForm',
    getAllQuestionsAndHintsByTypeId: (id: number) => ticketingBaseUrl + `GetAllQuestionsAndHintsByTypeId/${id}`,
    getAllSubmittedTickets: (token: string) => ticketingBaseUrl + `GetAllSubmittedTickets/${token}`,
    submitComment: ticketingBaseUrl + 'SubmitComment',
    search: ticketingBaseUrl + `FilterAndGetSubmittedTicket`,
    getTicketById: (token: string, id: number) => ticketingBaseUrl + `GetTicketById/${token}/${id}`,
    submitTicketAdminApproval: ticketingBaseUrl + `SubmitTicketAdminApproval`,
};


/** Announcements APIs.*/
const announcementsBaseUrl = apiUrl + 'Announcements/';
export const announcementsApis = {
    baseUrl: announcementsBaseUrl,
    search: announcementsBaseUrl + `FilterAndGetAnnouncements`,
    getAnnouncementById: (token: string, id: number) => announcementsBaseUrl + `GetAnnouncementById/${token}/${id}`,
};


/** Tasks APIs.*/
const tasksBaseUrl = apiUrl + 'Tasks/';
export const tasksApis = {
    baseUrl: tasksBaseUrl,
    getByCode: tasksBaseUrl + `FilterAndRetrieveTasks`,
    getLiveTasks: (token: string) => tasksBaseUrl + `GetLiveTasks/${token}`,
};

/** Versions APIs.*/
const versionsBaseUrl = apiUrl + 'Version/';
export const versionsApis = {
    baseUrl: versionsBaseUrl,
    getAllVersions: (token: string) => versionsBaseUrl + `GetAllVersionsForWeb/${token}`,
};


/** FILE SUBMISSION APIs.*/
const fileSubmissionBaseUrl = apiUrl + 'Ticketing/';
export const fileSubmissionApis = {
    baseUrl: fileSubmissionBaseUrl,
    uploadFile: fileSubmissionBaseUrl + 'UploadSingleFile',
};


/** DASHBOARD APIs.*/
const dashboardBaseUrl = apiUrl + 'Dashboard/';
export const dashboardApis = {
    baseUrl: dashboardBaseUrl,
    getInformation: (token: string) => dashboardBaseUrl + `GetDashboardInformation/${token}`,
}
